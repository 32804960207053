// TODO: Refactor this file to remove all the eslint-disable
// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import {
  makeGetRequest,
} from '../external/lib/apiHelper';
import { refreshTokens } from '../external/lib/backendTokenHelper';
// eslint-disable-next-line import/no-cycle
import {
  checkConnectionConfiguration,
  getConnections,
  deleteConnection,
} from '../external/lib/authHelper';
import { getUser } from '../external/lib/userDataHelper';
import { rootStore } from '../stores/Store';
import { GET } from '../lib/requests';
import handleError from '../lib/errorHandler';

// proxy URL to bypass google's strict cors policy on clientside API requests
export default class BusinessService {
  // eslint-disable-next-line consistent-return
  static refreshPracticeCredentials() {
    return refreshTokens();
  }

  static async pingProxyServer() {
    try {
      const res = await axios({
        method: 'get',
        url: process.env.REACT_APP_PROXY_URL,
      });
      return res;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async fetchFullBusinessInviteList(links, businessId, token, initial?) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    rootStore.businessStore.setLoading(true);

    const url = `${links.businesses.href}/${businessId}/invitations`;

    // eslint-disable-next-line camelcase
    makeGetRequest(url, { Authorization: `Bearer ${access_token}` })
      .then(
        (data) => {
          if (!data || data.length === 0) {
            rootStore.businessStore.storeInvitationListSplit([[]]);
            rootStore.businessStore.setLoading(false);
            return [[]];
          }

          rootStore.businessStore.setLoading(false);
          const invitationList = data._embedded.businessInvitations;
          rootStore.businessStore.storeInvitationListSplit(invitationList);
          return invitationList;
        },
        (error) => {
          console.log('err:', error);
          rootStore.businessStore.storeInvitationListSplit([[]]);
          rootStore.businessStore.setLoading(false);
          return [[]];
        }
      )
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error:', e);
        rootStore.businessStore.storeInvitationList([[]]);
        rootStore.businessStore.setLoading(false);
        return [[]];
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async fetchPendingInviteCount(links, businessId, token, initial?) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    rootStore.businessStore.setLoading(true);

    const url = `${links.businesses.href}/${businessId}/invitations`;

    // eslint-disable-next-line camelcase
    makeGetRequest(url, { Authorization: `Bearer ${access_token}` })
      .then(
        (data) => {
          if (!data || data.length === 0) {
            rootStore.businessStore.storeInvitationCount([[]], businessId);
            rootStore.businessStore.setLoading(false);
            return [[]];
          }

          rootStore.businessStore.setLoading(false);
          const invitationList = data._embedded.businessInvitations;
          rootStore.businessStore.storeInvitationCount(
            invitationList,
            businessId
          );
          return invitationList;
        },
        (error) => {
          console.log('err:', error);
          rootStore.businessStore.storeInvitationCount([[]], businessId);
          rootStore.businessStore.setLoading(false);
          return [[]];
        }
      )
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error:', e);
        rootStore.businessStore.storeInvitationCount([[]], businessId);
        rootStore.businessStore.setLoading(false);
        return [[]];
      });
  }

  static async fetchAdvisorInvitationList() {
    // @ts-ignore
    const { businesses } = rootStore.authStore.links;
    // @ts-ignore
    const businessId = rootStore.businessStore.practiceId.id;

    const url = `${businesses.href}/${businessId}/invitations`;
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    // eslint-disable-next-line camelcase
    makeGetRequest(url, { Authorization: `Bearer ${access_token}` })
      .then(
        (data) => {
          if (!data || data.length === 0) {
            rootStore.businessStore.storeAdvisorInvitations([[]]);
            return [[]];
          }

          const invitationList = data._embedded.businessInvitations;
          rootStore.businessStore.storeAdvisorInvitations(invitationList);
          return invitationList;
        },
        (error) => {
          console.log('err:', error);
          rootStore.businessStore.storeAdvisorInvitations([[]]);
          return [[]];
        }
      )
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error:', e);
        rootStore.businessStore.storeAdvisorInvitations([[]]);
        return [[]];
      });
  }

  // eslint-disable-next-line camelcase
  static async inviteUser(links, access_tokens, businessId, fields) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();
    const resolvedFieldInput = fields.map((fieldSet) => {
      const givenName = fieldSet.givenName.split(' ')[0];
      const familyName = fieldSet.givenName.split(' ')[1];
      return {
        givenName,
        familyName,
        displayName: fieldSet.givenName,
        email: fieldSet.emailAddress.toLowerCase(),
        roleKey: 'admin',
      };
    });
    return axios({
      method: 'post',
      url: `${links.businesses.href}/${businessId}/invitations`,
      data: {
        _embedded: {
          businessInvitations: resolvedFieldInput,
        },
      },
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        const newUser = res.data._embedded.invitations[0];
        newUser.status = 'invited';
        BusinessService.fetchFullBusinessInviteList(
          links,
          businessId,
          access_token,
          true
        );
        rootStore.businessStore.renderClientConfirmationWindow(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error inviting user to business', e);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
  static async inviteAdvisor(links, access_token, businessId, fields) {
    // @ts-ignore
    const practiceId = rootStore.businessStore.practiceId.id;
    const backendTokens = await BusinessService.refreshPracticeCredentials();

    const givenName = fields.givenName.split(' ')[0];
    const familyName = fields.givenName.split(' ')[1];
    const invitation = {
      givenName,
      familyName,
      displayName: fields.givenName,
      email: fields.emailAddress.toLowerCase(),
    };
    // @ts-ignore
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices/${practiceId}/invitations`,
      data: { invitation },
      headers: {
        Authorization: `Bearer ${backendTokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then(() => {
        rootStore.businessStore.fetchAdvisorInvitationList();
        rootStore.businessStore.renderConfirmationWindow(true, invitation);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error inviting user to practice', e);
      });
  }

  static async inviteUserFromSuggested(
    links,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
    access_tokens,
    businessId,
    givenName,
    familyName,
    email
  ) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    const resolvedFieldInput = [
      {
        givenName,
        familyName,
        displayName: `${givenName} ${familyName}`,
        email,
        roleKey: 'admin',
      },
    ];
    return axios({
      method: 'post',
      url: `${links.businesses.href}/${businessId}/invitations`,
      data: {
        _embedded: {
          businessInvitations: resolvedFieldInput,
        },
      },
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then(() => rootStore.businessStore.renderClientConfirmationWindow(true))
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error inviting user to business', e);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
  static async resendInvite(links, access_tokens, invitationId, businessId) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    return axios({
      method: 'post',
      url: `${links.businesses.href}/${businessId}/invitations/${invitationId}/resend`,
      data: {},
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then(() => rootStore.businessStore.renderClientConfirmationWindow(true))
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error inviting user to business', e);
      });
  }

  static async resendInviteAsAdvisor(
    links,
    // eslint-disable-next-line camelcase
    access_token,
    invitationId,
    businessId
  ) {
    const tokens = await BusinessService.refreshPracticeCredentials();
    return axios({
      method: 'post',
      url: `${links.businesses.href}/${businessId}/invitations/${invitationId}/resend`,
      data: {},
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then(() => rootStore.businessStore.renderClientConfirmationWindow(true))
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error inviting user to business', e);
      });
  }

  // eslint-disable-next-line camelcase
  static async getBusinessUsers(links, access_tokens, business) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    return axios({
      method: 'get',
      url: `${links.businesses.href}/${business.id}/users`,
      data: {},
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        rootStore.businessStore.updateBusinessListUsers(
          business.id,
          res.data._embedded
        );
        return res.data;
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error getting detail to business', e);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async getAdvisorsFromBusiness(links) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const practiceId = rootStore.businessStore.practiceId.id;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user } = rootStore.authStore;
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_AIDER_ENDPOINT}/${rootStore.businessStore.selectedBusinessId}/advisors`,
      data: {
        _embedded: {
          advisors: {
            validAdvisors: [
              {
                id: 'advisorId',
                name: 'Advisor Name',
              },
            ],
            currentAdvisors: [
              {
                id: 'advisorId',
                name: 'advisorName',
              },
            ],
          },
        },
      },
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    });
  }

  static async getBusinessesFromAdvisor(links, advisorId) {
    // @ts-ignore
    const practiceId = rootStore.businessStore.practiceId.id;
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_AIDER_ENDPOINT}/practices/${practiceId}/advisors/${advisorId}/clients`,
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    }).then(
      (res) => {
        console.log('res:', res.data);
        rootStore.businessStore.storeAdvisorsClients(
          res.data._embedded.clients
        );
      },
      () => {
        rootStore.businessStore.storeAdvisorsClients({
          currentClients: [],
          validClients: [],
        });
      }
    );
  }

  // eslint-disable-next-line consistent-return
  static async addAdvisorToBusiness(links, addToBusinessArray, advisorId) {
    try {
      rootStore.businessStore.setPermissionsLoading(true);
      const addToBusinessArrayProcessed = addToBusinessArray.map((i) => {
        const item = { clientBusinessId: i, advisorUserId: advisorId.id };
        // eslint-disable-next-line no-return-assign, no-param-reassign
        return (i = item);
      });

      const practiceId = rootStore.businessStore.practiceId.id;
      const backendTokens = await BusinessService.refreshPracticeCredentials();

      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices/${practiceId}/clients/advisors`,
        data: { advisorAddRequests: addToBusinessArrayProcessed },
        headers: {
          Authorization: `Bearer ${backendTokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        .then((res) => {
          console.log('added!', res);
          rootStore.businessStore.setPermissionsLoading(false);
        })
        .catch(() => {
          rootStore.businessStore.setPermissionsLoading(false);
        });
    } catch (e) {
      rootStore.businessStore.setPermissionsLoading(false);
    }
  }

  // eslint-disable-next-line consistent-return
  static async removeAdvisorFromBusiness(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    links,
    addToBusinessArray,
    advisorId,
    fullArray
  ) {
    try {
      const fullArrayMappedToId = fullArray.map((i) => i.id);
      const processedFullArrayForSubtraction = fullArrayMappedToId
        .filter((x) => !addToBusinessArray.includes(x))
        .map((i) => {
          const item = { clientBusinessId: i, advisorUserId: advisorId.id };
          // eslint-disable-next-line no-return-assign, no-param-reassign
          return (i = item);
        });

      // @ts-ignore
      const practiceId = rootStore.businessStore.practiceId.id;
      const backendTokens = await BusinessService.refreshPracticeCredentials();

      return axios({
        method: 'delete',
        url: `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices/${practiceId}/clients/advisors`,
        data: { advisorRemoveRequests: processedFullArrayForSubtraction },
        headers: {
          Authorization: `Bearer ${backendTokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      }).then(() => {
        rootStore.businessStore.setPermissionsLoading(false);
      });
    } catch (e) {
      rootStore.businessStore.setPermissionsLoading(false);
      console.log(e);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, consistent-return
  static async getPracticeAdvisors(links) {
    rootStore.businessStore.fetchAdvisorsLoading(true);
    try {
      const practiceId = rootStore.businessStore.practiceId.id;
      // eslint-disable-next-line camelcase
      const { access_token } =
        await BusinessService.refreshPracticeCredentials();

      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_USERS_MODULE_ENDPOINT}/businesses/${practiceId}/users`,
        data: {},
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: `Bearer ${access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        .then((res) => {
          if (res.data) {
            rootStore.businessStore.fetchAdvisorsLoading(false);
            rootStore.businessStore.updatePracticeAdvisors(res.data);
          }
        })
        .catch((e) => {
          rootStore.businessStore.fetchAdvisorsLoading(false);

          Sentry.captureException(e);
          console.log('error getting detail to business', e);
          rootStore.businessStore.fetchAdvisorsLoading(false);
        });
    } catch (e) {
      console.log('err getting practice token:', e);
      rootStore.businessStore.fetchAdvisorsLoading(false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, camelcase
  static async getConnectionProfileData(links, access_tokens, business) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();
    try {
      const connectionId = await getConnections(
        links,
        access_token,
        business.id
      );
      rootStore.businessStore.updateBusinessListApps(
        business.id,
        connectionId.businessConnections
      );
      const xeroConnection =
        connectionId
        && connectionId.businessConnections.find(
          (connection) => connection.ospKey === 'xero'
        );
      if (
        xeroConnection
        && xeroConnection.id
        && typeof xeroConnection !== 'undefined'
        && xeroConnection.status !== 'standby'
      ) {
        const data = await checkConnectionConfiguration(
          links,
          access_token,
          xeroConnection.id
        );
        rootStore.businessStore.setXeroConnection(xeroConnection, data);
        return rootStore.businessStore.updateBusinessListDetail(
          business.id,
          null,
          data
        );
      }

      rootStore.businessStore.setXeroConnection({}, {});

      const mockData = {
        configuration: {
          entities: [
            {
              address: '',
              countryCode: '',
              currencyCode: '',
              financialYearEnd: '',
              lineOfBusiness: '',
              phoneNumber: '',
              salesTaxBasis: '',
              salesTaxPeriod: '',
              shortCode: '',
              taxNumber: '',
              tenantConnectionId: '',
              tenantType: '',
              timezoneId: '',
            },
          ],
          disconnected: true,
        },
      };
      return rootStore.businessStore.updateBusinessListDetail(
        business.id,
        null,
        mockData
      );
    } catch (e) {
      console.log('ERRRR', e);
      return rootStore.businessStore.updateBusinessListDetail(
        business.id,
        null,
        {
          configuration: {
            disconnected: true,
            entities: [
              {
                address: '',
                countryCode: '',
                currencyCode: '',
                financialYearEnd: '',
                lineOfBusiness: '',
                phoneNumber: '',
                salesTaxBasis: '',
                salesTaxPeriod: '',
                shortCode: '',
                taxNumber: '',
                tenantConnectionId: '',
                tenantType: '',
                timezoneId: '',
              },
            ],
          },
        }
      );
    }
  }

  /**
   *TODO:  Need to remove the business from the businesses store here
   * @returns
   */
  // eslint-disable-next-line consistent-return
  static async removeBusiness() {
    try {
      const backendTokens = await BusinessService.refreshPracticeCredentials();
      const deletedBusinessId = `${rootStore.businessStore.selectedBusinessId}`;
      const nextBusiness = rootStore.businessStore.businessListData.filter(
        (business) => business.id !== deletedBusinessId
      )[0];

      if (typeof nextBusiness !== 'undefined' && nextBusiness) {
        rootStore.businessStore.setSelectedBusinessName(nextBusiness.name);
        await rootStore.businessStore.selectBusiness2(nextBusiness.id);
      } else {
        rootStore.businessStore.clearLastBusiness();
      }
      rootStore.businessStore.removeFromShortlist(deletedBusinessId);
      rootStore.authStore.setShallowLoader(true);
      // @ts-ignore
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices/${rootStore.businessStore.practiceId.id}/clients/${deletedBusinessId}`;
      return axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `Bearer ${backendTokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        .then(async (res) => {
          if (res) {
            console.log('removed business', res);
            await rootStore.businessesStore
              .fetchBusinessConnection()
              .then(() => {
                console.log('refreshed list');
                rootStore.authStore.setShallowLoader(false);
                if (typeof nextBusiness !== 'undefined' && nextBusiness) {
                  rootStore.businessStore.setSelectedBusinessName(
                    nextBusiness.name
                  );
                  rootStore.businessStore.selectBusiness2(nextBusiness.id);
                }
              });
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.log('error getting connections for businesses', e);
        });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Move to businessesStore
   * Will be deprecated int he future
   * @returns
   */
  // eslint-disable-next-line consistent-return
  static async disconnectBusiness() {
    const backendTokens = await BusinessService.refreshPracticeCredentials();
    if (rootStore.businessStore.xeroConnectionId) {
      return deleteConnection(
        backendTokens,
        rootStore.businessStore.xeroDisconnectLink
      ).then(() => {
        setTimeout(() => {
          rootStore.businessStore.selectBusiness2(
            rootStore.businessStore.selectedBusinessId,
          );
        }, 1000);
      });
    }
    console.log('business is already DCd');
  }

  // eslint-disable-next-line consistent-return
  static async getConnectionsForBusinesses() {
    // @ts-ignore
    const backendTokens = await BusinessService.refreshPracticeCredentials();

    // @ts-ignore
    if (rootStore?.authStore?.user?.uid) {
      const url = `${process.env.REACT_APP_CONNECTION_MODULE_ENDPOINT}/users/${rootStore.userStore.id}/connections`;
      const sentryTransaction = Sentry.startTransaction({
        name: 'Get Connections for User',
      });
      const span = sentryTransaction.startChild({
        op: 'makeGETRequest',
        data: { url },
      });
      return axios({
        method: 'get',
        url,
        data: {},
        headers: {
          Authorization: `Bearer ${backendTokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        .then((res) => {
          if (res) {
            rootStore.businessStore.storeConnectionsForBusinesses(
              res.data.businesses
            );
          }
          span.setStatus('ok');
          span.finish();
          sentryTransaction.finish();
        })
        .catch((e) => {
          Sentry.captureException(e);
          span.setStatus(e.message);
          span.finish();
          sentryTransaction.finish();
          console.log('error getting connections for businesses', e);
        });
    }
  }

  static async getUsersForBusinesses(businessId) {
    const backendTokens = await BusinessService.refreshPracticeCredentials();
    return (
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_USERS_MODULE_ENDPOINT}/businesses/${businessId}/users`,
        data: {},
        headers: {
          Authorization: `Bearer ${backendTokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        // eslint-disable-next-line consistent-return
        .then((res) => {
          if (res) {
            rootStore.businessStore.setAllUsers(res.data.businessUsers);
            rootStore.businessStore.updateBusinessListUsers(
              businessId,
              res.data
            );
            return res.data.businessUsers;
          }
        })
        .catch((error) => {
          console.log(error);
          Sentry.captureException(error);
        })
    );
  }

  static async getInvitationsForBusinesses() {
    // @ts-ignore
    const backendTokens = await BusinessService.refreshPracticeCredentials();
    // @ts-ignore
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_USERS_MODULE_ENDPOINT}/users/${rootStore.userStore.id}/invitations`,
      data: {},
      headers: {
        Authorization: `Bearer ${backendTokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        if (res) {
          rootStore.businessStore.storeInvitationCountForBusinesses(res.data);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.log('error getting invitations for businesses', e);
      });
  }

  /**
   * @deprecated
   * @param fields
   * @returns
   */
  // eslint-disable-next-line consistent-return
  static async updatePracticeInformation(fields) {
    try {
      rootStore.businessStore.setUpdatingPracticeInformation(true);
      // eslint-disable-next-line camelcase
      const { access_token } =
        await BusinessService.refreshPracticeCredentials();
      return axios({
        method: 'put',
        // @ts-ignore
        url: `${rootStore.authStore.links.businesses.href}/${rootStore.businessStore.practiceId.id}`,
        data: {
          _embedded: {
            business: {
              name: fields.name,
              address: fields.address,
              profile: {
                accountingSoftware: fields.accountingSoftware,
                numberOfAccountants: fields.numberOfAccountants,
                numberOfClients: fields.numberOfClients,
              },
            },
          },
        },
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: `Bearer ${access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      }).then(() => {
        rootStore.businessStore.setUpdatingPracticeInformation(false);
      });
    } catch (e) {
      rootStore.businessStore.setUpdatingPracticeInformation(false);
    }
  }

  /**
   * TODO: this is setting the business attributes back to API
   * @param links
   * @param access_tokens
   * @param businessId
   * @param fields
   * @param name
   * @returns
   */
  static async editBusinessDetails(
    links,
    // eslint-disable-next-line camelcase
    access_tokens,
    businessId,
    fields,
    name
  ) {
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    return axios({
      method: 'put',
      url: `${links.businesses.href}/${businessId}`,
      data: {
        _embedded: {
          business: {
            name,
            profile: {
              taxRate: parseFloat(fields.taxRate),
            },
          },
        },
      },
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        rootStore.businessStore.updateBusinessListProfile(
          businessId,
          res.data.profile
        );
        // confirmation modal
        // fetch businesses again
      })
      .catch((e) => {
        console.log('err', e);
      });
  }

  static async initializeBusiness(
    links,
    // eslint-disable-next-line camelcase
    access_token,
    businessId,
    businessListInstance
  ) {
    this.fetchFullBusinessInviteList(
      links,
      businessId,
      rootStore.authStore.access_token,
      true
    );
    rootStore.businessStore.updateBusinessListProfile(
      businessId,
      businessListInstance.profile
    );
    // todo required initialization info
  }

  // NEEDS TO GO TO CHECKLIST STORE
  static async getBusinessCheckList() {
    const transaction = Sentry.startTransaction({
      name: 'Load Period Close Checklist',
    });
    let span;

    rootStore.businessStore.setCheckListLoading(true);
    try {
      // eslint-disable-next-line camelcase
      const { access_token } =
        await BusinessService.refreshPracticeCredentials();
      span = transaction.startChild({ op: 'getBusinessCheckList' });
      return axios({
        method: 'get',
        url: `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${rootStore.businessStore.selectedBusinessId}/insights/tablegroups/gstPeriodChecklist`,
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: `Bearer ${access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        .then((res) => {
          rootStore.businessStore.storeCheckListData(res.data).then(() => {
            rootStore.businessStore.setCheckListLoading(false);
          });
          span.setStatus('ok');
          span.finish();
          transaction.finish();
          return res;
        })
        .catch((e) => {
          console.log(e);
          span.setStatus('unknown_error');
          span.finish();
          transaction.finish();
          Sentry.captureException(e);
          rootStore.businessStore.storeCheckListData(null);
          rootStore.businessStore.setCheckListLoading(false);
          return e;
        })
        .finally(() => true);
    } catch (e) {
      console.log('BUSINESS ALERTS:', e);
      if (span) {
        span.setStatus('unknown_error');
        span.finish();
      }
      transaction.finish();
      Sentry.captureException(e);
      rootStore.businessStore.storeCheckListData(null);
      rootStore.businessStore.setCheckListLoading(false);
      return 'error';
    }
  }

  static async updateGstPeriodChecklist() {
    rootStore.businessStore.setCheckListLoading(true);
    const { access_token: accessToken } =
      await BusinessService.refreshPracticeCredentials();
    return axios({
      method: 'put',
      url: `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${rootStore.businessStore.selectedBusinessId}/insights/tablegroups/gstPeriodChecklist`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(() => {
        rootStore.businessStore.setRuleLoadingMsg('', null, '');
        rootStore.businessStore.setCheckListLoading(false);
      })
      .catch((e) => {
        console.error(e);
        rootStore.businessStore.setCheckListLoading(false);
      })
      .finally(() => 'done');
  }

  static async updateChecklistStatus(tableGroupId: string) {
    try {
      // eslint-disable-next-line camelcase
      const { access_token } =
        await BusinessService.refreshPracticeCredentials();
      axios({
        method: 'put',
        url: `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${rootStore.businessStore.selectedBusinessId}/insights/tablegroups/${tableGroupId}/${rootStore.authStore.user.displayName}/statusupdate`,
        data: {},
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: `Bearer ${access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      }).then((response) => {
        if (response.status === 200) {
          rootStore.businessStore.storeCheckListTableGroupData(response.data);
        }
      });
    } catch (e) {
      console.log('Checklist toggle issue:', e);
      Sentry.captureException(e);
    }
  }
  // ########

  // NEEDS TO GO TO INSIGHTS STORE
  static async getBusinessInsights() {
    const transaction = Sentry.startTransaction({
      name: 'Load Business Insights',
    });
    let span = transaction.startChild({ op: 'refreshPracticeCredentials' });
    rootStore.businessStore.setInsightsLoading(true);
    try {
      // eslint-disable-next-line camelcase
      const { access_token } =
        await BusinessService.refreshPracticeCredentials();
      span.finish();
      span = transaction.startChild({ op: 'getBusinessInsights' });
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_INSIGHT_ENDPOINT}/businesses/${rootStore.businessStore.selectedBusinessId}/insights`,
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: `Bearer ${access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      })
        .then((res) => {
          rootStore.businessStore
            .storeInsightData(res.data.insights)
            .then(() => {
              rootStore.businessStore.setInsightsLoading(false);
              span.setStatus('ok');
              span.finish();
              transaction.finish();
            });
        })
        .catch((e) => {
          console.error(e);
          span.setStatus('unknown_error');
          span.finish();
          transaction.finish();
          Sentry.captureException(e);
          rootStore.businessStore.storeInsightData(null);
          rootStore.businessStore.setInsightsLoading(false);
        });
    } catch (e) {
      console.error('BUSINESS ALERTS:', e);
      span.setStatus('unknown_error');
      span.finish();
      transaction.finish();
      Sentry.captureException(e);
      rootStore.businessStore.storeInsightData(null);
      rootStore.businessStore.setInsightsLoading(false);
    }
  }

  static async uploadProfileImage(downloadURL) {
    rootStore.businessStore.setPracticeLogoURL(downloadURL);
    console.log('TO:', rootStore.businessStore.practiceId.id);
    // eslint-disable-next-line camelcase
    const { access_token } = await BusinessService.refreshPracticeCredentials();

    // @ts-ignore
    const url = `${rootStore.authStore.links.businesses.href}/${rootStore.businessStore.practiceId.id}`;
    const { name } = rootStore.businessStore.practiceId;
    return axios({
      method: 'put',
      url,
      data: {
        _embedded: {
          business: {
            name,
            profile: {
              logoUrl: downloadURL,
            },
          },
        },
      },
      headers: {
        // eslint-disable-next-line camelcase
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        console.log('SUCCESS:', res);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { logoURL } = res.data.profile;
        rootStore.actionStore.setBrandUploadStatus('success');
      })
      .catch((e) => {
        console.error(e);
        rootStore.actionStore.setBrandUploadStatus('error');
      });
  }

  static async getUserDetail(userId) {
    try {
      const url = `${process.env.REACT_APP_USERS_MODULE_ENDPOINT}/users/${userId}`;
      const res = await GET({ url, rootStore });
      if (!res) throw Error('No User Returned');
      rootStore.authStore.setPracticeUser({ user: res });
      rootStore.authStore.setPracticeUserDetail(res);
      return res;
    } catch (e) {
      handleError({ error: e, transaction: 'getUserDetail' });
      throw e;
    }
  }

  // eslint-disable-next-line camelcase
  static async editUserDetails(links, access_token, fields, id) {
    console.log('edit user:', fields);
    rootStore.businessStore.editDetailsLoading(true);
    const tokens = await BusinessService.refreshPracticeCredentials();
    return axios({
      method: 'put',
      url: `${links.users.href}/${id}`,
      data: {
        _embedded: {
          user: {
            email: fields.email.toLowerCase(), // string
            givenName: fields.givenName, // string
            familyName: fields.familyName, // string
            displayName: fields.displayName, // string
            phoneNumber: fields.phoneNumber, // string
          },
        },
      },
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    })
      .then(
        (res) => {
          if (res.status === 200) {
            rootStore.businessStore.editDetailsLoading(false);

            rootStore.authStore.validateUserModal(null);
            getUser(links, rootStore.userStore.id, tokens).then(
              (user) => {
                if (user) {
                  rootStore.authStore.setPracticeUser(user);
                }
              }
            );
          }

          // confirmation modal
          // fetch businesses again
        },
        // eslint-disable-next-line consistent-return
        (rej) => {
          // todo backend result in anything other than 500, log error using validateUserModal
          rootStore.businessStore.editDetailsLoading(false);

          if (!fields.phoneNumber || !fields.phoneNumber.includes('+')) {
            return rootStore.authStore.validateUserModal({
              error: 'invalid/phone-number',
              message:
                'Please use international Phone number format. ex: +64210000000',
            });
          }
          if (rej.response.data && rej.response.data._embedded.error.message) {
            return rootStore.authStore.validateUserModal({
              error: 'invalid/phone-number',
              message: rej.response.data._embedded.error.message,
            });
          }
        }
      )
      .catch((e) => {
        console.log('caught error', e);
      });
  }

  /**
   * TODO: Where is this data stored?
   * Method to remove the given tags from the given business in the DB
   * @param {string} businessId - the id of the business to remove the tags from
   * @param {string[]} tags - Array of tags to remove from the business
   */
  // eslint-disable-next-line consistent-return
  static async removeBusinessTags(businessId: string, tags: string[]) {
    const data = { tags };
    try {
      // eslint-disable-next-line camelcase
      const { access_token } =
        await BusinessService.refreshPracticeCredentials();

      return axios({
        method: 'delete',
        url: `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${businessId}/tags`,
        data,
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: `Bearer ${access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      }).catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    } catch (e) {
      console.error('e', e);
    }
  }

  /**
   * TODO: Where is this stored?
   * Method to add the given tags to the given business in the DB
   * @param {string} businessId - the id of the business to add the tags to
   * @param {string[]} tags - Array of tags to add to the business
   */
  // eslint-disable-next-line consistent-return
  static async addBusinessTags(businessId: string, tags: string[]) {
    const data = { tags };
    try {
      // eslint-disable-next-line camelcase
      const { access_token } =
        await BusinessService.refreshPracticeCredentials();

      return axios({
        method: 'put',
        url: `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${businessId}/tags`,
        data,
        headers: {
          // eslint-disable-next-line camelcase
          Authorization: `Bearer ${access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      }).catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    } catch (e) {
      console.error('e', e);
    }
  }
}
