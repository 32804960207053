import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import OverwriteChangesModal from '../modals/OverwriteChangesModal';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const ReportTemplateSelector = ({ className = '' }: { className?: string }) => {
  const rootStore = useStore();
  const { reportTemplateStore } = rootStore;
  const [form] = Form.useForm();

  const templateOptions = reportTemplateStore?.reportTemplateSelectionList;

  useEffect(() => {
    if (reportTemplateStore.selectedPerformanceReportTemplate) {
      form.setFieldsValue({ reportTemplate: reportTemplateStore.selectedPerformanceReportTemplate });
    }
  }, [reportTemplateStore.selectedPerformanceReportTemplate]);

  const switchTemplate = (value) => {
    trackMixpanelEvent({ description: 'Report Editor - Change Template', properties: { reportTemplate: value }, rootStore });
    reportTemplateStore.editedPerformanceReportTemplate = null;
    reportTemplateStore.selectedPerformanceReportTemplate = value;
  };

  const onReportTemplateSelection = ({ reportTemplate }) => {
    if (!reportTemplate) return;
    if (reportTemplateStore.isTemplateEdited) {
      trackMixpanelEvent({ description: 'Report Editor - Change Template Confirmation', properties: { reportTemplate }, rootStore });
      OverwriteChangesModal(
        () => {
          trackMixpanelEvent({ description: 'Report Editor - Discard Changes', properties: { reportTemplate }, rootStore });
          switchTemplate(reportTemplate);
        },
        () => {
          trackMixpanelEvent({ description: 'Report Editor - Stay on Template', properties: { reportTemplate }, rootStore });
          form.setFieldsValue({ reportTemplate: reportTemplateStore.selectedPerformanceReportTemplate });
        }
      );
    } else {
      switchTemplate(reportTemplate);
    }
  };

  return (
    <Form form={form} onValuesChange={onReportTemplateSelection}>
      <Form.Item name='reportTemplate' label={null} initialValue={reportTemplateStore.selectedPerformanceReportTemplate}>
        <Select
          className={className}
          size='large'
          options={templateOptions}
        />
      </Form.Item>
    </Form>
  );
};

ReportTemplateSelector.defaultProps = {
  className: '',
};

export default observer(ReportTemplateSelector);
