import React from 'react';
import { observer } from 'mobx-react';
import { TreeSelect } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { useStore } from '../../stores/Store';

const WysiwygVariableSelector = () => {
  const { reportTemplateStore } = useStore();

  const treeData = reportTemplateStore?.sortedTemplateVariables;

  const handleSelect = (item) => {
    reportTemplateStore.insertVariable(item);
  };

  return (
    <TreeSelect
      showSearch
      className='wysiwyg-custom-dropdown'
      popupClassName='ant-tree-dropdown'
      onChange={handleSelect}
      treeExpandAction='click'
      treeData={treeData}
      placeholder='Insert Data Point'
      value={null}
      suffixIcon={<CaretDownFilled />}
    />
  );
};

export default observer(WysiwygVariableSelector);
