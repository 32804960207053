import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { DeleteOutlined } from '@ant-design/icons';
import { ModalWidths } from '../../models/enums/modals';
import AsyncModal from './wrappers/AsyncModal';
import type { RootStore } from '../../stores/Store';
import handleError from '../../lib/errorHandler';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import DeleteRuleError from './content/DeleteRuleError';
import DeleteRuleConfirmation from './content/DeleteRuleConfirmation';
import DeleteRuleSuccessModal from './DeleteRuleSuccessModal';

export interface IDeleteRuleModal {
  businessId: string;
  ruleId: string;
  checklistId: string;
  rootStore: RootStore;
  sectionId: string;
  selectedPeriod: string;
  error?: any;
}

const DeleteRuleModal = ({
  businessId,
  ruleId,
  checklistId,
  sectionId,
  rootStore,
  selectedPeriod,
  error
}: IDeleteRuleModal) => {
  const rule = rootStore.rulesStore.rule(businessId, checklistId, sectionId, ruleId);

  /** Process the delete confirmation */
  const onOk = async () => {
    try {
      trackMixpanelEvent({
        description: 'Delete Rule - Delete Triggered',
        rootStore,
        properties: { ruleId },
      });

      await rootStore.rulesStore.deleteRule(businessId, checklistId, rule);

      rootStore.rulesStore.handleUpdateRule(ruleId, checklistId, sectionId, selectedPeriod, businessId);
      NiceModal.remove('DeleteRuleModal');
      DeleteRuleSuccessModal({ rule: { id: ruleId, title: rule.title } });
    } catch (e: any) {
      trackMixpanelEvent({
        description: 'Delete Rule - Error Encountered',
        rootStore,
        properties: { ruleId, e },
      });
      DeleteRuleModal({
        businessId,
        ruleId,
        rootStore,
        checklistId,
        sectionId,
        selectedPeriod,
        error: e.message || e,
      });
      handleError({
        error,
        status: 'error_deleting',
        transaction: 'Delete data rule',
        operation: 'handleDeletes',
      });
    }
  };

  const ModalProps = {
    name: 'Delete Rule - Confirm Modal',
    className: 'rule-modal rule-modal__delete',
    width: ModalWidths.MEDIUM,
    okText: 'Delete',
    okButtonProps: {
      className: 'button-primary',
      shape: 'round',
      size: 'large',
      danger: true,
      icon: <DeleteOutlined />,
    },
    cancelButtonProps: {
      className: 'button-secondary',
      shape: 'round',
      size: 'large',
    },
    onOk,
    asyncOk: true,
    content: error
      ? <DeleteRuleError error={error} rule={rule} checklistId={checklistId} />
      : <DeleteRuleConfirmation rule={rule} />,
    hasFollowUpModal: true,
    rootStore,
  };

  NiceModal.register('DeleteRuleModal', AsyncModal);
  return NiceModal.show('DeleteRuleModal', ModalProps);
};

export default DeleteRuleModal;
