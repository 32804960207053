import cookie from 'js-cookie';
import qs from 'qs';
import * as Sentry from '@sentry/browser';
import { makePostRequest } from './apiHelper';
import { rootStore } from '../../stores/Store';
import handleError from '../../lib/errorHandler';

const updateTokensExpiry = (tokens) => {
  if (!tokens) return;
  tokens.expiresAt = Math.floor(Date.now() / 1000) + (tokens.expires_in || 900);
  return tokens;
};

export const
  refreshTokens = async (tokens) => {
  // Get current stored tokens
    const store = rootStore.authStore;
    const backendTokens = tokens || {
      access_token: store.access_token,
      id_token: store.id_token,
      refresh_token: store.refresh_token,
      expires_in: store.expires_in,
    };

    // If there is no refresh_token, there is nothing we can do here
    if (!backendTokens.access_token) return {};

    // Calculate for how much longer do we want to keep the current set of tokens

    if (!rootStore.authenticationStore.tokenIsExpired()) {
      return backendTokens;
    }

    // Tokens expired or about to expire, refresh from server
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(process.env.REACT_APP_API_KEY)}`,
    };

    const body = {
      grant_type: 'refresh_token',
      refresh_token: backendTokens.refresh_token,
    };

    const refreshedTokens = await makePostRequest(
      `${process.env.REACT_APP_AUTH_MODULE_ENDPOINT}/oauth2/token`,
      headers,
      qs.stringify(body)
    ).then(updateTokensExpiry);
    cookie.set(
      process.env.REACT_APP_BACKEND_TOKENS_COOKIE_NAME,
      JSON.stringify(refreshedTokens),
      { domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: true }
    );
    rootStore.authStore.setTokens(
      refreshedTokens.access_token,
      refreshedTokens.refresh_token,
      refreshedTokens.expires_in,
      store.id_token || store.persistIdToken
    );
    const expiresAt = Math.floor(Date.now() / 1000) + refreshedTokens.expires_in;
    rootStore.authenticationStore.tokens =
      {
        expiresAt,
        accessToken: refreshedTokens.access_token,
        refreshToken: refreshedTokens.refresh_token,
        expiresIn: refreshedTokens.expires_in,
        idToken: store.id_token || store.persistIdToken,
      };

    return refreshedTokens;
  };

export const initializeBackendTokens = async (idToken) => {
  // @ts-ignore
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${btoa(`${process.env.REACT_APP_API_KEY}`)}`,
  };
  const body = {
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    subject_token: idToken,
    subject_token_type: 'urn:ietf:params:oauth:token-type:id_token',
  };
  try {
    const backendTokens = await makePostRequest(
      `${process.env.REACT_APP_AUTH_MODULE_ENDPOINT}/oauth2/token`,
      headers,
      qs.stringify(body)
    ).then(updateTokensExpiry);
    cookie.set(
      process.env.REACT_APP_BACKEND_TOKENS_COOKIE_NAME,
      JSON.stringify(backendTokens),
      { domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: true }
    );
    // eslint-disable-next-line camelcase
    const { access_token, refresh_token, expires_in } = backendTokens;
    rootStore.authStore.setTokens(
      access_token,
      refresh_token,
      expires_in,
      idToken
    );

    // eslint-disable-next-line camelcase
    const expiresAt = Math.floor(Date.now() / 1000) + expires_in;
    rootStore.authenticationStore.tokens =
      {
        expiresAt,
        accessToken: access_token,
        refreshToken: refresh_token,
        expiresIn: expires_in,
      };
    // eslint-disable-next-line camelcase
    return { access_token, refresh_token, expires_in, id_token: idToken };
  } catch (e) {
    handleError({ error: e });
    return {};
  }
};

export const getUserTokens = async (userId) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${btoa(`${process.env.REACT_APP_API_KEY}`)}`,
  };
  const body = {
    grant_type: 'client_credentials',
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    userId,
  };
  try {
    const backendTokens = await makePostRequest(
      `${process.env.REACT_APP_AUTH_MODULE_ENDPOINT}/oauth2/token`,
      headers,
      qs.stringify(body)
    ).then(updateTokensExpiry);
    // Store impersonated tokens
    // eslint-disable-next-line camelcase
    const { access_token, refresh_token, expires_in } = backendTokens;
    rootStore.authStore.setTokens(
      access_token,
      refresh_token,
      expires_in
    );

    // Return impersonated tokens
    // eslint-disable-next-line camelcase
    return { access_token, refresh_token, expires_in };
  } catch (e) {
    Sentry.captureException(e);
    return {};
  }
};
