import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import type { RootStore } from './Store';
import { GET } from '../lib/requests';

export default class PracticeTagStore {
  rootStore: RootStore;

  tags: string[] = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }

  /**
   * Sets the tags array to the provided tags and sorts them in alphabetical order (A-Z)
   * @param {string[]} tags - An array of strings representing the new tags.
   * @returns {void}
   */
  setTags = (tags: string[]): void => {
    this.tags = tags.sort((a: string, b: string) => a.localeCompare(b));
  };

  /**
   * Add new practice tags to the existing tag list
   * @param {string[]} tags - An array of strings representing the new practice tags
   * @returns {void}
   */
  addPracticeTags = (tags: string[]): void => {
    const oldTags = this.tags;
    const newTags = tags.filter((tag) => !oldTags.includes(tag));
    this.tags = [...oldTags, ...newTags];
  };

  /**
   * Gets the tags for the current practice from the business service API
   * and sets them in the tag store.
   * @returns A promise that resolves when the tags have been successfully retrieved and set.
   */
  fetchTags = async () => {
    try {
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices/${this.rootStore.practiceStore.id}/tags`;
      const res = await GET({ url, rootStore: this.rootStore });
      if (!res.tags) throw new Error('No tags found');
      this.setTags(res.tags);
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}
