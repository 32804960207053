import React from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Input, Radio, Row, Select } from 'antd';
import _ from 'lodash';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { TaxBasisKeys, TaxPeriodLengthKeys } from '@aider/constants-library/dist/enums/periodDetails';
import { FinancialYearEndDates, TaxPeriodStartDates } from '@aider/constants-library/dist/enums/clientManagement';
import { ClientDetailConstants, ClientManagementEnums, PeriodDetailConstants, PeriodDetailEnums } from '@aider/constants-library';
import { useStore } from '../../../stores/Store';
import LoadingSpinner from '../../LoadingSpinner';

import ButtonPrimary from '../../customAntD/ButtonPrimary';
import handleError from '../../../lib/errorHandler';
import { existsOrIsNull, nullToEmptyString } from '../../../lib/dataUtils';
import AccountsTreeSelect from '../../form/AccountsTreeSelect';

const ClientConfiguration = () => {
  const { businessesStore, splitStore, resyncStore } = useStore();
  const [saving, setSaving] = React.useState(false);
  const [form] = Form.useForm();

  const countryCode = businessesStore?.selectedBusiness?.countryCode || 'NZ';
  const osp = businessesStore.selectedBusiness?.connectedOsps?.[0] || ClientManagementEnums.OSPKeys.xero;
  const isXero = osp === ClientManagementEnums.OSPKeys.xero;
  const bankAccounts = businessesStore.selectedBusinessBankAccounts || [];

  // Set Default Values
  const defaultTaxStartPeriodKey = PeriodDetailEnums.DefaultTaxPeriod?.[countryCode] || PeriodDetailEnums.DefaultTaxPeriod.default;
  const defaultFinancialYearEndKey = PeriodDetailEnums.DefaultFinancialYearStart?.[countryCode] || PeriodDetailEnums.DefaultFinancialYearStart.default;
  const defaultGSTBasis = PeriodDetailEnums.DefaultTaxBasis[osp];
  const defaultPeriodLength = PeriodDetailEnums.DefaultTaxPeriodLength?.[countryCode] || PeriodDetailEnums.DefaultTaxPeriodLength.default;

  /**
   * Identify initial values in priority order
   * 1. Config
   * 2. Business profile / connection
   * 3. default
   */
  const fyStartDate = businessesStore.selectedBusinessConfig?.overrides?.financialYearEnd
    || businessesStore.selectedBusiness.connectedApps?.[0]?.configuration?.entities?.[0]?.financialYearEnd
    || ClientManagementEnums.FinancialYearEndDates[defaultFinancialYearEndKey];

  // As far as I know, this one is QB specific, and is either manually set (so in the config), or doesn't exist (so we use default)
  const taxPeriodStart = existsOrIsNull(businessesStore.selectedBusinessConfig?.overrides?.salesTaxPeriodEnd)
    ? businessesStore.selectedBusinessConfig?.overrides?.salesTaxPeriodEnd
    : ClientManagementEnums.TaxPeriodStartDates[defaultTaxStartPeriodKey];

  const GSTBasis = existsOrIsNull(businessesStore.selectedBusinessConfig?.overrides?.salesTaxBasis)
    ? businessesStore.selectedBusinessConfig?.overrides?.salesTaxBasis
    : existsOrIsNull(businessesStore.selectedBusiness.salesTaxBasis)
      ? businessesStore.selectedBusiness.salesTaxBasis
      : defaultGSTBasis;

  const PeriodLength = existsOrIsNull(businessesStore.selectedBusinessConfig?.overrides?.salesTaxPeriod)
    ? businessesStore.selectedBusinessConfig?.overrides?.salesTaxPeriod
    : existsOrIsNull(businessesStore.selectedBusiness?.connectedApps?.[0]?.configuration?.entities?.[0]?.salesTaxPeriod)
      ? businessesStore.selectedBusiness?.connectedApps?.[0]?.configuration?.entities?.[0]?.salesTaxPeriod
      : PeriodDetailEnums.TaxPeriodLengthKeys[defaultPeriodLength];

  const superPaymentOptions = PeriodDetailConstants.superannuationPeriods[countryCode === 'AU' ? countryCode : 'default'];

  const defaultSuperPeriod = PeriodDetailEnums.DefaultSuperannuationPeriod?.[countryCode];

  const onFormChange = (values: {
    bankAccounts?: string,
    basLodgementProgram?: string,
    taxRate?: string,
    superPaymentFrequency?: string,
    qbClearedAsReconciled?: boolean,
    gstBasis?: TaxBasisKeys,
    gstPeriod?: TaxPeriodLengthKeys,
    fiscalYearStartMonth?: FinancialYearEndDates,
    taxYearStartMonth?: TaxPeriodStartDates,
  }) => {
    const updatedBusiness = { ...businessesStore.selectedBusiness };
    if (values.bankAccounts) {
      _.set(updatedBusiness, 'profile.hasSelectedBankAccount', values.bankAccounts);
    }
    if (values.basLodgementProgram) {
      _.set(updatedBusiness, 'profile.taxAgent', values.basLodgementProgram);
    }
    if (values.taxRate) {
      _.set(updatedBusiness, 'profile.taxRate', values.taxRate);
    }
    if (values.superPaymentFrequency) {
      _.set(updatedBusiness, 'profile.superPaymentFrequency', values.superPaymentFrequency);
    }

    businessesStore.updateBusiness(updatedBusiness);

    // Store to config not profile so as not to be overwritten by next ETL
    const businessConfig = { ...businessesStore.selectedBusinessConfig };
    if (!businessConfig.overrides) {
      businessConfig.overrides = {};
    }
    if (values.gstBasis) {
      businessConfig.overrides.salesTaxBasis = values.gstBasis;
    }
    if (values.gstPeriod) {
      businessConfig.overrides.salesTaxPeriod = values.gstPeriod;
    }
    if (values.fiscalYearStartMonth) {
      businessConfig.overrides.financialYearEnd = values.fiscalYearStartMonth;
    }
    if (values.taxYearStartMonth) {
      businessConfig.overrides.salesTaxPeriodEnd = values.taxYearStartMonth;
    }
    if (values.qbClearedAsReconciled !== null && values.qbClearedAsReconciled !== undefined) {
      businessConfig.overrides.qbClearedAsReconciled = values.qbClearedAsReconciled;
    }

    businessesStore.updateSelectedBusinessConfig(businessConfig);
  };

  const handleSave = async () => {
    setSaving(true);

    // TODO:
    // Currently doing this here, because onFormChange happens before the AccountsTreeSelect has a chance to do its formatting,
    // meaning unformatted accounts (which can't be filtered down to childless only) would be set to the config if we tried to do it there
    // Unfortunately, if the user doesn't change the accounts, this approach ends up saving the defaults to config, which we don't want.
    // Plus, it's ugly as hell. Need a better way.
    const formItems = await form.validateFields();
    if (formItems.revenueAccounts?.length) {
      const businessConfig: any = { ...businessesStore.selectedBusinessConfig }; // TODO update that constants library...
      if (!businessConfig.overrides) {
        businessConfig.overrides = {};
      }
      businessConfig.overrides.revenueAccounts = formItems.revenueAccounts.filter((account) => account.childless).map((acc) => acc.value);
    }
    if (formItems.directCostsAccounts?.length) {
      const businessConfig: any = { ...businessesStore.selectedBusinessConfig }; // TODO update that constants library...
      if (!businessConfig.overrides) {
        businessConfig.overrides = {};
      }
      businessConfig.overrides.directCostsAccounts = formItems.directCostsAccounts.filter((account) => account.childless).map((acc) => acc.value);
    }
    if (formItems.opexAccounts?.length) {
      const businessConfig: any = { ...businessesStore.selectedBusinessConfig }; // TODO update that constants library...
      if (!businessConfig.overrides) {
        businessConfig.overrides = {};
      }
      businessConfig.overrides.opexAccounts = formItems.opexAccounts.filter((account) => account.childless).map((acc) => acc.value);
    }
    if (formItems.incomeTaxAccounts?.length) {
      const businessConfig: any = { ...businessesStore.selectedBusinessConfig }; // TODO update that constants library...
      if (!businessConfig.overrides) {
        businessConfig.overrides = {};
      }
      businessConfig.overrides.incomeTaxAccounts = formItems.incomeTaxAccounts.filter((account) => account.childless).map((acc) => acc.value);
    }

    try {
      const businessId = businessesStore.selectedBusinessId;
      await businessesStore.persistSelectedBusiness();
      // Update all insights after saving
      if (!resyncStore.resyncingBusinesses.get(businessId) && !resyncStore.loadingBusinesses.get(businessId)) {
        resyncStore.loadingBusinesses.set(businessId, true);
        resyncStore.triggerCalculationResync(businessId); // not awaiting because we don't want to block the UI
      }
    } catch (e) {
      handleError({ error: e, transaction: 'Client Configuration - Save' });
    }

    setSaving(false);
  };

  const salesTaxPeriodOpts: SelectProps['options'] = PeriodDetailConstants.taxPeriodLengths?.[countryCode] || PeriodDetailConstants.taxPeriodLengths.default;

  return !businessesStore?.selectedBusiness?.name
    || businessesStore.bankAccountDebounce.indexOf(businessesStore.selectedBusinessId) !== -1
    || businessesStore.businessConfigsDebounce.indexOf(businessesStore.selectedBusinessId) !== -1
    ? (<LoadingSpinner />)
    : (
      <Form form={form} className='client-settings__content scrollbar' layout='vertical' onValuesChange={onFormChange}>
        <div className='client-settings__content--main scrollbar'>
          <h3>Client Settings</h3>
          <h4>Accounting Settings</h4>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='fiscalYearStartMonth'
                label='First month of fiscal year'
                initialValue={fyStartDate}
              >
                <Select
                  disabled={isXero}
                  className='client-settings__content--input'
                  showSearch
                  filterOption={(input: string, option: DefaultOptionType) => {
                    if (!input) return true;
                    if (typeof option.label === 'string') {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return option.label === input;
                  }}
                  size='large'
                  options={PeriodDetailConstants.financialYearEndDates}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects:{' '}
                  <b>
                    Activity Summary, Period Close, Cash Flow, Income Tax
                    , Profitability Insights
                  </b>
                </li>
                <li>
                  Default:{' '}
                  <b>
                    per {ClientManagementEnums.OSPNames[osp]} settings, OR{' '}
                    {ClientManagementEnums.MonthNames[defaultFinancialYearEndKey]}
                    {' '}if none available.
                  </b>
                </li>
              </ul>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='bankAccounts'
                label='Active business bank accounts'
                initialValue={
                  Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount).length > 0
                    ? Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount)
                    : (bankAccounts.map((account) => account.accountId))
                  }
              >
                <Select
                  mode='multiple'
                  size='large'
                  placeholder={bankAccounts?.length === 0 && !businessesStore.fetchingBankAccounts ? 'No bank accounts found...' : 'Select bank accounts...'}
                  className='bankSelect'
                  allowClear
                  options={
                    bankAccounts
                      ?.slice()
                      .sort((a, b) => a.accountName.localeCompare(b.accountName))
                      .map((account) => ({
                        label: account.accountName,
                        value: account.accountId,
                      }))
                  }
                  dropdownRender={(menu) => (
                    <div className='bankSelect__dropdown'>
                      {menu}
                    </div>
                  )}
                  maxTagCount={0}
                  maxTagPlaceholder={
                    businessesStore.selectedBusiness.profile.hasSelectedBankAccount.length === 0
                    || businessesStore.selectedBusiness.profile.hasSelectedBankAccount.length === bankAccounts.length
                      ? 'All bank accounts selected'
                      : `${businessesStore.selectedBusiness.profile.hasSelectedBankAccount.length} bank accounts selected`
                  }
                  loading={
                    businessesStore.fetchingBankAccounts
                    || !businessesStore.selectedBusinessBankAccounts
                  }
                  disabled={
                    businessesStore.fetchingBankAccounts
                    || !businessesStore.selectedBusinessBankAccounts
                    || bankAccounts.length === 0
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Cash Flow insights, Period Close</b>
                </li>
                <li>
                  Default: <b>All active bank accounts in {ClientManagementEnums.OSPNames[osp]} selected.</b>
                </li>
                <li>
                  Please deselect any bank accounts that wouldn't normally be included in business cash flow calculation or bank reconciliation tasks - eg loan and personal accounts.
                </li>
              </ul>
            </Col>
          </Row>

          {!isXero && (
            <Row gutter={16}>
              <Col sm={24} md={12}>
                <Form.Item
                  name='qbClearedAsReconciled'
                  label='Reconciliation - treat Cleared transactions as...'
                  initialValue={businessesStore.selectedBusinessConfig?.overrides?.qbClearedAsReconciled ?? true}
                >
                  <Radio.Group>
                    <Radio value>
                      Reconciled
                    </Radio>
                    <Radio value={false}>
                      Unreconciled
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <ul>
                  <li>
                    Affects: <b>Period Close</b>
                  </li>
                  <li>
                    Default: <b>Reconciled</b>
                  </li>
                </ul>
              </Col>
            </Row>
          )}

          <h4>Tax & Compliance Settings</h4>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item name='taxRate' label='Income tax rate' initialValue={businessesStore.selectedBusiness.profile.taxRate || businessesStore.defaultTaxRate}>
                <Input
                  className='client-settings__content--input__taxRate'
                  size='large'
                  min={0}
                  max={99}
                  step={1}
                  type='number'
                  name='taxRate'
                  suffix='%'
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Income Tax Insight</b>
                </li>
                <li>
                  Default{' '}<b>{`${businessesStore?.defaultTaxRate}%`}</b>
                </li>
              </ul>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='incomeTaxAccounts'
                label='Income tax Revenue & Expenses accounts'
                initialValue={
                  Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount).length > 0
                    ? Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount)
                    : (bankAccounts.map((account) => account.accountId))
                  }
              >
                <AccountsTreeSelect
                  sectionId='incomeTaxAccounts'
                  treeCheckable
                  businessId={businessesStore.selectedBusinessId}
                  osp={osp}
                  dataId='incomeTaxAccounts'
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Income Tax insight</b>
                </li>
                <li>
                  Default: <b>per {ClientManagementEnums.OSPNames[osp]} P&L Report.</b>
                </li>
              </ul>
            </Col>
          </Row>

          {countryCode !== 'US' && (
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='gstBasis'
                label='GST basis'
                initialValue={nullToEmptyString(GSTBasis)}
              >
                <Select
                  className='client-settings__content--input'
                  showSearch
                  size='large'
                  options={ClientDetailConstants.TaxBasis[osp]}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Activity Summary, Period Close, GST Insights</b>
                </li>
                <li>
                  Default{' '}
                  <b>
                    per {ClientManagementEnums.OSPNames[osp]} settings, OR{' '}
                    {PeriodDetailEnums.DefaultTaxBasis[osp]} if none available
                  </b>
                </li>
              </ul>
            </Col>
          </Row>
          )}

          {countryCode !== 'US' && (
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item name='gstPeriod' label='GST period' initialValue={nullToEmptyString(PeriodLength)}>
                <Select
                  className='client-settings__content--input'
                  showSearch
                  size='large'
                  options={salesTaxPeriodOpts}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Activity Summary, Period Close, GST Insights</b>
                </li>
                <li>
                  Default{' '}
                  <b>
                    per {ClientManagementEnums.OSPNames[osp]} settings, OR{' '}
                    {PeriodDetailEnums.TaxPeriodLengthLabels[PeriodDetailEnums.DefaultTaxPeriodLength?.[countryCode]
                    || PeriodDetailEnums.DefaultTaxPeriodLength.default]} if none available.
                  </b>
                </li>
              </ul>
            </Col>
          </Row>
          )}

          {countryCode !== 'US' && (
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='taxYearStartMonth'
                label='First month of GST period'
                initialValue={nullToEmptyString(taxPeriodStart)}
              >
                <Select
                  className='client-settings__content--input'
                  showSearch
                  size='large'
                  options={PeriodDetailConstants.taxPeriodStartDates}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects:{' '}
                  <b>
                    Activity Summary, Period Close, GST insights
                  </b>
                </li>
                <li>
                  Default:{' '}
                  <b>per latest finalised GST Return, OR {ClientManagementEnums.MonthNames[defaultTaxStartPeriodKey]} if none available</b>
                </li>
              </ul>
            </Col>
          </Row>
          )}

          {countryCode === 'AU' && (
            <Row gutter={16}>
              <Col sm={24} md={12}>
                <Form.Item
                  name='basLodgementProgram'
                  label='Australian agent BAS lodgement program'
                  initialValue={businessesStore.selectedBusiness.profile.taxAgent || 'yes'}
                >
                  <Radio.Group>
                    <Radio value='yes'>
                      Tax filing extension applies
                    </Radio>
                    <Radio value='no'>
                      No tax filing extension - standard due dates apply
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <ul>
                  <li>
                    Affects: <b>Period Close, GST insight</b>
                  </li>
                  <li>
                    Default: <b>Tax filing extension applies</b>
                  </li>
                </ul>
              </Col>
            </Row>
          )}

          {splitStore.featureEnabled('superSettings') && defaultSuperPeriod && (
            <Row gutter={16}>
              <Col sm={24} md={12}>
                <Form.Item
                  name='superPaymentFrequency'
                  label='Superannuation period'
                  initialValue={businessesStore.selectedBusiness.profile.superPaymentFrequency || defaultSuperPeriod}
                >
                  <Select
                    className='client-settings__content--input'
                    showSearch
                    size='large'
                    options={superPaymentOptions}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <ul>
                  <li>
                    Affects: <b>Period Close, Tax insights</b>
                  </li>
                  <li>
                    Default: <b>{PeriodDetailEnums.SuperannuationPeriodLabels[defaultSuperPeriod]}</b>
                  </li>
                </ul>
              </Col>
            </Row>
          )}

          <h4>Advisory Settings</h4>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='revenueAccounts'
                label='Revenue accounts'
                initialValue={
                  Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount).length > 0
                    ? Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount)
                    : (bankAccounts.map((account) => account.accountId))
                  }
              >
                <AccountsTreeSelect
                  sectionId='revenueAccounts'
                  treeCheckable
                  businessId={businessesStore.selectedBusinessId}
                  osp={osp}
                  dataId='revenueAccounts'
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Profitability insights</b>
                </li>
                <li>
                  Default: <b>per {ClientManagementEnums.OSPNames[osp]} P&L Report.</b>
                </li>
              </ul>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='directCostsAccounts'
                label='Direct Costs accounts'
                initialValue={
                  Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount).length > 0
                    ? Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount)
                    : (bankAccounts.map((account) => account.accountId))
                  }
              >
                <AccountsTreeSelect
                  sectionId='directCostsAccounts'
                  treeCheckable
                  businessId={businessesStore.selectedBusinessId}
                  osp={osp}
                  dataId='directCostsAccounts'
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Profitability insights</b>
                </li>
                <li>
                  Default: <b>per {ClientManagementEnums.OSPNames[osp]} P&L Report.</b>
                </li>
              </ul>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name='opexAccounts'
                label='Operating Expenses accounts'
                initialValue={
                  Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount).length > 0
                    ? Object.values(businessesStore.selectedBusiness.profile.hasSelectedBankAccount)
                    : (bankAccounts.map((account) => account.accountId))
                  }
              >
                <AccountsTreeSelect
                  sectionId='opexAccounts'
                  treeCheckable
                  businessId={businessesStore.selectedBusinessId}
                  osp={osp}
                  dataId='opexAccounts'
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <ul>
                <li>
                  Affects: <b>Profitability insights</b>
                </li>
                <li>
                  Default: <b>per {ClientManagementEnums.OSPNames[osp]} P&L Report.</b>
                </li>
              </ul>
            </Col>
          </Row>

        </div>
        <footer>
          <ButtonPrimary
            loading={saving}
            disabled={saving}
            onClick={handleSave}
            size='large'
            mixpanelEvent='Client Configuration - Save'
            mixpanelProperties={{ updatedBusiness: { id: businessesStore.selectedBusinessId, name: businessesStore.selectedBusiness?.name } }}
          >
            Save
          </ButtonPrimary>
        </footer>
      </Form>
    );
};

export default observer(ClientConfiguration);
