import React, { useState } from 'react';
import { Button, Form, Tooltip } from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import Markdown from 'markdown-to-jsx';
import { AiderBrandColorful } from '../icons';
import UserIcon from './UserIcon';

import { useStore } from '../../stores/Store';
import SavePromptModal from '../modals/SavePromptModal';
import InteractiveTable from './InteractiveTable';

const Links = ({ children, ...props }) => (
  <a
    {...props}
    target='_blank'
  >
    {children}
  </a>
);

function extractMarkdownTables(text) {
  /**
   * Extracts all markdown tables from the given text.
   *
   * @param {string} text - The text containing the markdown tables.
   * @returns {Array<Array<Object>>} An array of arrays, each containing objects representing the table rows.
   */

  // Regular expression to match table lines
  const tableRegex = /\|.*\|/g;
  const tableLines = text.match(tableRegex);

  if (!tableLines) {
    return [];
  }

  const tables = [];
  let currentTable = [];
  let headers = [];

  tableLines.forEach((line, index) => {
    const isSeparator =
      index < tableLines.length - 1
      && tableLines[index + 1].trim().match(/^\|(\s*-+\s*\|)+$/);

    if (isSeparator || index === 0) {
      if (currentTable.length > 0) {
        tables.push(currentTable);
        currentTable = [];
      }
      headers = line
        .split('|')
        .map((header) => header.trim())
        .filter((header) => header);
    } else if (!line.trim().match(/^\|(\s*-+\s*\|)+$/)) {
      const values = line
        .split('|')
        .map((value) => value.trim())
        .filter((value) => value);
      const row = {};
      headers.forEach((header, idx) => {
        row[header] = values[idx] || '';
      });
      currentTable.push(row);
    }
  });

  if (currentTable.length > 0) {
    tables.push(currentTable);
  }

  return tables;
}

function replaceMarkdownTable(text) {
  /**
   * Removes the markdown table from the given text.
   *
   * @param {string} text - The text containing the markdown table.
   * @returns {string} The text with the markdown table removed.
   */

  // Regular expression to match table lines
  const tableRegex = /(?:(?:\|[^|\r\n]*)+\|(?:\r?\n|\r)?)+/g;
  const tableLines = text.match(tableRegex);

  if (!tableLines) {
    return (
      <Markdown
        options={{
          overrides: {
            a: {
              component: Links,
            },
          },
        }}
      >
        {text}
      </Markdown>
    );
  }

  // Extract the JSON data and transform it into antd Table format
  const tables = extractMarkdownTables(text);

  // Split the text into parts around the tables
  const parts = text.split(tableRegex);
  const interpolatedParts = [];

  let tableIndex = 0;
  for (let i = 0; i < parts.length; i++) {
    interpolatedParts.push(
      <Markdown
        key={`text-${i}`}
        options={{
          overrides: {
            a: {
              component: Links,
            },
          },
        }}
      >
        {parts[i]}
      </Markdown>
    );

    if (tableIndex < tables.length) {
      interpolatedParts.push(
        <InteractiveTable
          key={`table-${tableIndex}`}
          data={tables[tableIndex]}
        />
      );
      tableIndex++;
    }
  }

  return <>{interpolatedParts}</>;
}

const ChatMessage = ({ message, page }) => {
  const [copyState, setCopyState] = useState(false);
  const rootStore = useStore();
  const [form] = Form.useForm();

  const copyMessage = () => {
    setCopyState(true);
    navigator.clipboard.writeText(message.content);
    setTimeout(() => {
      setCopyState(false);
    }, 1000);
  };

  return (
    <div className={`chat-message message-type-${message.role} scrollbar`}>
      {message.role !== 'user' ? (
        <AiderBrandColorful className='aider-icon' />
      ) : (
        <UserIcon />
      )}
      <div className='chat-message__text'>
        {replaceMarkdownTable(message.content)}
      </div>
      {message.role === 'user' ? (
        <Tooltip
          title='Add to My Prompts'
          placement='left'
        >
          <Button
            type='primary'
            ghost
            className='copy-button'
            icon={<PlusOutlined />}
            shape='circle'
            size='large'
            onClick={() => {
              form.setFieldValue('prompt', message.content);
              const prompt = {
                label: '',
                text: message.content,
                page,
              };
              return SavePromptModal(rootStore, prompt, form);
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={copyState ? 'Copied!' : 'Copy to clipboard'}
          placement='left'
        >
          <Button
            type='primary'
            ghost
            className='copy-button'
            icon={<CopyOutlined />}
            shape='circle'
            size='large'
            onClick={copyMessage}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ChatMessage;
