import { makeAutoObservable } from 'mobx';
import { firestore } from 'firebase';
import type { RootStore } from './Store';
import { POST, PUT } from '../lib/requests';
import { Routers } from '../models/enums/utils';
import handleError from '../lib/errorHandler';

export default class ResyncStore {
  rootStore: RootStore;

  resyncingBusinesses: Map<string, boolean> = new Map();

  loadingBusinesses: Map<string, boolean> = new Map();

  error: Map<string, boolean> = new Map();

  sectionKeys: string[] = ['modelTraining', 'gstTraining', 'cashflowTraining', 'dashboard', 'insights', 'tablegroup'];

  dataStatusDebounce: any;

  dataStatusSnapshots: Map<string, any> = new Map();

  globalResyncStarted: number = null;

  unsubscribeDataStatus: () => void = () => { };

  async triggerETLResync(businessId: string) {
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${businessId}/workflow/resync`;
    const status = await POST({ url, rootStore: this.rootStore });
    return status;
  }

  async triggerCalculationResync(businessId: string) {
    const url = `${process.env.REACT_APP_INSIGHT_ENDPOINT}/businesses/${businessId}/insights/refresh`;
    const status = await PUT({ url, rootStore: this.rootStore });
    return status;
  }

  async watchFirestore(localisation: Routers = Routers.DASHBOARD) {
    clearTimeout(this.dataStatusDebounce);
    this.dataStatusDebounce = setTimeout(() => {
      if (!this.rootStore.practiceStore.id) return;
      const query = firestore().collection('/businesses').where('managedBy', '==', this.rootStore.practiceStore.id);

      const unsubscribe = query.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (localisation === Routers.INSIGHTS) {
            if (doc.id !== this.rootStore.businessesStore.selectedBusinessId) return;

            const data = doc.data();
            if (data?.dataStatus) {
              this.dataStatusSnapshots.set(doc.id, data.dataStatus);
            }
            if (data?.profile?.dataStatus && data?.profile?.dataStatus !== this.rootStore.businessesStore.businesses.get(doc.id)?.profile?.dataStatus) {
              this.rootStore.businessesStore.updateBusinessByPath(doc.id, 'profile.dataStatus', data.profile.dataStatus);
            }
          } else if (
            (localisation === Routers.DASHBOARD && this.rootStore.dashboardStore.businesses.has(doc.id))
            || (localisation !== Routers.DASHBOARD && this.rootStore.businessesStore.activeClientBusinesses.has(doc.id))
          ) {
            const data = doc.data();
            if (data?.dataStatus) {
              this.dataStatusSnapshots.set(doc.id, data.dataStatus);
            }
            if (data?.profile?.dataStatus && data?.profile?.dataStatus !== this.rootStore.businessesStore.businesses.get(doc.id)?.profile?.dataStatus) {
              this.rootStore.businessesStore.updateBusinessByPath(doc.id, 'profile.dataStatus', data.profile.dataStatus);
            }
          }
        });
      }, (error) => {
        handleError({ error, transaction: 'Dashboard - Firestore Snapshot' });
      });
      this.unsubscribeDataStatus = unsubscribe;
    }, 1000);
  }

  isDataStatusStale(dataStatusObj: any, dataStatusKey: string) {
    const staleMills = 1000 * 60 * 60 * 3; // 3 hours

    if (dataStatusObj?.etl?.started) {
      const etlElapsed = Date.now() - dataStatusObj.etl.started?.toDate();
      const dataStatusElapsedMills = Date.now() - dataStatusObj[dataStatusKey]?.started?.toDate();
      const globalElapsed = Date.now() - this.globalResyncStarted;

      return (
        (etlElapsed > staleMills || (this.globalResyncStarted && globalElapsed > staleMills))
        && (dataStatusElapsedMills > staleMills)
      );
    }
    return false;
  }

  get dataStatusPendingData() {
    const dataStatus: Map<string, boolean> = new Map();
    this.dataStatusSnapshots.forEach((value, id) => {
      const res = Object.keys(value)?.map((key) => {
        try {
          if (this.isDataStatusStale(value, key)) {
            return false;
          }

          if (key.toLowerCase().includes('payroll')
            || key.indexOf(' ') > -1) {
            return false;
          }

          if (key === 'etl') {
            return (
              value.etl.ended < value.etl.started
            );
          }

          return (
            ![
              'NOT_CONFIGURED',
              'NO_RECENT_DATA',
              'MISSING_SCOPE',
              'COUNTRY_NOT_SUPPORTED',
            ].includes(value[key]?.status) && (!value[key]?.ended || value[key]?.ended < value.etl?.started)
            // item has to have a valid status, and either not ended or ended before the etl started
          );
        } catch (e) {
          return false;
        }
      });

      dataStatus.set(id, res.includes(true));
    });

    return dataStatus;
  }

  dataStatusErrorData(businessId: string) {
    const dataSnapshot = this.dataStatusSnapshots.get(businessId);

    if (!dataSnapshot) return [];

    const { etl } = dataSnapshot;

    if (!etl) return [];

    const { tasks } = etl;
    const etlErrors = Object.keys(tasks)?.map((task) => {
      if (tasks[task].status === 'FAILED') {
        return tasks[task];
      }
      return null;
    })
      .filter((etlError) => etlError);

    return etlErrors;
  }

  constructor(initStore) {
    this.rootStore = initStore;
    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }
}
