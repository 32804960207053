import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Collapse, CollapseProps, Form, Select, Switch } from 'antd';
import { observer } from 'mobx-react';
import lookup from 'country-code-lookup';
import Format, {
  FormatTypes,
  ValueTypes,
} from '@aider/aider-formatting-library';
import {
  getCurrentFinancialYear,
  getOffsetFromPeriodNonISO,
  PeriodTypes,
} from '@aider/aider-period-library';
import { useStore } from '../../stores/Store';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import { POST } from '../../lib/requests';
import ChatWindow from './ChatWindow';
import UserChatInput from './UserChatInput';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import PeriodGranularitySelectComponent from '../PeriodGranularity';
import { InsightType } from '../../ts/enums/Constants';
import AssistantLoader from './AssistantLoader';

enum Data {
  BALANCE_SHEET = 'Balance Sheet',
  PNL = 'Profit and Loss',
  INSIGHTS = 'Profitability Insights',
  DASHBOARD = 'Dashboard',
}

const AssistantChat = ({ page = 'insights' }) => {
  const rootStore = useStore();
  rootStore.assistantStore.setPage(page);
  const { businessesStore } = rootStore;
  const [form] = Form.useForm();
  const [messages, setMessages] = useState([]);
  const [dataIncluded, setDataIncluded] = useState(
    page === 'dashboard'
      ? [Data.DASHBOARD]
      : page === 'insights'
        ? [Data.BALANCE_SHEET, Data.PNL, Data.INSIGHTS]
        : []
  );
  const [waitingForAi, setWaitingForAi] = useState(false);
  const [provider, setProvider] = useState('gpt4');
  const [tempContext, setTempContext] = useState(null);
  const [assistantBusinessId, setAssistantBusinessId] = useState(null);

  let yearOfPrevFinancialYear = null;
  if (page === 'insights' && businessesStore.selectedBusiness.connection) {
    const format = new Format(
      businessesStore.selectedBusiness.connection?.currencyCode,
      businessesStore.selectedBusiness.connection?.countryCode
    );
    const currentFinancialYear = getCurrentFinancialYear(
      businessesStore.selectedBusiness.connection?.financialYearEnd,
      12,
      businessesStore.selectedBusiness.connection?.timeZoneId
    );
    const previousFinancialYear = getOffsetFromPeriodNonISO(
      currentFinancialYear.periodStart,
      currentFinancialYear.periodEnd,
      businessesStore.selectedBusiness.connection?.timeZoneId,
      -1
    );
    yearOfPrevFinancialYear = format.formatValue({
      format: ValueTypes.formatDate,
      value: previousFinancialYear.periodEnd,
      dateFormat: { year: FormatTypes.long },
    });
  }

  useEffect(() => {
    rootStore.assistantStore.clearChat();
    rootStore.assistantStore.loadChatHistory();
  }, [rootStore.practiceStore.id]);

  useEffect(() => {
    const element = document.getElementById('assistant-user-input');
    const parent = document.getElementById('insightContainer');
    if (parent && element) {
      parent.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [rootStore.assistantStore.messageHistory]);

  const checklistAction = (key: Data) => {
    let description = 'Submit data to AI';
    if (!dataIncluded.includes(key)) {
      setDataIncluded([...dataIncluded, key]);
    } else {
      setDataIncluded(dataIncluded.filter((included) => included !== key));
      description = 'Unsubmit data to AI';
    }
    trackMixpanelEvent({
      description,
      properties: { key },
      rootStore,
    });
  };

  const dataButtons = [
    {
      key: 'balanceSheet',
      label: 'Balance Sheet',
      type: Data.BALANCE_SHEET,
      disabled: false,
      action: () => checklistAction(Data.BALANCE_SHEET),
    },
    {
      key: 'profitAndLoss',
      label: 'Profit & Loss',
      type: Data.PNL,
      disabled: false,
      action: () => checklistAction(Data.PNL),
    },
    {
      key: 'profitabilityInsights',
      label: 'Profitability Insights',
      type: Data.INSIGHTS,
      disabled:
        rootStore.timePeriodStore.periodGranularity === PeriodTypes.YEARLY,
      action: () => rootStore.timePeriodStore.periodGranularity !== PeriodTypes.YEARLY
        && checklistAction(Data.INSIGHTS),
    },
  ];

  const industry = businessesStore.selectedBusiness?.lineOfBusiness;
  const country = businessesStore.selectedBusiness
    ? lookup.byIso(businessesStore.selectedBusiness?.countryCode).country
    : businessesStore.selectedBusiness?.countryCode;

  const insertPrompt = (label: string, prompt: string) => {
    form.setFieldValue('message', prompt);
    trackMixpanelEvent({
      description: 'Use prompt',
      properties: { label, prompt },
      rootStore,
    });
  };

  const insightPrompts = [
    {
      label: 'Client meeting & agenda',
      action: async () => {
        insertPrompt(
          'Client meeting & agenda',
          `I want to discuss with my advisory client their small ${industry} business’ performance (revenue, pricing, profitability), income tax, GST payments, cashflow management & planning.\n\nDraft an email that helps them understand the importance of meeting about the business to help them improve their performance. \nInclude a meeting agenda touching on the main topics.`
        );
      },
    },
    {
      label: 'Client data summary',
      action: async () => {
        insertPrompt(
          'Client data summary',
          `I want to discuss with my advisory client the performance of their small ${country} business in ${industry}.\n\nUse the financials provided, draft a bullet pointed summary of less than 500 words that highlights any key variances in financial performances compared to last period & suggests 3 key actions I can recommend my client take to improve performance.`
        );
      },
    },
    {
      label: 'Client sales proposal',
      action: async () => {
        insertPrompt(
          'Client sales proposal',
          'I want my small business accounting client to understand that monthly reporting, taking & tracking actions to improve business performance can help them reach their long-term goals.\n\nDraft a sales proposal for a monthly advisory service that will include next step action suggestions to improve their business growth & profitability.'
        );
      },
    },
    {
      label: 'Client engagement letter',
      action: async () => {
        insertPrompt(
          'Client engagement letter',
          'I want my client to review, accept & sign an engagement letter for additional monthly advisory service.\n\nDraft a simple engagement letter, outline the regular tax & compliance services plus an additional monthly performance review call. Include the date, practice details & address at the bottom of the letter.'
        );
      },
    },
    {
      label: 'Client goals template',
      action: async () => {
        insertPrompt(
          'Client goals template',
          `I want my business advisory client to set future targets for the next 3 financial years. Create a table using the ${yearOfPrevFinancialYear} financial year values for Total Income, Gross Profit, Total Operating Expenses, & Net Profit; leave boxes for the next 3 years for the client to set their $ value goals. Add areas for entering: 1 Sales/marketing goals for next month, quarter, year; 2 Operational goals for next month, quarter, year; 3 Lifestyle goals they want their business to help them achieve – include example “e.g. Spend 25% less time working in 3 years”.`
        );
      },
    },
  ];

  const dashboardPrompts = [
    {
      label: 'Advisory Opportunities',
      action: async () => {
        insertPrompt(
          'Advisory Opportunities',
          'Whom in my client list would benefit from some advisory services?'
        );
      },
    },
  ];

  const userChat = async (advisorForm: any) => {
    rootStore.assistantStore.timestamp = new Date();
    const message = advisorForm.getFieldValue('message');

    setWaitingForAi(true);
    let systemMessage = 'When applicable, show results in a table format.\n';

    if (tempContext !== null) {
      systemMessage += tempContext;
      setTempContext(null);
    }

    if (
      dataIncluded.includes(Data.INSIGHTS)
      && rootStore.timePeriodStore.periodGranularity !== PeriodTypes.YEARLY
    ) {
      systemMessage += `\nFinancial Performance\nProfitability: ${rootStore.assistantStore
        .getInsights()
        .map((insight: any) => `\n## ${insight.title}\n${insight.message}\n`)}`;
    }

    if (dataIncluded.includes(Data.BALANCE_SHEET)) {
      systemMessage += await rootStore.assistantStore.getBalanceSheetContext();
    }

    if (dataIncluded.includes(Data.PNL)) {
      systemMessage += await rootStore.assistantStore.getProfitAndLossContext();
    }

    if (dataIncluded.includes(Data.DASHBOARD)) {
      let dataset = [
        'unreconciled',
        'revenue',
        'netProfit',
        'incometax',
        'cashflow',
        'gstcompletion',
        'gstestimate',
      ];
      try {
        const metaQuestion = `
        Your goal is to list the datasets that are required to answer the user's question: "${message}". The datasets must be chosen from the following:
        - unreconciled: the total number of of bank transactions from all selected bank accounts that have not been reconciled and the date of the earliest unreconciled transaction.
        - revenue: Revenue for the current financial year up to the last full month vs. same period last year.
        - netProfit: Net profit for the current financial year up to the last full month vs. same period last year.
        - incometax: Income tax forecast for the current financial year to the last full month vs last year.
        - cashflow: Cash position estimate for the current month vs the previous month.
        - gstcompletion: GST period reconciled transactions completion rate and GST due date. The proportion of transactions that have been reconciled for the current GST period and the due date for the GST return.
        - gstestimate: GST forecast for the next period due date vs average GST paid.

        Make sure the output only contains datasets provided above and no others.
        Your response should be a list of comma separated values, eg: foo, bar, baz
        `;
        const analysis = await POST({
          url: `${process.env.REACT_APP_INSIGHT_ENDPOINT}/chat`,
          data: {
            provider,
            messages: [
              {
                role: 'system',
                content:
                  'We need to identify what data to use to answer a question, provide only the list of datasets',
              },
              {
                role: 'user',
                content: metaQuestion,
              },
            ],
          },
          rootStore,
        });
        dataset = analysis.message.content
          .split(',')
          .map((d: string) => d.trim());
      } catch (e) {
        Sentry.captureException(e);
      }
      systemMessage += `\nI'm a Chartered Accountant and Advisor. This information is the high level financial performance of my clients. Use this information to guide your answers.\n${rootStore.assistantStore.getDashboard(
        dataset
      )}`;
    }

    const actionMessage = {
      role: 'user',
      content: message,
    };

    setMessages([...messages, actionMessage]);
    rootStore.assistantStore.appendChat({
      message,
      from: 'user',
      includedDocuments: dataIncluded,
      provider,
    });
    advisorForm.resetFields();
    trackMixpanelEvent({
      description: 'Chat with AI',
      properties: { message, provider, dataIncluded, page },
      rootStore,
    });

    if (page === 'insights' || (page === 'assistant' && assistantBusinessId)) {
      const modelMap = {
        gpt4: 'gpt-4o',
        palm2: 'gemini-1.5-pro',
      };
      const data = {
        model: modelMap[provider],
        question: actionMessage.content,
        context: systemMessage,
        historicalMessages: messages,
      };

      const businessId =
        page === 'insights'
          ? businessesStore.selectedBusinessId
          : assistantBusinessId;

      try {
        const response = await POST({
          url: `${process.env.REACT_APP_LLM_ENDPOINT}/api/v1/business/${businessId}/dataChatWithContext`,
          data,
          rootStore,
        });
        setWaitingForAi(false);

        rootStore.assistantStore.appendChat({
          message: response.answer,
          from: 'assistant',
          provider: modelMap[provider],
        });

        setMessages([
          ...messages,
          actionMessage,
          { role: 'assistant', content: response.answer },
        ]);
      } catch (e) {
        Sentry.captureException(e);
        setWaitingForAi(false);
        setMessages([
          ...messages,
          actionMessage,
          {
            role: 'assistant',
            content: 'Sorry, there was an issue talking to the AI.',
          },
        ]);
      }
    } else {
      const data = {
        provider,
        messages: [
          { role: 'system', content: systemMessage },
          ...messages,
          actionMessage,
        ],
      };

      try {
        const response = await POST({
          url: `${process.env.REACT_APP_INSIGHT_ENDPOINT}/chat`,
          data,
          rootStore,
        });
        setWaitingForAi(false);
        rootStore.assistantStore.appendChat({
          message: response.message.content,
          from: 'assistant',
          provider,
        });
        setMessages([...messages, actionMessage, response.message]);
      } catch (e) {
        Sentry.captureException(e);
        setWaitingForAi(false);
        setMessages([
          ...messages,
          actionMessage,
          {
            role: 'assistant',
            content: 'Sorry, there was an issue talking to the AI.',
          },
        ]);
      }
    }
  };

  const handlePeriodSelection = (newPeriod: string) => {
    rootStore.timePeriodStore.setProfitabilityPeriodSelected(newPeriod);
  };

  const userPrompts = rootStore.assistantStore.userPrompts.filter(
    (p) => p.page === page || (!p.page && page === 'insights')
  );

  const items: CollapseProps['items'] =
    page === 'dashboard'
      ? [
        {
          key: '1',
          label: 'Quick Chat Prompts',
          children: (
            <>
              {dashboardPrompts.map((b) => (
                <ButtonSecondary
                  key={b.label}
                  className='chat-prompt'
                  type='primary'
                  ghost
                  shape='round'
                  onClick={b.action}
                >
                  {b.label}
                </ButtonSecondary>
              ))}
            </>
          ),
        },
        {
          key: '2',
          label: 'My Chat Prompts',
          children: (
            <>
              {userPrompts.length === 0 && <p>You have no saved prompts</p>}
              {userPrompts.map((p) => (
                <ButtonSecondary
                  key={p.label}
                  className='chat-prompt'
                  type='primary'
                  ghost
                  shape='round'
                  onClick={() => {
                    insertPrompt(p.label, p.text);
                  }}
                >
                  {p.label}
                </ButtonSecondary>
              ))}
            </>
          ),
        },
      ]
      : page === 'insights'
        ? [
          {
            key: '1',
            label: 'Quick Chat Prompts',
            children: (
              <>
                {insightPrompts.map((b) => (
                  <ButtonSecondary
                    key={b.label}
                    className='chat-prompt'
                    type='primary'
                    ghost
                    shape='round'
                    onClick={b.action}
                  >
                    {b.label}
                  </ButtonSecondary>
                ))}
              </>
            ),
          },
          {
            key: '2',
            label: 'My Chat Prompts',
            children: (
              <>
                {userPrompts.length === 0 && <p>You have no saved prompts</p>}
                {userPrompts.map((p) => (
                  <ButtonSecondary
                    key={p.label}
                    className='chat-prompt'
                    type='primary'
                    ghost
                    shape='round'
                    onClick={() => {
                      insertPrompt(p.label, p.text);
                    }}
                  >
                    {p.label}
                  </ButtonSecondary>
                ))}
              </>
            ),
          },
        ]
        : [
          {
            key: '1',
            label: 'My Chat Prompts',
            children: (
              <>
                {userPrompts.length === 0 && <p>You have no saved prompts</p>}
                {userPrompts.map((p) => (
                  <ButtonSecondary
                    key={p.label}
                    className='chat-prompt'
                    type='primary'
                    ghost
                    shape='round'
                    onClick={() => {
                      insertPrompt(p.label, p.text);
                    }}
                  >
                    {p.label}
                  </ButtonSecondary>
                ))}
              </>
            ),
          },
        ];

  return (
    <Form
      form={form}
      className='advisory-ai'
      style={page === 'assistant' ? { padding: '0' } : {}}
    >
      <main className='advisory-ai__content'>
        <section className='advisory-ai__content--sidebar scrollbar'>
          <aside>
            <h3>AI option: Aider +</h3>
            <Select
              defaultValue='gpt4'
              onChange={setProvider}
              options={[
                { value: 'gpt4', label: 'Secure ChatGPT 4' },
                { value: 'palm2', label: 'Secure Google Gemini' },
              ]}
            />
          </aside>
          {page === 'assistant' && (
            <aside>
              <h3>Select Client (optional):</h3>
              <Select
                placeholder='Select a client'
                options={businessesStore.sortedActiveClientBusinesses.map(
                  (business) => ({
                    value: business.id,
                    label: business.name,
                  })
                )}
                onChange={(businessId) => {
                  // Handle business selection
                  setAssistantBusinessId(businessId);
                }}
                value={assistantBusinessId}
              />
            </aside>
          )}
          {page === 'insights' && (
            <aside>
              <h3>Data to include:</h3>
              <PeriodGranularitySelectComponent
                className='report-period-selection'
                type={InsightType.PROFITABILITY}
                yearly
              />
              {rootStore.timePeriodStore.profitabilityPeriodSelected && (
                <Select
                  className='report-period-selection'
                  defaultValue={
                    rootStore.timePeriodStore.profitabilityPeriodSelected
                  }
                  onChange={handlePeriodSelection}
                  size='middle'
                  options={rootStore.timePeriodStore.profitabilityPeriods}
                  value={rootStore.timePeriodStore.profitabilityPeriodSelected}
                />
              )}
              {dataButtons.map((data) => (
                <div
                  className='checkbox-item'
                  key={data.type}
                >
                  <Switch
                    id={`data-${data.key}`}
                    size='small'
                    className='checkbox-item__switch'
                    checked={dataIncluded.indexOf(data.type) > -1}
                    onChange={data.action}
                    disabled={data.disabled}
                  />
                  <label
                    htmlFor={`data-${data.key}`}
                    className='checkbox-item__label'
                    onClick={data.action}
                  >
                    {data.label}
                  </label>
                </div>
              ))}
            </aside>
          )}
          <aside style={{ padding: 0 }}>
            <h3 style={{ padding: '1rem 0 0 1rem' }}>Chat Prompts:</h3>
            <Collapse
              items={items}
              defaultActiveKey={['1']}
              size='small'
              ghost
            />
          </aside>
          <aside>
            <ButtonSecondary
              type='primary'
              className='btn-danger chat-prompt'
              ghost
              shape='round'
              size='small'
              onClick={() => {
                setMessages([]);
                setDataIncluded(
                  page === 'dashboard'
                    ? [Data.DASHBOARD]
                    : page === 'insights'
                      ? [Data.BALANCE_SHEET, Data.PNL, Data.INSIGHTS]
                      : []
                );
                form.setFieldValue('message', '');
                rootStore.assistantStore.clearChat();
              }}
            >
              + Start new chat
            </ButtonSecondary>
          </aside>
        </section>
        <section className='advisory-ai__content--main'>
          <section className='advisory-ai__content--main__chat-window scrollbar'>
            <ChatWindow
              chatMessages={messages}
              messageHistory={rootStore.assistantStore.messageHistory}
              page={page}
            />
            {waitingForAi && <AssistantLoader />}
          </section>
          <UserChatInput
            userChat={userChat}
            waitingForAi={waitingForAi}
          />
        </section>
      </main>
    </Form>
  );
};

export default observer(AssistantChat);
