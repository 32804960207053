import { Checkbox, Col, Form, InputNumber, Row } from 'antd';
import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import RequiredInput from './RequiredInput';
import AddressInput from './AddressInput';
import { useStore } from '../../stores/Store';
import { useEnterIntercept } from '../../lib/customHooks';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import Notification from '../Notification';
import { Routers } from '../../models/enums/utils';

interface FirmInformationFormProps {
  form: any;
  saving: boolean;
  setSaving: Function;
  successAction?: Function;
  formOffsetCols: number;
  isNewPractice?: boolean;
}

const FirmInformationForm = ({
  form,
  saving,
  setSaving,
  successAction,
  formOffsetCols,
  isNewPractice,
}:FirmInformationFormProps) => {
  const rootStore = useStore();
  const { localeStore, practiceStore } = rootStore;
  const [placeId, setPlaceId] = useState(null);
  const location = useLocation();

  // Method to save the user details if the form is valid
  const onFinish = async (values) => {
    setSaving(true);
    const practiceDetails = {
      name: values.name,
      address: values.address,
      numberOfClients: values.numberOfClients,
      numberOfAdvisors: values.numberOfAdvisors,
      accountingSoftware: values.accountingSoftwares,
    };

    trackMixpanelEvent({
      description: 'Save Firm Information',
      properties: practiceDetails,
      rootStore
    });

    try {
      practiceStore.updatePractice(practiceDetails);
      if (placeId) {
        await practiceStore.setPracticeAddressData(placeId);
      }
      if (isNewPractice) {
        await practiceStore.createPractice();
      } else {
        await practiceStore.persistPractice();
      }
      setSaving(false);

      Notification({
        type: 'success',
        title: 'Changes saved',
      });

      if (successAction) {
        successAction();
      }
      return ({ status: 'success' });
    } catch (error) {
      Sentry.captureException(error);
      setSaving(false);
      Notification({
        type: 'error',
        title: 'Sorry, unable to save changes',
        description: 'Please try again.'
      });
      return ({ status: 'error', error });
    }
  };
  // Method to handle form validation errors
  const onFinishFailed = () => {
    Notification({
      type: 'error',
      title: 'Sorry, unable to save changes',
      description: 'Please try again.'
    });
  };

  // Inject enter intercept handler into form, except on address field
  useEnterIntercept({
    dependencies: [form],
    callback: (event) => {
      if (event.target.id !== 'address') {
        form.submit();
      }
    }
  });

  return (practiceStore.hasRequiredInfo
      || location.pathname === Routers.ONBOARDING
  ) && (
    <Form
      className='settings-form'
      labelCol={{ span: formOffsetCols }}
      wrapperCol={{ span: 24 - formOffsetCols }}
      labelWrap
      labelAlign='right'
      requiredMark='optional'
      colon={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateTrigger='onBlur'
      form={form}
    >
      <Row>
        <Col xs={0} sm={formOffsetCols} />
        <Col>
          <h3>Firm Details</h3>
        </Col>
      </Row>
      <Form.Item
        name='name'
        label='Firm Name'
        required
        initialValue={practiceStore.name}
        rules={[{ required: true, message: 'Please enter a firm name' }]}
      >
        <RequiredInput loading={saving} />
      </Form.Item>

      <Form.Item
        name='address'
        label='Billing Address'
        required
        initialValue={practiceStore.address}
        rules={[{
          required: true,
          min: 2,
          message: 'Please enter a valid address' }]}
      >
        <AddressInput loading={saving} locationDataCallback={setPlaceId} />
      </Form.Item>

      <Row>
        <Col xs={0} sm={formOffsetCols} />
        <Col>
          <h3>About your firm</h3>
        </Col>
      </Row>
      <Form.Item
        name='numberOfClients'
        label='Number of Clients'
        initialValue={practiceStore.numberOfClients}
      >
        <InputNumber min={0} disabled={saving} />
      </Form.Item>
      <Form.Item
        name='numberOfAdvisors'
        label='Number of Advisors'
        initialValue={practiceStore.numberOfAdvisors}
      >
        <InputNumber min={0} disabled={saving} />
      </Form.Item>
      <Form.Item
        name='accountingSoftwares'
        label='Accounting Softwares'
        initialValue={practiceStore.accountingSoftware}
      >
        <Checkbox.Group disabled={saving}>
          <Checkbox value='xero'>{localeStore.translation('osps.xero')}</Checkbox>
          <Checkbox value='quickbooks'>{localeStore.translation('osps.intuit')}</Checkbox>
          <Checkbox value='myob'>{localeStore.translation('osps.myob')}</Checkbox>
          <Checkbox value='other'>{localeStore.translation('osps.other')}</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

FirmInformationForm.defaultProps = {
  successAction: null,
  isNewPractice: false,
};

export default observer(FirmInformationForm);
