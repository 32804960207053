// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, configure, observable, reaction } from 'mobx';
import React from 'react';
import cookie from 'js-cookie';
import ActionStore from './v1/actionStore';
import AuthStore from './v1/authStore';
import BusinessAggregationStore from './v1/businessAggregationStore';
import BusinessStore from './v1/businessStore';
import HelpPanelStore from './v1/HelpPanelStore';
import PageStore from './v1/pageStore';
import TagStore from './v1/tagStore';
import SplitStore from './splitStore';
import TimePeriodStore from './v1/timePeriodStore';
import ReportDownloadStore from './v1/reportDownloadStore';
import PracticeStore from './practiceStore';
import UserStore from './userStore';
import AuthenticationStore from './authenticationStore';
import SubscriptionStore from './subscriptionStore';
import AdvisorsStore from './advisorsStore';
import AdvisorClientStore from './advisorClientStore';
import DashboardStore from './dashboardStore';
import ResyncStore from './resyncStore';
import AssistantStore from './assistantStore';
import PracticeTagStore from './practiceTagStore';
import BusinessesStore from './businessesStore';
import ChecklistStore from './checklistStore';
import ConnectionsStore from './connectionsStore';
import RulesStore from './rulesStore';
import AlertStore from './alertStore';
import InvitationStore from './invitationStore';
import ClientSummaryStore from './clientSummaryStore';
import LoadingStore from './loadingStore';
import CommentsStore from './commentsStore';
import PeriodCloseDashboardStore from './periodCloseDashboardStore';
import ClientManagementStore from './clientManagementStore';
import LocaleStore from './localeStore';
import ReportTemplateStore from './reportTemplateStore';
import { CookieKeys } from '../ts/enums/Constants';

configure({ enforceActions: 'never' });

export class RootStore {
  alertStore: AlertStore;

  businessStore: BusinessStore;

  authStore: AuthStore;

  GraphStore: BusinessAggregationStore;

  actionStore: ActionStore;

  pageStore: PageStore;

  helpPanelStore: HelpPanelStore;

  tagStore: TagStore;

  splitStore: SplitStore;

  timePeriodStore: TimePeriodStore;

  reportDownloadStore: ReportDownloadStore;

  practiceStore: PracticeStore;

  userStore: UserStore;

  authenticationStore: AuthenticationStore;

  subscriptionStore: SubscriptionStore;

  advisorsStore: AdvisorsStore;

  advisorClientStore: AdvisorClientStore;

  dashboardStore: DashboardStore;

  resyncStore: ResyncStore;

  assistantStore: AssistantStore;

  practiceTagStore: PracticeTagStore;

  businessesStore: BusinessesStore;

  checklistStore: ChecklistStore;

  connectionsStore: ConnectionsStore;

  rulesStore: RulesStore;

  invitationStore: InvitationStore;

  clientSummaryStore: ClientSummaryStore;

  loadingStore: LoadingStore;

  commentsStore: CommentsStore;

  periodCloseDashboardStore: PeriodCloseDashboardStore;

  clientManagementStore: ClientManagementStore;

  localeStore: LocaleStore;

  reportTemplateStore: ReportTemplateStore;

  constructor() {
    // feaatues store has to be intialized before other stores
    this.splitStore = new SplitStore(this);
    // V1 Stores
    this.businessStore = new BusinessStore(this);
    this.authStore = new AuthStore(this);
    this.GraphStore = new BusinessAggregationStore(this);
    this.actionStore = new ActionStore(this);
    this.pageStore = new PageStore(this);
    this.helpPanelStore = new HelpPanelStore(this);
    this.tagStore = new TagStore(this);
    this.timePeriodStore = new TimePeriodStore(this);
    this.reportDownloadStore = new ReportDownloadStore(this);

    // V2 Stores
    this.alertStore = new AlertStore(this);
    this.practiceStore = new PracticeStore(this);
    this.practiceTagStore = new PracticeTagStore(this);
    this.userStore = new UserStore(this);
    this.authenticationStore = new AuthenticationStore(this);
    this.subscriptionStore = new SubscriptionStore(this);
    this.advisorsStore = new AdvisorsStore(this);
    this.advisorClientStore = new AdvisorClientStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.assistantStore = new AssistantStore(this);
    this.authStore.setLoading(false);
    this.resyncStore = new ResyncStore(this);
    this.businessesStore = new BusinessesStore(this);
    this.checklistStore = new ChecklistStore(this);
    this.connectionsStore = new ConnectionsStore(this);
    this.rulesStore = new RulesStore(this);
    this.invitationStore = new InvitationStore(this);
    this.clientSummaryStore = new ClientSummaryStore(this);
    this.loadingStore = new LoadingStore(this);
    this.commentsStore = new CommentsStore(this);
    this.periodCloseDashboardStore = new PeriodCloseDashboardStore(this);
    this.clientManagementStore = new ClientManagementStore(this);
    this.localeStore = new LocaleStore(this);
    this.reportTemplateStore = new ReportTemplateStore(this);

    // Trigger reaction to obtain user details. This is temporary
    // until we refactor the authentication flow.
    reaction(
      () => typeof this.authenticationStore.accessToken !== 'undefined'
        && typeof this.userStore.id !== 'undefined',
      (id) => {
        if (id && !this.userStore.hasUserDetails) {
          this.userStore.retrieveUserDetail();
        }
      }
    );

    // Trigger reaction to obtain splits
    reaction(
      () => typeof this.authenticationStore.accessToken !== 'undefined'
        && typeof this.practiceStore.id !== 'undefined'
        && !this.splitStore?.client
        && !this.splitStore?.features,
      () => {
        this.splitStore.initSplit();
      }
    );
  }

  @observable loading = false;

  @action.bound setLoading(loading: boolean) {
    this.loading = loading;
  }

  // Disable is a workaround for known eslint issue where it complains NodeJS is undefined
  // eslint-disable-next-line no-undef
  timeoutId: NodeJS.Timeout = null;

  createTimeout() {
    const timeout = 30 * 60 * 1000; // 30 minutes in millisecs
    this.removeTimeout();
    this.timeoutId = setTimeout(() => {
      cookie.set(CookieKeys.sessionTimedOut, true);
      this.authStore.handleSignOut();
    }, timeout);
  }

  removeTimeout() {
    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  async stopStorePersistance() {
    await this.GraphStore.clearStoredData();
    await this.userStore.clearStoredData();
    await this.connectionsStore.clearStoredData();
    await this.authenticationStore.clearStoredData();
    // Clear invitation store only if there is no invite token
    if (!this.invitationStore.inviteToken) {
      await this.invitationStore.clearStoredData();
    }
  }
}

export const rootStore = new RootStore();

/* Store helpers */
const StoreContext = React.createContext(rootStore);

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
);

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
export const withStore = (Component) => (props) => <Component {...props} store={useStore()} />;
