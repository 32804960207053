import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import type { RootStore } from './Store';
import { DELETE, GET, POST, PUT } from '../lib/requests';
import { trackMixpanelEvent } from '../lib/mixpanel';
import handleError from '../lib/errorHandler';
import { Rule } from '../models/interfaces/Rules';

export default class RulesStore {
  rootStore: RootStore;

  rules = new Map();

  pendingRules = new Map();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }

  rule(
    businessId: string,
    checklistId: string,
    sectionId: string,
    ruleId: string
  ) {
    return this.rules
      .get(businessId)
      ?.get(checklistId)
      ?.get(sectionId)
      ?.find((r) => r.id === ruleId);
  }

  storeRule(businessId, tableGroupId: string, rule: Rule) {
    if (!this.rules.has(businessId)) {
      this.rules.set(businessId, new Map());
    }
    if (
      !this.rules
        .get(businessId)
        .has(this.rootStore.checklistStore.selectedChecklistType)
    ) {
      this.rules
        .get(businessId)
        .set(this.rootStore.checklistStore.selectedChecklistType, new Map());
    }
    const existingRules = this.rules
      .get(businessId)
      .get(this.rootStore.checklistStore.selectedChecklistType);
    if (!existingRules.has(tableGroupId)) {
      existingRules.set(tableGroupId, [rule]);
    }
    existingRules.get(tableGroupId).push(rule);
    this.rules
      .get(businessId)
      .set(this.rootStore.checklistStore.selectedChecklistType, existingRules);
  }

  get selectedBusinessRules() {
    return this.rules
      ?.get(this.rootStore.businessesStore.selectedBusinessId)
      ?.get(this.rootStore.checklistStore.selectedChecklistType);
  }

  sortedBusinessRules(businessId: string, checklistId: string, categoryId: string) {
    const arrayToSort = this.rules
      ?.get(businessId)
      ?.get(checklistId)
      ?.get(categoryId) || [];
    return arrayToSort.slice().sort((a, b) => a?.title.localeCompare(b?.title));
  }

  businessHasRules(businessId: string, checklistId: string, categoryId: string) {
    return this.rules?.get(businessId)?.get(checklistId)?.get(categoryId)?.length > 0;
  }

  async retrieveRules(businessId: string, checklistId: string) {
    const sentryTransaction = Sentry.startTransaction({
      name: 'Get Checklist Rules',
    });
    try {
      let url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${businessId}/tablegroups/${checklistId}/rules`;
      if (businessId === this.rootStore.practiceStore.id) {
        url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/practices/${businessId}/checklists/${checklistId}/rules`;
      }
      const res = await GET({
        url,
        rootStore: this.rootStore,
        sentryTransaction,
      });
      sentryTransaction.finish();
      if (!res) throw new Error('No rules found');
      if (!this.rules.has(businessId)) {
        this.rules.set(businessId, new Map());
      }

      this.rules.get(businessId).set(checklistId, new Map());

      res.forEach((rule) => {
        if (!this.rules.get(businessId).get(checklistId).has(rule.section)) {
          this.rules.get(businessId).get(checklistId).set(rule.section, []);
        }
        const existingIndex = this.rules
          .get(businessId)
          .get(checklistId)
          .get(rule.section)
          .findIndex((r) => r.id === rule.id);
        if (existingIndex > -1) {
          this.rules
            .get(businessId)
            .get(checklistId)
            .get(rule.section)
            .splice(existingIndex, 1, rule);
        } else {
          this.rules
            .get(businessId)
            .get(checklistId)
            .get(rule.section)
            .push(rule);
        }
      });
      return res;
    } catch (e) {
      sentryTransaction.finish();
      Sentry.captureException('Error retrieving rules:', e);
      throw e;
    }
  }

  async createRule(businessId: string, checklistId: string, rule: Rule) {
    try {
      let url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${businessId}/tablegroups/${checklistId}/rules`;
      if (businessId === this.rootStore.practiceStore.id) {
        url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/practices/${businessId}/checklists/${checklistId}/rules`;
      }
      const res = await POST({ url, data: rule, rootStore: this.rootStore });
      if (!res) throw new Error('No rule created');
      trackMixpanelEvent({
        description: 'Create Rule',
        properties: {
          checklistId,
          rule: res,
          target: businessId,
          ruleType:
            businessId === this.rootStore.practiceStore.id
              ? 'Practice'
              : 'Business',
        },
        rootStore: this.rootStore,
      });
      return res;
    } catch (error) {
      Sentry.captureException({
        message: 'Create Rule Error',
        checklistId,
        rule,
        error,
      });
      throw error;
    }
  }

  async updateRule(
    businessId: string,
    checklistId: string,
    sectionId: string,
    rule: Rule
  ) {
    try {
      let url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${businessId}/tablegroups/${checklistId}/rules/${rule.id}`;
      if (businessId === this.rootStore.practiceStore.id) {
        url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/practices/${businessId}/checklists/${checklistId}/rules/${rule.id}`;
      }
      const res = await PUT({ url, data: rule, rootStore: this.rootStore });
      if (!res) throw new Error('No rule updated');
      trackMixpanelEvent({
        description: 'Update Rule',
        properties: {
          sectionId,
          existingRule: this.rules
            .get(businessId)
            .get(checklistId)
            .get(sectionId)
            .find((r) => r.id === rule.id),
          rule: res,
          target: businessId,
          ruleType:
            businessId === this.rootStore.practiceStore.id
              ? 'Practice'
              : 'Business',
        },
        rootStore: this.rootStore,
      });
      return res;
    } catch (error) {
      Sentry.captureException({
        message: 'Update Rule Error',
        sectionId,
        rule,
        error,
      });
      throw error;
    }
  }

  async deleteRule(businessId: string, checklistId: string, rule: Rule) {
    try {
      let ruleTypeQueryParam = '';
      if (rule.type) {
        ruleTypeQueryParam = `?ruleType=${rule.type}`;
      }

      let url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${businessId}/tablegroups/${checklistId}/rules/${rule.id}${ruleTypeQueryParam}`;
      if (businessId === this.rootStore.practiceStore.id) {
        url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/practices/${businessId}/checklists/${checklistId}/rules/${rule.id}`;
      }
      const res = await DELETE({ url, rootStore: this.rootStore });
      if (!res) throw new Error('No rule deleted');
      trackMixpanelEvent({
        description: 'Delete Rule',
        properties: {
          checklistId,
          rule,
          target: businessId,
          ruleType:
            businessId === this.rootStore.practiceStore.id
              ? 'Practice'
              : 'Business',
        },
        rootStore: this.rootStore,
      });
      return res;
    } catch (error) {
      Sentry.captureException({
        message: 'Delete Rule Error',
        checklistId,
        rule,
        error,
      });
      throw error;
    }
  }

  async addPendingRule(
    businessId: string,
    pendingRuleId: string,
    checklistId: string,
    categoryId: string
  ) {
    if (!this.pendingRules.has(businessId)) {
      this.pendingRules.set(businessId, new Map());
    }

    if (!this.pendingRules.get(businessId).has(checklistId)) {
      this.pendingRules.get(businessId).set(checklistId, new Map());
    }

    if (!this.pendingRules.get(businessId).get(checklistId).has(categoryId)) {
      this.pendingRules
        .get(businessId)
        .get(checklistId)
        .set(categoryId, new Map());
    }

    this.pendingRules
      .get(businessId)
      .get(checklistId)
      .get(categoryId)
      .set(pendingRuleId, true);

    return this.pendingRules;
  }

  async removePendingRule(
    businessId: string,
    pendingRuleId: string,
    checklistId: string,
    categoryId: string
  ) {
    if (
      this.pendingRules.get(businessId).has(checklistId)
      && this.pendingRules.get(businessId).get(checklistId).has(categoryId)
      && this.pendingRules
        .get(businessId)
        .get(checklistId)
        .get(categoryId)
        .has(pendingRuleId)
    ) {
      this.pendingRules
        .get(businessId)
        .get(checklistId)
        .get(categoryId)
        .delete(pendingRuleId);
    }
    return this.pendingRules;
  }

  get isRuleLoading() {
    return this.pendingRules.size > 0;
  }

  async handleUpdateRule(
    ruleId: string,
    checklistId: string,
    sectionId: string,
    selectedPeriod: string,
    businessId: string
  ) {
    try {
      const splitTableGroup = checklistId.split('-');
      const isArchive = splitTableGroup[0] === 'ARCHIVED';
      const checklistType = isArchive ? splitTableGroup[2] : splitTableGroup[1];

      await this.addPendingRule(businessId, ruleId, checklistId, sectionId);

      this.rootStore.checklistStore.setActivePeriod(selectedPeriod);
      if (businessId === this.rootStore.practiceStore.id) {
        await this.retrieveRules(businessId, checklistId);
      } else {
        await this.rootStore.checklistStore.fireUpdateChecklist(ComplianceChecklistEnums.Types[checklistType]);
        await this.rootStore.checklistStore.retrieveChecklist(ComplianceChecklistEnums.Types[checklistType]);
      }
      await this.removePendingRule(
        businessId,
        ruleId,
        checklistId,
        sectionId
      );
    } catch (e) {
      handleError({
        error: e,
        status: 'error_saving',
        transaction: 'Save/Edit data rule',
        operation: 'handleUpdates',
      });
    }
  }
}
