import { firestore } from 'firebase';
import { makeAutoObservable, reaction } from 'mobx';
import * as Sentry from '@sentry/browser';
import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';
import { PeriodTypes } from '@aider/aider-period-library';
import type { RootStore } from './Store';
import handleError from '../lib/errorHandler';

type Message = {
  timestamp?: Date;
  message: string;
  provider?: string;
} & (UserMessage | AssistantMessage);

type UserMessage = {
  from: 'user';
  includedDocuments: string[];
}

type AssistantMessage = {
  from: 'assistant';
}

type UserPrompt = {
  id: string;
  page: string;
  label: string;
  text: string;
}

interface IChat {
  id: string;
  businessId: string;
  userId: string;
  timestamp?: Date;
  messages: Message[];
}

export default class AssistantStore {
  rootStore: RootStore;

  messages: Message[] = [];

  messageHistory: Message[] = [];

  chatId: string = null;

  timestamp: Date;

  userPrompts: UserPrompt[] = [];

  page: string = 'insights';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );

    // Trigger reaction to obtain user prompts.
    reaction(
      () => typeof this.rootStore.authenticationStore.accessToken !== 'undefined'
        && typeof this.rootStore.userStore.id !== 'undefined',
      (id) => {
        if (id) {
          try {
            firestore()
              .collection('/advisoryAssistantUserPrompts')
              .doc(this.rootStore.userStore.id)
              .get()
              .then((doc) => {
                const { userPrompts } = doc?.data() || { userPrompts: [] };
                this.userPrompts = userPrompts.map((userPrompt) => {
                  const prompt = { ...userPrompt };
                  prompt.id = prompt.id ?? uuid();
                  prompt.page = prompt.page ?? 'insights';
                  return prompt;
                });
              });
          } catch (error) {
            handleError({
              error,
              status: 'error_retrieving',
              transaction: 'assistantStore - reaction - userPrompts',
              operation: 'get',
            });
          }
        }
      }
    );
  }

  get paginatedPrompts() {
    const dashboardPrompts = this.userPrompts
      .filter((userPrompt) => userPrompt.page === 'dashboard')
      .sort((a, b) => a.label.localeCompare(b.label));

    const insightsPrompts = this.userPrompts
      .filter(
        (userPrompt) => userPrompt.page === 'insights' || !userPrompt?.page
      )
      .sort((a, b) => a.label.localeCompare(b.label));

    const assistantPrompts = this.userPrompts
      .filter(
        (userPrompt) => userPrompt.page === 'assistant'
      )
      .sort((a, b) => a.label.localeCompare(b.label));

    return { dashboardPrompts, insightsPrompts, assistantPrompts };
  }

  get chat(): IChat {
    return {
      id: this.chatId,
      businessId:
        (this.page === 'insights'
          ? this.rootStore.businessStore?.selectedBusinessId
          : this.page === 'dashboard'
            ? this.rootStore.practiceStore.id
            : this.page),
      userId: this.rootStore.userStore?.id,
      timestamp: this.timestamp,
      messages: this.messages,
    };
  }

  async addUserPrompt(prompt: UserPrompt) {
    // TODO: if prompt has an id, then it must already exist in the array, so we should update it.
    const promptIndex = this.userPrompts.findIndex(
      (elem) => elem.id === prompt.id
    );
    if (promptIndex !== -1) {
      this.userPrompts[promptIndex] = prompt;
    } else {
      this.userPrompts.push(prompt);
    }

    return this.saveUserPrompts();
  }

  async deleteUserPrompt(prompt: UserPrompt) {
    const userPrompts = this.userPrompts.filter((userPrompt) => {
      if (prompt?.id) {
        return userPrompt.id !== prompt.id;
      }
      return userPrompt.label !== prompt.label;
    });
    this.userPrompts = userPrompts;
    return this.saveUserPrompts();
  }

  async saveUserPrompts() {
    try {
      await firestore()
        .collection('/advisoryAssistantUserPrompts')
        .doc(this.rootStore.userStore.id)
        .set({ userPrompts: this.userPrompts });

      return true;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  }

  appendChat(props: Message) {
    this.messages.push({
      timestamp: new Date(),
      ...props,
    });
    this.persistChat();
  }

  clearChat() {
    this.messages = [];
    this.chatId = null;
  }

  persistChat = async () => {
    if (this.chatId) {
      await this.persistExistingChat();
    } else {
      await this.persistNewChat();
    }
  };

  persistNewChat = async () => {
    try {
      const docRef = await firestore()
        .collection('/advisoryAssistantChat')
        .add(this.chat);

      this.chatId = docRef.id;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  persistExistingChat = async () => {
    try {
      await firestore()
        .collection('/advisoryAssistantChat')
        .doc(this.chatId)
        .update(this.chat);
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  getDateForReport = (periodName, lastDay = false, lastYear = 0) => {
    let periodDate = new Date(periodName);
    if (lastDay) {
      periodDate = new Date(
        periodDate.getFullYear(),
        periodDate.getMonth() + 1,
        0
      );
    }
    const year = periodDate.getFullYear() - lastYear;
    const month = String(periodDate.getMonth() + 1).padStart(2, '0');
    const day = String(periodDate.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  getBalanceSheet = async (previousPeriod = false) => new Promise((resolve) => {
    try {
      let storedDate: string =
          this.rootStore.timePeriodStore.profitabilityPeriodSelected;
      if (storedDate.includes('-')) storedDate = storedDate.slice(storedDate.indexOf('-') + 1).trim();
      firestore()
        .collection('/balanceSheet')
        .doc(
          `${
            this.rootStore.businessStore.selectedBusinessId
          }-xero-accrual-monthly-${this.getDateForReport(
            storedDate,
            true,
            previousPeriod ? 1 : 0
          )}`
        )
        .get()
        .then((doc) => {
          resolve(doc?.data());
        });
    } catch (e) {
      Sentry.captureException(e);
      resolve(null);
    }
  });

  getProfitAndLoss = async (previousPeriod = false) => new Promise((resolve) => {
    try {
      let storedDate: string =
          this.rootStore.timePeriodStore.profitabilityPeriodSelected;
      if (
        this.rootStore.timePeriodStore.periodGranularity
          !== PeriodTypes.MONTHLY
      ) {
        if (storedDate.includes('-')) storedDate = storedDate.slice(storedDate.indexOf('-') + 1).trim();

        const requiredMonths =
            this.rootStore.timePeriodStore.periodGranularity
            === PeriodTypes.QUARTERLY
              ? 3
              : 12;

        const firestorePromises = [];

        for (let i = 0; i < requiredMonths; i++) {
          const currentDate = DateTime.fromJSDate(new Date(storedDate))
            .minus({ month: i })
            .endOf('month')
            .toUTC()
            .toFormat('LLL yyyy');

          firestorePromises.push(
            firestore()
              .collection('/profitAndLoss')
              .doc(
                `${
                  this.rootStore.businessStore.selectedBusinessId
                }-xero-accrual-monthly-${this.getDateForReport(
                  currentDate,
                  false,
                  previousPeriod ? 1 : 0
                )}`
              )
              .get()
          );
        }

        Promise.all(firestorePromises).then((resolvedPromises) => {
          resolve(
            resolvedPromises.reverse().map((resolved) => resolved.data())
          );
        });
      } else {
        // Single month
        firestore()
          .collection('/profitAndLoss')
          .doc(
            `${
              this.rootStore.businessStore.selectedBusinessId
            }-xero-accrual-monthly-${this.getDateForReport(
              storedDate,
              false,
              previousPeriod ? 1 : 0
            )}`
          )
          .get()
          .then((doc) => {
            resolve([doc?.data()]);
          });
      }
    } catch (e) {
      Sentry.captureException(e);
      resolve(null);
    }
  });

  getInsights = () => {
    const nonProfitabilityInsights = [
      'cashFlow',
      'cashFlowActual',
      'invoiceStatus',
      'gst',
      'incomeTax',
      'reconciliation',
    ];

    return this.rootStore.businessStore.insightData
      .filter(
        ({ insightKey }) => !nonProfitabilityInsights.includes(insightKey)
      )
      .map(({ headline, periods, quarters }) => {
        let message =
          'Data not available for this profitabilty insight period.';
        let period;
        switch (this.rootStore.timePeriodStore?.periodGranularity) {
          case PeriodTypes.QUARTERLY:
            period = quarters?.find(
              (p) => p?.periodData?.name
                === this.rootStore.timePeriodStore?.profitabilityPeriodSelected
            );
            break;
          default:
            period = periods?.find(
              (p) => p?.periodData?.name
                === this.rootStore.timePeriodStore?.profitabilityPeriodSelected
            );
            break;
        }

        if (period) {
          message = period?.chartSummary?.[0]?.body;
        }
        return {
          title: headline?.title,
          message,
        };
      });
  };

  getAllBSAccounts = async (currentBalanceSheet: any, oldBalanceSheet: any) => {
    // Bank accounts
    const bankAccounts: string[] = [];
    bankAccounts.push(
      currentBalanceSheet.Bank?.map((account) => account.accountId) || []
    );
    bankAccounts.push(
      oldBalanceSheet.Bank?.map((account) => account.accountId) || []
    );
    // Current Asset accounts
    const currentAssetAccounts: string[] = [];
    currentAssetAccounts.push(
      currentBalanceSheet.CurrentAssets?.map((account) => account.accountId)
        || []
    );
    currentAssetAccounts.push(
      oldBalanceSheet.CurrentAssets?.map((account) => account.accountId) || []
    );
    // Fixed Asset accounts
    const fixedAssetAccounts: string[] = [];
    fixedAssetAccounts.push(
      currentBalanceSheet.FixedAssets?.map((account) => account.accountId) || []
    );
    fixedAssetAccounts.push(
      oldBalanceSheet.FixedAssets?.map((account) => account.accountId) || []
    );
    // NonCurrent Asset accounts
    const nonCurrentAssetAccounts: string[] = [];
    nonCurrentAssetAccounts.push(
      currentBalanceSheet.NonCurrentAssets?.map(
        (account) => account.accountId
      ) || []
    );
    nonCurrentAssetAccounts.push(
      oldBalanceSheet.NonCurrentAssets?.map((account) => account.accountId)
        || []
    );
    // Current Liabilities accounts
    const currentLiabilitiesAccounts: string[] = [];
    currentLiabilitiesAccounts.push(
      currentBalanceSheet.CurrentLiabilities?.map(
        (account) => account.accountId
      ) || []
    );
    currentLiabilitiesAccounts.push(
      oldBalanceSheet.CurrentLiabilities?.map((account) => account.accountId)
        || []
    );
    // Non Current Liabilities accounts
    const nonCurrentLiabilitiesAccounts: string[] = [];
    nonCurrentLiabilitiesAccounts.push(
      currentBalanceSheet.NonCurrentLiabilities?.map(
        (account) => account.accountId
      ) || []
    );
    nonCurrentLiabilitiesAccounts.push(
      oldBalanceSheet.NonCurrentLiabilities?.map(
        (account) => account.accountId
      ) || []
    );
    // Equity accounts
    const equityAccounts: string[] = [];
    equityAccounts.push(
      currentBalanceSheet.Equity?.map((account) => account.accountId) || []
    );
    equityAccounts.push(
      oldBalanceSheet.Equity?.map((account) => account.accountId) || []
    );

    return {
      bankAccounts: [...new Set(bankAccounts.flat())],
      currentAssetAccounts: [...new Set(currentAssetAccounts.flat())],
      fixedAssetAccounts: [...new Set(fixedAssetAccounts.flat())],
      nonCurrentAssetAccounts: [...new Set(nonCurrentAssetAccounts.flat())],
      currentLiabilitiesAccounts: [
        ...new Set(currentLiabilitiesAccounts.flat()),
      ],
      nonCurrentLiabilitiesAccounts: [
        ...new Set(nonCurrentLiabilitiesAccounts.flat()),
      ],
      equityAccounts: [...new Set(equityAccounts.flat())],
    };
  };

  getAllPnLAccounts = async (
    currentProfitAndLoss: any[],
    oldProfitAndLoss: any[]
  ) => {
    // Income accounts
    const incomeAccounts: string[] = [];
    const costOfSalesAccounts: string[] = [];
    const opexAccounts: string[] = [];

    currentProfitAndLoss.forEach((currentReport) => {
      incomeAccounts.push(
        currentReport?.Income?.map((account) => account.accountId) || []
      );
      costOfSalesAccounts.push(
        currentReport?.LessCostOfSales?.map((account) => account.accountId)
          || []
      );
      opexAccounts.push(
        currentReport?.LessOperatingExpenses?.map(
          (account) => account.accountId
        ) || []
      );
    });

    oldProfitAndLoss.forEach((oldReport) => {
      incomeAccounts.push(
        oldReport?.Income?.map((account) => account.accountId) || []
      );
      costOfSalesAccounts.push(
        oldReport?.LessCostOfSales?.map((account) => account.accountId) || []
      );
      opexAccounts.push(
        oldReport?.LessOperatingExpenses?.map((account) => account.accountId)
          || []
      );
    });

    return {
      incomeAccounts: [...new Set(incomeAccounts.flat())],
      costOfSalesAccounts: [...new Set(costOfSalesAccounts.flat())],
      opexAccounts: [...new Set(opexAccounts.flat())],
    };
  };

  getBalanceSheetContext = async () => {
    let systemMessage: string = '';
    const balanceSheetCurrent: any = await this.getBalanceSheet();
    const balanceSheetOld: any = await this.getBalanceSheet(true);
    // If there's no previous period, just keep doing what you're doing
    if (balanceSheetCurrent && !balanceSheetOld) {
      systemMessage += `\n Balance Sheet ${this.rootStore.timePeriodStore.profitabilityPeriodSelected}`;

      systemMessage += '\n Assets';

      systemMessage += '\n Bank';
      systemMessage += balanceSheetCurrent.Bank?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Bank: ${
        balanceSheetCurrent.Summary.TotalBank || '0'
      }`;

      systemMessage += '\n Current Assets';
      systemMessage += balanceSheetCurrent.CurrentAssets?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Current Assets: ${
        balanceSheetCurrent.Summary.TotalCurrentAssets || '0'
      }`;

      systemMessage += '\n Fixed Assets';
      systemMessage += balanceSheetCurrent.FixedAssets?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Fixed Assets: ${
        balanceSheetCurrent.Summary.TotalFixedAssets || '0'
      }`;

      systemMessage += `\n Total Assets: ${
        balanceSheetCurrent.Summary.TotalAssets || '0'
      }`;

      systemMessage += '\n Liabilities';

      systemMessage += '\n Current Liabilities';
      systemMessage += balanceSheetCurrent.CurrentLiabilities?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Current Liabilities: ${
        balanceSheetCurrent.Summary.TotalCurrentLiabilities || '0'
      }`;

      systemMessage += '\n Non Current Liabilities';
      systemMessage += balanceSheetCurrent.NonCurrentLiabilities?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Non Current Liabilities: ${
        balanceSheetCurrent.Summary.TotalNonCurrentLiabilities || '0'
      }`;

      systemMessage += `\n Total Liabilities: ${
        balanceSheetCurrent.Summary.TotalLiabilities || '0'
      }`;

      systemMessage += `\n Net Assets: ${
        balanceSheetCurrent.Summary.NetAssets || '0'
      }`;

      systemMessage += '\n Equity';
      systemMessage += balanceSheetCurrent.Equity?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Equity: ${
        balanceSheetCurrent.Summary.TotalEquity || '0'
      }`;
    }

    if (balanceSheetCurrent && balanceSheetOld) {
      const newPeriodHeader = DateTime.fromJSDate(
        balanceSheetCurrent.reportDate.toDate()
      )
        ?.toUTC()
        .toFormat('LLL yyyy');
      const oldPeriodHeader = DateTime.fromJSDate(
        balanceSheetOld.reportDate.toDate()
      )
        ?.toUTC()
        .toFormat('LLL yyyy');
      const allAccounts = await this.getAllBSAccounts(
        balanceSheetCurrent,
        balanceSheetOld
      );
      // Account specific CSV
      systemMessage += `\n Balance Sheet data:\n Account Name,Category,Balance at end of ${oldPeriodHeader},Balance at end of ${newPeriodHeader} \n`;

      systemMessage += '\n Assets';

      systemMessage += '\n Bank';
      allAccounts.bankAccounts.forEach((bankAccount) => {
        const oldData =
          balanceSheetOld.Bank?.find(
            (account) => account.accountId === bankAccount
          ) || null;
        const currentData =
          balanceSheetCurrent.Bank?.find(
            (account) => account.accountId === bankAccount
          ) || null;
        systemMessage += `\n ${
          oldData?.accountName || currentData?.accountName
        },bank,${oldData?.value ?? ''},${currentData?.value ?? ''}`;
      });

      systemMessage += '\n Current Assets';
      allAccounts.currentAssetAccounts.forEach((assetAccount) => {
        const oldData =
          balanceSheetOld.CurrentAssets?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        const currentData =
          balanceSheetCurrent.CurrentAssets?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        systemMessage += `\n ${
          oldData?.accountName || currentData?.accountName
        },current asset,${oldData?.value ?? ''},${currentData?.value ?? ''}`;
      });

      systemMessage += '\n Fixed Assets';
      allAccounts.fixedAssetAccounts.forEach((assetAccount) => {
        const oldData =
          balanceSheetOld.FixedAssets?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        const currentData =
          balanceSheetCurrent.FixedAssets?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        systemMessage += `\n ${
          oldData?.accountName || currentData?.accountName
        },fixed asset,${oldData?.value ?? ''},${currentData?.value ?? ''}`;
      });

      systemMessage += '\n Liabilities';

      systemMessage += '\n Current Liabilities';
      allAccounts.currentLiabilitiesAccounts.forEach((assetAccount) => {
        const oldData =
          balanceSheetOld.CurrentLiabilities?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        const currentData =
          balanceSheetCurrent.CurrentLiabilities?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        systemMessage += `\n ${
          oldData?.accountName || currentData?.accountName
        },current liability,${oldData?.value ?? ''},${
          currentData?.value ?? ''
        }`;
      });

      systemMessage += '\n Non Current Liabilities';
      allAccounts.nonCurrentLiabilitiesAccounts.forEach((assetAccount) => {
        const oldData =
          balanceSheetOld.NonCurrentLiabilities?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        const currentData =
          balanceSheetCurrent.NonCurrentLiabilities?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        systemMessage += `\n ${
          oldData?.accountName || currentData?.accountName
        },non current liability,${oldData?.value ?? ''},${
          currentData?.value ?? ''
        }`;
      });

      systemMessage += '\n Equity';
      allAccounts.equityAccounts.forEach((assetAccount) => {
        const oldData =
          balanceSheetOld.Equity?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        const currentData =
          balanceSheetCurrent.Equity?.find(
            (account) => account.accountId === assetAccount
          ) || null;
        systemMessage += `\n ${
          oldData?.accountName || currentData?.accountName
        },equity,${oldData?.value ?? ''},${currentData?.value ?? ''}`;
      });

      // Summary CSV
      systemMessage += '\n Totals';
      const allSummaryTypes = [
        ...new Set(
          Object.keys(balanceSheetCurrent.Summary)
            .concat(Object.keys(balanceSheetOld.Summary))
            .flat()
        ),
      ];
      systemMessage += `\n category,total at the end of ${oldPeriodHeader},total at the end of ${newPeriodHeader}`;
      allSummaryTypes.forEach((summaryType) => {
        const oldData = balanceSheetOld.Summary
          ? balanceSheetOld.Summary[summaryType] ?? ''
          : '';
        const newData = balanceSheetCurrent.Summary
          ? balanceSheetCurrent.Summary[summaryType] ?? ''
          : '';
        systemMessage += `\n ${summaryType
          .replace(/([A-Z])/g, ' $1')
          .trim()},${oldData},${newData}`;
      });
    }
    return systemMessage;
  };

  getProfitAndLossContext = async () => {
    let systemMessage: string = '';
    const profitAndLossCurrent: any = await this.getProfitAndLoss();
    const profitAndLossOld: any = await this.getProfitAndLoss(true);
    // If there's no previous period, just keep doing what you're doing
    if (profitAndLossCurrent && !profitAndLossOld) {
      systemMessage += `\n Profit and Loss ${this.rootStore.timePeriodStore.profitabilityPeriodSelected}`;

      systemMessage += '\n Income';
      systemMessage += profitAndLossCurrent.Income?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Income: ${
        profitAndLossCurrent.Summary.TotalIncome || '0'
      }`;

      systemMessage += '\n Cost of Sales';
      systemMessage += profitAndLossCurrent.LessCostOfSales?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Cost of Sales: ${
        profitAndLossCurrent.Summary.TotalCostOfSales || '0'
      }`;

      systemMessage += `\n Gross Profit: ${
        profitAndLossCurrent.Summary.GrossProfit || '0'
      }`;

      systemMessage += '\n Operating Expenses';
      systemMessage += profitAndLossCurrent.LessOperatingExpenses?.map(
        (account) => `\n ${account.accountName}: ${account.value}`
      );
      systemMessage += `\n Total Operating Expenses: ${
        profitAndLossCurrent.Summary.TotalOperatingExpenses || '0'
      }`;

      systemMessage += `\n Net Profit: ${
        profitAndLossCurrent.Summary.NetProfit || '0'
      }`;
    } else if (profitAndLossCurrent && profitAndLossOld) {
      let oldPeriodHeader = 'Previous Period';
      let newPeriodHeader = 'Current Period';
      if (profitAndLossOld?.length && profitAndLossOld?.length) {
        oldPeriodHeader = `${DateTime.fromJSDate(
          profitAndLossOld?.[profitAndLossOld?.length - 1]?.endDate.toDate()
        )
          ?.toUTC()
          .toFormat('LLL yyyy')}`;
        newPeriodHeader = `${DateTime.fromJSDate(
          profitAndLossCurrent?.[
            profitAndLossCurrent?.length - 1
          ]?.endDate.toDate()
        )
          ?.toUTC()
          .toFormat('LLL yyyy')}`;
        if (
          this.rootStore.timePeriodStore.periodGranularity
          !== PeriodTypes.MONTHLY
        ) {
          oldPeriodHeader = `${DateTime.fromJSDate(
            profitAndLossOld?.[0]?.startDate.toDate()
          )
            ?.toUTC()
            .toFormat('LLL yyyy')} - ${oldPeriodHeader}`;

          newPeriodHeader = `${DateTime.fromJSDate(
            profitAndLossCurrent?.[0]?.startDate.toDate()
          )
            ?.toUTC()
            .toFormat('LLL yyyy')} - ${newPeriodHeader}`;
        }
      }
      const allAccounts = await this.getAllPnLAccounts(
        profitAndLossCurrent,
        profitAndLossOld
      );
      systemMessage += `\n Profit and Loss data:\n Account Name, Category, Balance change during ${oldPeriodHeader}, Balance change during ${newPeriodHeader} \n`;

      // Income accounts
      systemMessage += '\n Income';
      allAccounts.incomeAccounts.forEach((incomeAccount) => {
        let currentValue = 0;
        let accountName = null;
        profitAndLossCurrent.filter(Boolean).forEach((report) => {
          const relevantAccount = report.Income?.find(
            (item) => item.accountId === incomeAccount
          );
          currentValue += relevantAccount?.value ?? 0;
          accountName = relevantAccount?.accountName || null;
        });
        let previousValue = 0;
        profitAndLossOld.filter(Boolean).forEach((report) => {
          const relevantAccount = report.Income?.find(
            (item) => item.accountId === incomeAccount
          );
          previousValue += relevantAccount?.value ?? 0;
          if (!accountName) accountName = relevantAccount?.accountName || null;
        });
        systemMessage += `\n ${accountName}, income, ${previousValue}, ${currentValue}`;
      });

      // Cost of Sales accounts
      systemMessage += '\n Cost of Sales';
      allAccounts.costOfSalesAccounts.forEach((costOfSalesAccount) => {
        let currentValue = 0;
        let accountName = null;
        profitAndLossCurrent.filter(Boolean).forEach((report) => {
          const relevantAccount = report.LessCostOfSales?.find(
            (item) => item.accountId === costOfSalesAccount
          );
          currentValue += relevantAccount?.value ?? 0;
          accountName = relevantAccount?.accountName || null;
        });
        let previousValue = 0;
        profitAndLossOld.filter(Boolean).forEach((report) => {
          const relevantAccount = report.LessCostOfSales?.find(
            (item) => item.accountId === costOfSalesAccount
          );
          previousValue += relevantAccount?.value ?? 0;
          if (!accountName) accountName = relevantAccount?.accountName || null;
        });
        systemMessage += `\n ${accountName}, cost of sales, ${previousValue}, ${currentValue}`;
      });

      // Opex accounts
      systemMessage += '\n Operating Expenses';
      allAccounts.opexAccounts.forEach((opexAccount) => {
        let currentValue = 0;
        let accountName = null;
        profitAndLossCurrent.filter(Boolean).forEach((report) => {
          const relevantAccount = report.LessOperatingExpenses?.find(
            (item) => item.accountId === opexAccount
          );
          currentValue += relevantAccount?.value ?? 0;
          accountName = relevantAccount?.accountName || null;
        });
        let previousValue = 0;
        profitAndLossOld.filter(Boolean).forEach((report) => {
          const relevantAccount = report.LessOperatingExpenses?.find(
            (item) => item.accountId === opexAccount
          );
          previousValue += relevantAccount?.value ?? 0;
          if (!accountName) accountName = relevantAccount?.accountName || null;
        });
        systemMessage += `\n ${accountName}, operating expenses, ${previousValue}, ${currentValue}`;
      });

      // Summary
      systemMessage += '\n Summary';
      systemMessage += `\n Category, Total change during ${oldPeriodHeader}, Total change during ${newPeriodHeader}`;
      let grossProfitTotalCurrent = 0;
      let grossProfitTotalPrevious = 0;
      profitAndLossCurrent.filter(Boolean).forEach((report) => {
        grossProfitTotalCurrent += report?.Summary?.GrossProfit || 0;
      });
      profitAndLossOld.filter(Boolean).forEach((report) => {
        grossProfitTotalPrevious += report?.Summary?.GrossProfit || 0;
      });
      systemMessage += `\n Total gross profit change,${grossProfitTotalPrevious},${grossProfitTotalCurrent}`;

      let netProfitTotalCurrent = 0;
      let netProfitTotalPrevious = 0;
      profitAndLossCurrent.filter(Boolean).forEach((report) => {
        netProfitTotalCurrent += report?.Summary?.NetProfit || 0;
      });
      profitAndLossOld.filter(Boolean).forEach((report) => {
        netProfitTotalPrevious += report?.Summary?.NetProfit || 0;
      });
      systemMessage += `\n Total net profit change,${netProfitTotalPrevious},${netProfitTotalCurrent}`;

      let costOfSalesTotalCurrent = 0;
      let costOfSalesTotalPrevious = 0;
      profitAndLossCurrent.filter(Boolean).forEach((report) => {
        costOfSalesTotalCurrent += report?.Summary?.TotalCostOfSales || 0;
      });
      profitAndLossOld.filter(Boolean).forEach((report) => {
        costOfSalesTotalPrevious += report?.Summary?.TotalCostOfSales || 0;
      });
      systemMessage += `\n Total cost of sales change,${costOfSalesTotalPrevious},${costOfSalesTotalCurrent}`;

      let incomeTotalCurrent = 0;
      let incomeTotalPrevious = 0;
      profitAndLossCurrent.filter(Boolean).forEach((report) => {
        incomeTotalCurrent += report?.Summary?.TotalIncome || 0;
      });
      profitAndLossOld.filter(Boolean).forEach((report) => {
        incomeTotalPrevious += report?.Summary?.TotalIncome || 0;
      });
      systemMessage += `\n Total income change,${incomeTotalPrevious},${incomeTotalCurrent}`;

      let opexTotalCurrent = 0;
      let opexTotalPrevious = 0;
      profitAndLossCurrent.filter(Boolean).forEach((report) => {
        opexTotalCurrent += report?.Summary?.TotalOperatingExpenses || 0;
      });
      profitAndLossOld.filter(Boolean).forEach((report) => {
        opexTotalPrevious += report?.Summary?.TotalOperatingExpenses || 0;
      });
      systemMessage += `\n Total operating expenses change,${opexTotalPrevious},${opexTotalCurrent}`;
    }
    return systemMessage;
  };

  getDashboard = (dataset) => {
    const includeUnreconciled = dataset.includes('unreconciled');
    const includeRevenue = dataset.includes('revenue');
    const includeNetProfit = dataset.includes('netProfit');
    const includeIncomeTax = dataset.includes('incometax');
    const includeCashflow = dataset.includes('cashflow');
    const includeGstestimate = dataset.includes('gstestimate');
    const includeGstcompletion = dataset.includes('gstcompletion');
    let message = '\n Business Name';
    if (includeUnreconciled) {
      message += ', Unreconciled Items, Oldest Unreconciled Item';
    }
    if (includeRevenue) {
      message += ', Revenue Forecast, Revenue Trend';
    }
    if (includeNetProfit) {
      message += ', Net Profit Forecast, Net Profit Trend';
    }
    if (includeIncomeTax) {
      message += ', Income Tax Forecast, Income Tax Trend';
    }
    if (includeCashflow) {
      message += ', Cash Position, Cash Position Trend';
    }
    if (includeGstestimate) {
      message += ', GST Forecast, GST Trend';
    }
    if (includeGstcompletion) {
      message += ', GST  Completion, GST Due Date, GST Status';
    }
    message += '\n';

    this.rootStore.dashboardStore.businesses
      // .filter((row) => !row.loading)
      .forEach((row) => {
        message += `"${row?.businessName}", `;
        if (includeUnreconciled) {
          const unreconciled = row.data?.find((d) => d.key === 'unreconciled');
          message += `"${unreconciled?.current?.formattedValue}", "${unreconciled?.change?.rawValue}", `;
        }
        if (includeRevenue) {
          const revenue = row.data?.find((d) => d.key === 'revenue');
          message += `"${revenue?.current?.formattedValue}", "${revenue?.trendDirection} ${revenue?.change?.formattedValue}", `;
        }
        if (includeNetProfit) {
          const netProfit = row.data?.find((d) => d.key === 'netProfit');
          message += `"${netProfit?.current?.formattedValue}", "${netProfit?.trendDirection} ${netProfit?.change?.formattedValue}", `;
        }
        if (includeIncomeTax) {
          const incometax = row.data?.find((d) => d.key === 'incometax');
          message += `"${incometax?.current?.formattedValue}", "${incometax?.trendDirection} ${incometax?.change?.formattedValue}", `;
        }
        if (includeCashflow) {
          const cashflow = row.data?.find((d) => d.key === 'cashflow');
          message += `"${cashflow?.current?.formattedValue}", "${cashflow?.trendDirection} ${cashflow?.change?.formattedValue}", `;
        }
        if (includeGstestimate) {
          const gstestimate = row.data?.find((d) => d.key === 'gstestimate');
          message += `"${gstestimate?.current?.formattedValue}", "${gstestimate?.trendDirection} ${gstestimate?.change?.formattedValue}", `;
        }
        if (includeGstcompletion) {
          const gstestimate = row.data?.find((d) => d.key === 'gstestimate');
          const gstcompletion = row.data?.find(
            (d) => d.key === 'gstcompletion'
          );
          message += `"${gstcompletion?.current?.formattedValue}", "${gstcompletion?.change?.formattedValue}", "${gstestimate?.reportStatus}", `;
        }
      });
    return message;
  };

  loadChatHistory = () => {
    // eslint-disable-next-line no-mixed-operators
    const businessId =
      this.page === 'insights'
        ? this.rootStore.businessStore?.selectedBusinessId
        : this.page === 'dashboard'
          ? this.rootStore.practiceStore.id
          : this.page;
    firestore()
      .collection('/advisoryAssistantChat')
      .where('businessId', '==', businessId || '')
      .get()
      .then((querySnapshot) => {
        this.messageHistory = querySnapshot.docs
          .map((doc) => doc.data())
          .filter((chat) => chat.userId === this.rootStore.userStore.id)
          .sort((a, b) => b.timestamp - a.timestamp)
          .reduce(
            (acc, obj) => acc.concat(
              obj.messages
                .sort((a, b) => b.timestamp - a.timestamp)
                .map((m) => ({ role: m.from, content: m.message }))
            ),
            []
          )
          .reverse();
      });
  };

  setPage = (page: string) => {
    this.page = page;
  };
}
