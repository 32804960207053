import React from 'react';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { useStore } from '../../stores/Store';
import { useAuthenticatedEffect } from '../../lib/customHooks/useAuthenticatedEffect';
import EditorRow from './EditorRow';
import ReportTemplateSelector from './ReportTemplateSelector';
import PeriodGranularity from '../PeriodGranularity';
import { InsightType } from '../../ts/enums/Constants';
import ReportTemplateFooter from './ReportTemplateFooter';
import ComponentRow from './ComponentRow';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const PerformanceReportV2 = () => {
  const rootStore = useStore();
  const { practiceStore, reportTemplateStore } = rootStore;

  useAuthenticatedEffect(() => {
    reportTemplateStore.fetchPerformanceReportTemplates();
  }, [practiceStore.id]);

  const onPeriodChange = (period) => {
    trackMixpanelEvent({ description: 'Report Editor - Change Period', properties: { period }, rootStore });
    rootStore.timePeriodStore.setProfitabilityPeriodSelected(period);
  };

  return (
    <section className='report-editor'>
      <header className='report-editor__header'>
        <div className='periodSelecterHeader__block'>
          <h3 className='report-editor__header-text'>Report Period</h3>
          <PeriodGranularity id='profitability-tab-granularity' type={InsightType.REPORT} />
          <Select
            className='profitability-period-dropdown'
            defaultValue={rootStore.timePeriodStore.profitabilityPeriodSelected}
            onChange={onPeriodChange}
            size='large'
            options={rootStore.timePeriodStore.profitabilityPeriods}
            value={rootStore.timePeriodStore.profitabilityPeriodSelected}
          />
        </div>
        {reportTemplateStore.reportTemplateSelectionList.length > 0 && (
          <div className='periodSelecterHeader__block report-editor__header-template'>
            <h3 className='report-editor__header-text'>Template</h3>
            <ReportTemplateSelector className='report-editor__header-template-selector' />
          </div>
        )}
      </header>
      <div className='report-editor__content scrollbar'>
        {reportTemplateStore.selectedPerformanceReportBlocks.map((
          block: PracticeTypes.ReportBlock,
          index: number,
          src: PracticeTypes.ReportBlock[]
        ) => {
          const blockInstance = src.filter((b, i) => b.type === block.type && i <= index).length;
          return (
            <>
              {block.type === 'page' && block.position > 0 && (
                <ComponentRow
                  key={`component-row_${block.id}`}
                  block={block}
                />
              )}
              <EditorRow
                key={block.id}
                block={block}
                blockInstance={blockInstance}
              />
            </>
          );
        })}
        {reportTemplateStore.selectedPerformanceReportBlocks.length > 0 && (
          <ComponentRow
            key='component-row_last'
            block={{ type: null, id: null, position: null }}
          />
        )}
        <EditorRow
          block={{ type: 'page', id: null, position: null }}
          blockInstance={reportTemplateStore.selectedPerformanceReportPageCount + 1}
        />
      </div>
      <ReportTemplateFooter />
    </section>
  );
};

export default observer(PerformanceReportV2);
