import Format, { enums } from '@aider/aider-formatting-library';
import { DateTime } from 'luxon';
import { DashboardEnums } from '@aider/constants-library';
import { Align } from '../enums/components';

const format = new Format();
const { FormatTypes, ValueTypes } = enums;

const currentDate = DateTime.now().endOf('month');
const lastDate = DateTime.now().minus({ months: 1 }).endOf('month');
const periodFormat = { month: FormatTypes.short, year: FormatTypes.short };
const dayFormat = { day: FormatTypes.short, month: FormatTypes.short };

const currentPeriod = format.formatValue({
  format: ValueTypes.formatDate,
  value: currentDate,
  dateFormat: periodFormat
});
const lastPeriod = format.formatValue({
  format: ValueTypes.formatDate,
  value: lastDate,
  dateFormat: periodFormat
});
const currentPeriodDay = format.formatValue({
  format: ValueTypes.formatDate,
  value: currentDate,
  dateFormat: dayFormat
});
const lastPeriodDay = format.formatValue({
  format: ValueTypes.formatDate,
  value: lastDate,
  dateFormat: dayFormat
});

export const DashboardColumns = {
  revenue: {
    insight: 'profitability',
    type: DashboardEnums.ColumnTypes.trend,
  },
  netProfit: {
    insight: 'profitability',
    type: DashboardEnums.ColumnTypes.trend,
  },
  incometax: {
    insight: 'compliance',
    type: DashboardEnums.ColumnTypes.trend,
    ews: true,
  },
  unreconciled: {
    insight: 'health',
    type: DashboardEnums.ColumnTypes.alert,
  },
  cashflow: {
    insight: 'cashflow',
    type: DashboardEnums.ColumnTypes.trend,
    ews: true,
    tooltipOptions: {
      currentPeriod,
      lastPeriod,
      currentPeriodDay,
      lastPeriodDay
    },
  },
  gstestimate: {
    insight: 'compliance',
    type: DashboardEnums.ColumnTypes.trend,
    ews: true,
  },
  gstcompletion: {
    insight: 'compliance',
    type: DashboardEnums.ColumnTypes.gst,
  }
};

export const sectionResyncTooltips = {
  bankReconciliation: {
    title: 'Resync reconciliation data for this period.',
    content: 'Quicker than the standard Resync. Use when you are working only on the reconciliation tasks.',
  },
};

export const WYSIWIG_TOOLBAR_OPTIONS = {
  text: ['blockType', 'inline', 'textAlign', 'list', 'link', 'history'],
  header: ['textAlign', 'history'],
};

/**
 * Enum to store the alignment and width of the columns in the checklist
 * if a table has more columns than the ones defined here,
 * it should be assumed to be the same alignment as the last column
 * if a width prop is provided, it will be used to set the width of the column
 * if no width prop is provided, the default width (20ch) will be used
 */
export const CHECKLIST_LAYOUTS = {
  // Default layout for all tables if no specific layout is defined
  default: [
    { alignment: Align.left },
    { alignment: Align.right }
  ],
  // Layout for specific checklist items
  items: {
    deletedTransactions: [
      { alignment: Align.left, width: '10ch' },
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.left },
      { alignment: Align.right }
    ],
    invoices__pre: [
      { alignment: Align.left, width: '21ch' },
      { alignment: Align.left, width: '30ch' },
      { alignment: Align.left, width: 'auto' },
    ],
    invoices: [
      { alignment: Align.left, width: '15ch' },
      { alignment: Align.right, width: '10ch' },
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.left, width: '20ch' },
      { alignment: Align.right, width: '15ch' }
    ],
    overdueBills__pre: [
      { alignment: Align.left, width: '21ch' },
      { alignment: Align.left, width: '30ch' },
      { alignment: Align.left, width: 'auto' },
    ],
    overdueBills: [
      { alignment: Align.left, width: '15ch' },
      { alignment: Align.right, width: '10ch' },
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.left, width: '20ch' },
      { alignment: Align.right, width: '15ch' }
    ],
    transactionsHighestValue: [
      { alignment: Align.left },
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.right },
    ],
    transactionsHighestValue__pre: [
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.right },
      { alignment: Align.right },
      { alignment: Align.right, width: '40ch' },
    ],
  },
  // Layout for Rule Types
  ruleTypes: {
    accounts: [
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.right },
      { alignment: Align.right },
      { alignment: Align.right, width: '40ch' },
    ],
    transactions: [
      { alignment: Align.left },
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.left, width: '40ch' },
      { alignment: Align.right },
    ],
  },
  // Default layout for sections if no specific item layout is defined
  sections: {
    bankReconciliation: [
      { alignment: Align.left, width: '30ch' },
      { alignment: Align.right, width: '25ch' },
    ],
    payroll: [
      { alignment: Align.left },
      { alignment: Align.right },
      { alignment: Align.right },
      { alignment: Align.center },
      { alignment: Align.right }
    ],
    gst: [
      { alignment: Align.left },
      { alignment: Align.right },
      { alignment: Align.right },
      { alignment: Align.left }
    ],
  }
};
