export const AIDER_LINKS = {
  self: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/root`,
  },
  config: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/config`,
  },
  tokens: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/oauth2/token`,
  },
  users: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/users`,
  },
  user: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/users/{userId}`,
    templated: true,
  },
  subscriptions: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v2/subscriptions`,
  },
  businesses: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/businesses`,
  },
  businessUsers: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/businesses/{businessId}/users`,
    templated: true,
  },
  businessInvitations: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/businesses/{businessId}/invitations`,
    templated: true,
  },
  feed: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/feed`,
  },
  apps: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/apps`,
  },
  connections: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/connections`,
  },
  intercomVerification: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/external/intercom/userVerification?clientType={type}`,
    templated: true,
  },
  feedback: {
    href: `${process.env.REACT_APP_AIDER_DOMAIN}/api/v1/feedback`,
  },
};

export const HELPDESK_URL = 'https://ask.aider.ai/help/kb-tickets/new';

export const SUPPORT_EMAIL = 'success@aider.ai';

export const AIDER_CONFIG = {
  'aider-ai-production': {
    configuration: {
      firebase: {
        apiKey: 'AIzaSyCydZP385Jgoj2PmZMKFaFiWDXYiWv9B-Q',
        databaseURL: 'https://aider-ai-production.firebaseio.com',
        storageBucket: 'aider-ai-production.appspot.com',
        authDomain: 'aider-ai-production.firebaseapp.com',
        messagingSenderId: '111489898947',
        projectId: 'aider-ai-production'
      },
      osp: {
        google: {
          googleTimeZone: {
            apiKey: 'AIzaSyCA2WxWNB2vNwojkz8QwMzTJftvMjhtFgw'
          },
          googlePlaces: {
            apiKey: 'AIzaSyD3amd5ocmFEvKW6R-14uvpl4sxiCBZY5k'
          }
        },
        intercom: {
          appId: 'db144sw3',
          iosApiKey: 'ios_sdk-62cd352b216e0776182115e278fe87a08cbe70c8',
          androidApiKey: 'android_sdk-d303c5f9425fe66adf73318de9baf0e817638a5a'
        },
        stripe: {
          apiKey: 'pk_live_4M1hOOLM9Bs16w6w6v45jcYc'
        }
      },
      analytics: {
        googleAnalytics: {
          trackingId: 'UA-114041830-2'
        },
        googleTagManager: {
          androidContainerId: 'GTM-WVWK8ML',
          iosContainerId: 'GTM-TGJJJ3C',
          advisorPortalContainerId: 'GTM-KTP3SV4'
        }
      },
      help: {
        externalUrl: 'https://www.aider.ai/'
      }
    }
  },
  'aider-ai-staging': {
    configuration: {
      firebase: {
        apiKey: 'AIzaSyAtdbeBBMDQza5uD4ccd0V_EINypq0dvCk',
        databaseURL: 'https://aider-ai-staging.firebaseio.com',
        storageBucket: 'aider-ai-staging.appspot.com',
        authDomain: 'aider-ai-staging.firebaseapp.com',
        messagingSenderId: '511194687447',
        projectId: 'aider-ai-staging'
      },
      osp: {
        google: {
          googleTimeZone: {
            apiKey: 'AIzaSyBleSwJY4rUSq8sqVl5IEGTELumHCaNSFQ'
          },
          googlePlaces: {
            apiKey: 'AIzaSyDzMqhHtS5yUz5a2Z7vVgIO3U_P4u1yPFQ'
          }
        },
        intercom: {
          appId: 'trgy2nua',
          iosApiKey: 'ios_sdk-97c582cf56bd0efad7567d12338ba30e4a48ee56',
          androidApiKey: 'android_sdk-902ebf78799ae9563328c43f7f10630417eeea6c'
        },
        stripe: {
          apiKey: 'pk_test_3TUly61sc2UePfoXNYNO7ixD'
        }
      },
      analytics: {
        googleAnalytics: {
          trackingId: 'UA-114041830-1'
        },
        googleTagManager: {
          androidContainerId: 'GTM-KR4ZN4V',
          iosContainerId: 'GTM-NBZJRSR',
          advisorPortalContainerId: 'GTM-M8Q93BP'
        }
      },
      help: {
        externalUrl: 'https://www.aider.ai/'
      }
    }
  }
};

export const FREE_TIER_CLIENT_LIMIT_EXCEEDED = 'Free tier client limit reached';
