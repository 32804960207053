import * as Sentry from '@sentry/browser';
import { getAcquisitionData } from './acquisitionHelper';
import { POST } from '../../lib/requests';

export const loadIntercom = async (rootStore, tokens = {}, user) => {
  try {
    const intercomVerification = await POST({
      url: `${process.env.REACT_APP_INSIGHT_ENDPOINT}/intercom`,
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
      rootStore,
      data: {
        userId: rootStore.userStore.id,
      },
    });

    const { appId } = intercomVerification;

    const company = {
      company_id: rootStore.businessStore.practiceId.id,
      name: rootStore.businessStore.practiceId.name,
      plan: `${rootStore.businessStore.practiceId.subscription?.type} ${rootStore.businessStore.practiceId.subscription?.status} ${rootStore.businessStore.practiceId.subscription?.stripeId}`,
      size: rootStore.businessStore.practiceId.subscription?.items[0]
        ?.numberOfClients,
    };

    const { acquisition } = getAcquisitionData();

    window.intercomSettings = {
      app_id: appId,
      user_id: rootStore.userStore.id,
      user_hash: intercomVerification.hmac,
      name:
        user.displayName
        || rootStore.userStore.givenName
        || rootStore.userStore.username,
      email: user.email || rootStore.userStore.email,
      phone: user.phoneNumber,
      company,
      ...acquisition
    };

    // Provided by Intercom, removed event listener
    (function () {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${appId}`;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    }());
    return window.Intercom;
  } catch (e) {
    Sentry.captureException(e);
  }
};
