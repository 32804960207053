// TODO: no-cycle needs to be resolved
/* eslint import/no-cycle: "off", no-console: "off" */
import axios from 'axios';
import * as Sentry from '@sentry/browser';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import firebase from 'firebase'; // While unused, required for auth
import { logActionEvent } from '../entities/utils';
import { rootStore } from '../stores/Store';
import BusinessService from './businessService';
import Notification from '../components/Notification';
import { POST } from '../lib/requests';
import handleError from '../lib/errorHandler';

export default class ActionService {
  static async getEmailContacts() {
    const tokens = await BusinessService.refreshPracticeCredentials();

    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_EMAIL_SERVICE}/businesses/${rootStore.businessStore.selectedBusinessId}/contacts`,
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        'X-API-Key': process.env.REACT_APP_API_KEY,
      },
    }).then((res) => {
      rootStore.actionStore.storeEmailContacts(res.data);
      return res.data;
    }).catch((e) => {
      console.log(e);
      return [];
    });
  }

  // eslint-disable-next-line consistent-return
  static async uploadImagesByKey(tokens) {
    const bodyItem = {};
    // eslint-disable-next-line array-callback-return
    rootStore.actionStore.confirmedChartSelection.map((chart) => {
      bodyItem[`${chart.split('_')[0]}`] = rootStore.actionStore.chartImages[`${chart.split('_')[0]}`];
    });

    let imageUrl = null;
    try {
      imageUrl = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_STORAGE_SERVICE_URL}/businesses/${rootStore.businessStore.selectedBusinessId}/insights/graphs`,
        data: bodyItem,
        headers: {
          Authorization: `Bearer ${tokens.access_token}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      });
      return imageUrl;
    } catch (e) {
      console.log(e);
    }
  }

  // eslint-disable-next-line consistent-return
  static async sendEmailAction(sendList, messageInput, type, subject) {
    const tokens = await BusinessService.refreshPracticeCredentials();
    try {
      const imageUrlMulti = await ActionService.uploadImagesByKey(tokens);
      let message = messageInput;
      rootStore.actionStore.confirmedChartSelection.forEach((fullKey) => {
        const endToken = `<-end_${fullKey}->`;
        const startToken = `<-${fullKey}->`;
        message = message.replace(endToken, '').replace(startToken, '');
      });
      logActionEvent(rootStore.actionStore.selectedSubjectKey);
      const recipientData = sendList.map((item, index, arr) => {
        let newMessage = message;
        if (arr.length > 1) {
          newMessage = message.replace('<-client_name->', 'everyone');
        } else if (!item.displayName) {
          newMessage = message.replace('<-client_name->', '');
        } else {
          newMessage = message.replace('<-client_name->', item.displayName);
        }
        if (arr.length > 1) {
          return ({
            email: item.email.toLowerCase(), subject, message: newMessage, imageUrls: imageUrlMulti.data,
          });
        }
        if (item.externalContact || item.manualEntry) {
          return ({
            email: item.email.toLowerCase(), subject, message: newMessage, imageUrls: imageUrlMulti.data,
          });
        }
        return ({
          recipientId: item.userId, subject, message: newMessage, imageUrl: imageUrlMulti.data,
        });
      });

      const url = `${process.env.REACT_APP_EMAIL_SERVICE}/practices/${rootStore.practiceStore.id}/insights`;
      const data = {
        practiceId: rootStore.practiceStore.id,
        clientBusinessId: rootStore.businessStore.selectedBusinessId,
        recipientData,
      };
      POST({ url, data, rootStore }).then((res) => {
        if (!res) {
          throw Error('No response');
        }
        return [
          res.filter((item) => item.result === 'SENT'), // Successful send
          res.filter((item) => item.result === 'FAILED'), // Failed sends
          res.filter((item) => item.result !== 'SENT' && item.result !== 'FAILED') // Any other response which should never occur
        ];
      })
        .then(([success, failed, other]) => {
          if (failed.length > 0) {
            Notification({
              type: 'error',
              title: 'Email not sent',
              description: `Could not send email to the following: ${failed.map((item) => item.email).join(', ')}`
            });
          }

          if (success.length > 0) {
            Notification({
              type: 'success',
              title: 'Email sent successfully',
              description: `Insight sent for ${rootStore.businessesStore.selectedBusiness.name} to ${success.length} clients`,
            });
          }

          if (other.length > 0) {
            Notification({
              type: 'warning',
              title: 'Abnormal email status',
              description: `Unexpected response from email service: ${other.map((item) => item.email).join(', ')}`
            });
          }
        })
        .finally(() => {
          rootStore.actionStore.setConfirmedChartSelectionNull();
        })
        .catch((error) => {
          Notification({
            type: 'error',
            title: 'Email not sent',
            description: 'Could not send email, please try again...'
          });
          throw Error(error);
        });
    } catch (error) {
      handleError({ error });
      rootStore.actionStore.setConfirmedChartSelectionNull();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static async sendCustomTierEmailToCS(data: any) {
    const { subscriptionDetails, practiceId } = rootStore.businessStore;
    const { currentTier, currentClientCount } = subscriptionDetails;

    const emailData = {
      ...data,
      practiceName: practiceId.name,
      currentTier,
      currentClientCount
    };

    try {
      const { access_token: accessToken } = await BusinessService.refreshPracticeCredentials();
      if (!accessToken) return '';

      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_EMAIL_SERVICE}/subscription/customTier`,
        data: {
          practiceId: practiceId.id,
          emailData
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-API-Key': process.env.REACT_APP_API_KEY,
        },
      }).catch((e) => {
        console.debug('ERRORNEOUS:', e);
        Sentry.captureException(e);
      });
    } catch (e) {
      console.debug('error:', e);
      Sentry.captureException(e);
      return e;
    }
  }
}
