import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { PracticeTypes } from '@aider/constants-library';
import { ModalWidths } from '../../models/enums/modals';
import StandardModal from './wrappers/StandardModal';
import LLMAssistantContent from './content/LLMAssistantContent';
import GenAI from '../icons/GenAI';
import type ReportTemplateStore from '../../stores/reportTemplateStore';

const LLMAssistantModal = (block: PracticeTypes.ReportBlock, reportTemplateStore: ReportTemplateStore) => {
  NiceModal.register('LLMAssistantModal', StandardModal);

  const onOk = () => {
    reportTemplateStore.setEditBlock(block);
    reportTemplateStore.useLLMReportContent();
  };

  const onCancel = () => {
    reportTemplateStore.clearLLMReportContent();
  };

  const afterClose = () => {
    reportTemplateStore.clearConversationHistory();
    NiceModal.remove('LLMAssistantModal');
  };

  const ModalProps = {
    name: 'LLM Assistant Modal',
    title: (
      <>
        <GenAI /> Report Assistant
      </>
    ),
    okText: 'Use AI Content',
    onOk,
    cancelText: 'Keep Original Content',
    onCancel,
    className: 'report-assistant-modal',
    width: ModalWidths.CONTAINER,
    content: <LLMAssistantContent block={block} />,
    afterClose,
  };

  return NiceModal.show('LLMAssistantModal', ModalProps);
};

export default LLMAssistantModal;
