import React from 'react';
import { AlignLeftOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { Tooltip } from 'antd';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';

const AddComponentPopup = ({
  blockId,
  setTooltipVisible
} : {
  blockId: PracticeTypes.ReportBlock['id'],
  setTooltipVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { reportTemplateStore } = useStore();

  const handleActionClick = (action: PracticeTypes.ReportBlock['type']) => {
    setTooltipVisible(false);

    switch (action) {
      case 'text':
        reportTemplateStore.addTextBlock(blockId);
        break;
      case 'header':
        reportTemplateStore.addHeaderBlock(blockId);
        break;
      default:
    }
  };

  return (
    <nav className='report-editor__add-component' data-children-count='2'>
      <Tooltip
        title='Text Block'
        placement='bottom'
      >
        <ButtonSecondary
          size='large'
          shape='circle'
          icon={<AlignLeftOutlined />}
          mixpanelEvent='Report Editor - Add Text Block'
          onClick={() => {
            handleActionClick('text');
          }}
        />
      </Tooltip>
      <Tooltip
        title='Title Block'
        placement='bottom'
      >
        <ButtonSecondary
          size='large'
          shape='circle'
          mixpanelEvent='Report Editor - Add Title Block'
          onClick={() => {
            handleActionClick('header');
          }}
        >
          T
        </ButtonSecondary>
      </Tooltip>
    </nav>
  );
};

export default AddComponentPopup;
