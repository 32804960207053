import React from 'react';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';
import GenAI from '../../icons/GenAI';
import LLMAssistantModal from '../../modals/LLMAssistantModal';

const TextActions = ({
  block,
  blockId,
  className
}: {
  block: PracticeTypes.ReportBlock,
  blockId: PracticeTypes.ReportBlock['id'],
  className: string
}) => {
  const { reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === blockId;

  return (
    <aside className={className}>
      {!isEditing && (
        <>
          <ButtonSecondary
            className='borderless'
            icon={<GenAI />}
            mixpanelEvent='Report Editor - Gen AI'
            onClick={() => {
              LLMAssistantModal(block, reportTemplateStore);
            }}
          />
          <ButtonSecondary
            className='borderless'
            icon={<EditOutlined />}
            mixpanelEvent='Report Editor - Edit Text'
            mixpanelProperties={{ editBy: 'Action Button' }}
            onClick={() => { reportTemplateStore.editBlock = blockId; }}
          />
        </>
      )}
      {isEditing && (
        <>
          <ButtonSecondary
            className='borderless'
            icon={<CheckCircleOutlined />}
            mixpanelEvent='Report Editor - Save Text'
            onClick={() => {
              reportTemplateStore.editBlock = null;
            }}
          />
        </>
      )}
    </aside>
  );
};

export default observer(TextActions);
