import * as React from 'react';
import Markdown from 'markdown-to-jsx';
import { DateTime } from 'luxon';
import { Collapse, Table } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ClientManagementEnums, PeriodCloseTypes } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import { CHECKLIST_LAYOUTS } from '../../models/constants/components';
import CellContent from './CellContent';
import CheckListActions from '../../components-v2/molecules/CheckList/CheckListActions';
import ClientSettingsModal from '../modals/ClientSettingsModal';
import { ClientSettingsPages } from '../../models/enums/modals';

/**
 * Component that renders the content of a Period Close Check
 * @returns
 */
const PeriodCloseChecklistTable = ({
  title,
  table,
  checklistIdentifier,
  selectedPeriod,
  period,
  defaultOpen,
  ospKey,
}: PeriodCloseTypes.ChecklistItem) => {
  const { localeStore, businessesStore } = useStore();
  const rootStore = useStore();

  /** List of item IDs that have bullet points */
  const hasBulletPoints = [
    'unreconciledTransactions',
    'unreconciledBankLines',
    'reconcileBalances',
  ].includes(checklistIdentifier?.itemId);

  const periodEnd = period?.periodEnd ? DateTime.fromISO(period.periodEnd).toFormat('dd/LL/y') : 'period end';

  /**
   * Get the table template for the main table following the heirarchy:
   * 1. Layout for the Custom Rule item id
   * 2. Layout for the Rule Type
   * 3. Layout for the item id
   * 4. Layout for the section id
   * 5. Default layout
   */
  const template = CHECKLIST_LAYOUTS.items?.[checklistIdentifier?.ruleItemId]
      || CHECKLIST_LAYOUTS.ruleTypes?.[checklistIdentifier?.ruleType]
      || CHECKLIST_LAYOUTS.items?.[checklistIdentifier.itemId]
      || CHECKLIST_LAYOUTS.sections?.[checklistIdentifier.sectionId]
      || CHECKLIST_LAYOUTS.default;

  /**
   * Same as above but for the table that is displayed before the main table
   */
  const preTemplate = CHECKLIST_LAYOUTS.items?.[`${checklistIdentifier?.ruleItemId}__pre`]
      || CHECKLIST_LAYOUTS.ruleTypes?.[`${checklistIdentifier?.ruleType}__pre`]
      || CHECKLIST_LAYOUTS.items?.[`${checklistIdentifier.itemId}__pre`]
      || CHECKLIST_LAYOUTS.sections?.[`${checklistIdentifier.sectionId}__pre`]
      || CHECKLIST_LAYOUTS.default;

  /**
   * Get the alignment and width of a cell from the template
   */
  const getCellDetails = (cellIndex: number) => (
    template?.[cellIndex] || template[template.length - 1]
  );

  const getPreCellDetails = (cellIndex: number) => (
    preTemplate?.[cellIndex] || preTemplate[preTemplate.length - 1]
  );

  const { tableRows, columnCount } = table || { tableRows: [], columnCount: 0 };

  const columns = [];
  const headerIndex = tableRows.findIndex((row) => row.rowType === 'header');
  const headerRow = tableRows.filter((row) => row.rowType === 'header')?.[0];
  let preHeadRows;
  const preHeadCols = [];
  const preHeadTableData = [];

  businessesStore.fetchBankAccounts(
    businessesStore.selectedBusinessId
  );

  const selectedNumber = businessesStore?.selectedBusiness.profile?.hasSelectedBankAccount?.length
    ? businessesStore?.selectedBusiness?.profile?.hasSelectedBankAccount?.length
    : 'all';

  /**
   * If the header row is not the first row, there is a pre table
   * before the main table.
   * Generate the columns and data for the pre table
   */
  if (headerIndex > 0) {
    preHeadRows = tableRows.slice(0, headerIndex);
    if (preHeadRows?.length > 0 && preHeadRows[0].rowCells?.length > 0) {
      preHeadRows[0].rowCells.forEach((cell, index) => {
        const { alignment, width } = getPreCellDetails(index);
        preHeadCols.push({
          key: `pre-head-row-${checklistIdentifier.itemId}-${cell.cellIndex}`,
          dataIndex: cell.cellIndex,
          className: `cell-align__${alignment}`,
          title: '',
          fixed: cell.cellIndex === 0 ? 'left' : undefined,
          width: width || '20ch',
        });
      });
      preHeadRows.forEach((row) => {
        const rowCells = {};
        row.rowCells.forEach((cell) => {
          rowCells[cell.cellIndex] = (
            <span
              className={`period-close-checklist__cell period-close-table__row--${
                row.rowType
              }`}
            >
              <CellContent checklistIdentifier={checklistIdentifier} cell={cell} row={row} table={table} />
            </span>
          );
        });
        preHeadTableData.push(rowCells);
      });
    }
  }

  if (headerRow) {
    /** If there is a header row, generate the columns for the main table */
    const headerCells = headerRow.rowCells;
    for (let i = 0; i < columnCount; i++) {
      const { alignment, width } = getCellDetails(i);
      const content = {
        key: `header-row-${checklistIdentifier.itemId}-${headerRow.rowIndex}-${headerCells?.[i]?.cellIndex || i}`,
        dataIndex: headerCells?.[i]?.cellIndex || i,
        className: `period-close-table__row-header cell-align__${alignment}`,
        title: (<CellContent checklistIdentifier={checklistIdentifier} cell={headerCells?.[i]} row={headerRow} table={table} />),
        fixed: i === 0 ? 'left' : undefined,
        width: width || '20ch',
      };
      columns.push(content);
    }
  } else {
    /**
     * If there is no header row, generate empty columns for as many cells
     * identified to be present in the table
     */
    for (let i = 0; i < columnCount; i++) {
      const { alignment, width } = getCellDetails(i);
      const content = {
        key: `header-row-${checklistIdentifier.itemId}-${i}`,
        dataIndex: i,
        className: `period-close-table__row-header__empty cell-align__${alignment}`,
        title: '',
        fixed: i === 0 ? 'left' : undefined,
        width: width || '20ch',
      };
      columns.push(content);
    }
  }

  /** Generate the data for the main table */
  const tableData = tableRows
    ?.slice(headerIndex + 1)
    ?.map((row) => (
      row?.rowCells
        ?.flatMap((cell) => ({
          [cell.cellIndex]: (
            <span
              className={`period-close-checklist__cell period-close-table__row--${
                row.rowType
              }`}
            >
              <CellContent checklistIdentifier={checklistIdentifier} cell={cell} row={row} table={table} />
            </span>
          )
        }))
        ?.reduce((acc, cell) => ({ ...acc, ...cell }), {})

    ));

  const renderEmptyTable = () => (
    <div className='period-close-checklist__empty'>
      No matching data found.
    </div>
  );

  return (
    <Collapse
      className='period-close-checklist'
      ghost
      bordered={false}
      defaultActiveKey={[defaultOpen ? checklistIdentifier.itemId : null]}
      expandIcon={({ isActive }) => (isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />)}
      items={[{
        key: checklistIdentifier.itemId,
        label: title,
        extra: (<CheckListActions checklistIdentifier={checklistIdentifier} selectedPeriod={selectedPeriod} title={title} />),
        children: (
          <section className='period-close-table__wrapper'>
            {hasBulletPoints && (
              <div className='checklistBullets'>
                <Markdown>
                  {localeStore.translation(
                    `period-close.bullet-points.${checklistIdentifier?.itemId}.${ospKey}`,
                    { periodEnd, baseUrl: process.env.REACT_APP_QB_URL }
                  )}
                </Markdown>
                {(businessesStore.selectedBusinessOsp === ClientManagementEnums.OSPKeys.xero
                || checklistIdentifier?.itemId !== 'unreconciledBankLines') && (
                  <div className='checklistSettings'>
                    <span>Reconciliation Settings:</span>
                    <span>
                      {`${selectedNumber} of ${businessesStore?.selectedBusinessBankAccounts?.length || 'all'} active bank accounts selected.`}
                      <b
                        onClick={() => {
                          ClientSettingsModal(rootStore, ClientSettingsPages.CLIENT_CONFIGURATION);
                        }}
                        style={{ color: '#4264ea', cursor: 'pointer' }}
                      >{' Change >'}
                      </b>
                    </span>
                  </div>
                )}
              </div>
            )}
            {preHeadCols.length > 0 && (
              <Table
                showHeader={false}
                size='small'
                className='period-close-table pre-head-table'
                columns={preHeadCols}
                dataSource={preHeadTableData}
                scroll={{ x: '100%' }}
                pagination={false}
                locale={{ emptyText: renderEmptyTable }}
              />
            )}
            {columns.length > 0 && (
              <Table
                size='small'
                className='period-close-table'
                columns={columns}
                dataSource={tableData}
                scroll={{ x: '100%', y: tableRows.length > 12 ? 500 : undefined }}
                pagination={false}
                locale={{ emptyText: renderEmptyTable }}
              />
            )}
            {checklistIdentifier?.type === 'MANUAL_CHECK' && (
              <div className='manual-checklist-note'>
                <Markdown>
                  {table?.tableRows?.[0]?.rowCells?.[0]?.data}
                </Markdown>
              </div>
            )}
          </section>
        )
      }]}
    />
  );
};

PeriodCloseChecklistTable.defaultProps = {
  defaultOpen: false,
};

/** Export component */
export default PeriodCloseChecklistTable;
