import { makeAutoObservable } from 'mobx';
import { ClientManagementTypes } from '@aider/constants-library';
import type { RootStore } from './Store';
import { GET } from '../lib/requests';
import handleError from '../lib/errorHandler';
import { ClientManagementDataType } from '../models/interfaces/stores';

export default class ClientManagementStore {
  rootStore: RootStore;

  dashboardRows: ClientManagementTypes.ClientManagementRow[];

  dashboardFilters: ClientManagementTypes.ClientManagementFilter;

  searchString: string = '';

  loading: boolean = false;

  get filteredRows(): ClientManagementDataType[] {
    return this.dashboardRows
      .filter((row) => row.name.toLowerCase().includes(this.searchString.toLowerCase()))
      .map((row) => ({ key: row.id, ...row }));
  }

  async getClientManagementDashboard(options: { useGlobalLoader: boolean } = { useGlobalLoader: true }) {
    if (!this.loading) {
      this.loading = true;
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/users/${this.rootStore.userStore.id}/clientManagementDashboard`;
      if (options.useGlobalLoader) {
        this.rootStore.loadingStore.setLoading('clientManagement');
      }
      GET({ url, rootStore: this.rootStore })
        .then((res: ClientManagementTypes.ClientManagementResponse) => {
          if (!res.rows || !res.filters) {
            throw Error('Required Data not retrieved');
          }
          this.dashboardRows = res.rows;
          this.dashboardFilters = res.filters;
        })
        .catch((error) => {
          handleError({ error });
        })
        .finally(() => {
          this.rootStore.loadingStore.setDoneLoading('clientManagement');
          this.loading = false;
        });
    }
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }
}
