import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BusinessActivationStatus } from '@aider/constants-library/dist/enums/clientManagement';
import cookie from 'js-cookie';
import Navigator from './Navigator';
import AddClientRootController from './AddClientRootController';
import queryStringInspector from '../lib/queryStringInspector';
import { useStore } from '../stores/Store';
import UnsubscribeModal from './modals/UnsubscribeModal';
import { useAuthenticatedEffect } from '../lib/customHooks/useAuthenticatedEffect';
import { Routers } from '../models/enums/utils';
import AuthHandler from './AuthHandler';
import PaywallModal from './modals/PaywallModal';
import { CookieKeys } from '../ts/enums/Constants';

const Root = () => {
  const rootStore = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const appReferral = cookie.get(CookieKeys.appReferral);
  const appConnect = cookie.get(CookieKeys.appConnect);
  const appDisconnect = cookie.get(CookieKeys.appDisconnect);
  const loggingIn = cookie.get(CookieKeys.loggingIn);
  if (loggingIn) {
    cookie.remove(CookieKeys.loggingIn);
    rootStore.loadingStore.setLoading('auth');
  }

  useEffect(() => {
    rootStore.loadingStore.setLoading('help');
    rootStore.helpPanelStore.setSelectedIndex(null);
    rootStore.helpPanelStore.hideAllHelpItems();
    queryStringInspector(rootStore, navigate);
    rootStore.loadingStore.setDoneLoading('help');
  });

  if (rootStore.invitationStore.inviteToken && location.pathname !== Routers.INVITATION && location.pathname !== Routers.INVITE_ACCEPT) {
    navigate(Routers.INVITATION);
  }

  useAuthenticatedEffect(() => {
    if (rootStore.userStore.requiresUnsubscribe) rootStore.userStore.unsubscribeFromEmailService();

    if (rootStore.invitationStore.inviteToken && location.pathname !== Routers.INVITE_ACCEPT) {
      navigate(Routers.INVITE_ACCEPT);
    }

    if (
      rootStore.businessesStore.initialFetchComplete
      && rootStore.businessesStore.businesses.size === 0
      && !rootStore.invitationStore.inviteAccepted
      && !rootStore.invitationStore.inviteToken
      && !rootStore.authenticationStore.impersonatingUser
      && location.pathname !== Routers.ONBOARDING) {
      navigate(Routers.ONBOARDING);
    }

    if (rootStore.practiceStore.hasId && appReferral) {
      cookie.remove(CookieKeys.appReferral);
    }

    if (rootStore.practiceStore.hasId && appConnect) {
      rootStore.loadingStore.setLoading('appReferral');
      cookie.remove(CookieKeys.appConnect);
      rootStore.connectionsStore.connectionType = appConnect;
      rootStore.connectionsStore.modalAddConnection();
    }

    if (rootStore.practiceStore.hasId && appDisconnect) {
      cookie.remove(CookieKeys.appDisconnect);
      navigate(Routers.CLIENT_DISCONNECT.replace(':ospKey', appDisconnect));
    }

    if (location.pathname === Routers.DASHBOARD
      && !rootStore.dashboardStore.loading
      && rootStore.dashboardStore.businesses.size === 0
    ) {
      rootStore.dashboardStore.loadDashboard();
    }

    if (location.pathname === Routers.PERIOD_CLOSE_DASHBOARD
      && !rootStore.periodCloseDashboardStore.dashboardInitialised
      && rootStore.periodCloseDashboardStore.dashboard.size === 0
    ) {
      rootStore.periodCloseDashboardStore.initialiseDashboard();
    }

    if (!rootStore.businessesStore.initialFetchComplete && !rootStore.businessesStore.loading) {
      if (location.pathname.indexOf(Routers.CLIENTS_MANAGEMENT) > -1) {
        rootStore.businessesStore.fetchBusinessData();
      } else {
        rootStore.businessesStore.fetchBusinessData({ businessStatus: BusinessActivationStatus.activated });
      }
    }

    if (!rootStore.businessesStore.initialFetchComplete && location.pathname === Routers.INSIGHTS && rootStore.businessesStore.selectedBusinessId) {
      rootStore.businessStore.selectBusiness2(rootStore.businessesStore.selectedBusinessId);
    }
    if ((rootStore.authStore.user && !rootStore.authStore.loading && !rootStore.authStore.signInError)
      && (location.pathname === Routers.LOGIN || location.pathname === Routers.SIGNUP)) {
      rootStore.dashboardStore.loadDashboard();
      navigate(Routers.DASHBOARD);
    }

    if (location.pathname === Routers.ACTIVATE_CLIENTS && !rootStore.clientManagementStore.dashboardRows) {
      rootStore.clientManagementStore.getClientManagementDashboard();
    }
  }, [rootStore.invitationStore.inviteAccepted, rootStore.businessesStore.selectedBusinessId, rootStore.businessesStore.initialFetchComplete, rootStore.practiceStore.id]);

  useAuthenticatedEffect(() => {
    if (!rootStore?.businessesStore?.selectedBusinessId && rootStore?.businessesStore?.defaultSelectedBusinessId) {
      rootStore.loadingStore.setLoading('defaultBusiness');
      let selectDefaultBusiness;
      clearTimeout(selectDefaultBusiness);
      selectDefaultBusiness = setTimeout(() => {
        rootStore.businessesStore.selectedBusinessId = rootStore.businessesStore.defaultSelectedBusinessId;
        rootStore.businessStore.selectBusiness2(rootStore.businessesStore.defaultSelectedBusinessId);
        rootStore.loadingStore.setDoneLoading('defaultBusiness');
      }, 250);
    }
  }, [rootStore.businessesStore.defaultSelectedBusinessId]);

  if (rootStore.subscriptionStore.isTrialExpired) { PaywallModal(); }

  if (rootStore.userStore.showUnsubscribeModal) UnsubscribeModal({ rootStore, error: rootStore.userStore?.unsubscribeError });

  return (
    <>
      <AuthHandler rootStore={rootStore} />
      {rootStore.subscriptionStore.requiresPaywallRedirect && rootStore.subscriptionStore.paywallRedirect()}
      <Navigator />
      <AddClientRootController />
    </>
  );
};

export default observer(Root);
