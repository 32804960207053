import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { notification } from 'antd';
import { ComplianceChecklistEnums, PeriodCloseTypes } from '@aider/constants-library';
import type { RootStore } from './Store';
import { GET, POST, PUT } from '../lib/requests';

import 'firebase/app';
import 'firebase/storage';
import handleError from '../lib/errorHandler';
import { getIndexOfClosestFutureDate, getIndexOfClosestPastDate } from '../lib/dateUtils';
import { TableGroup } from '../models/interfaces/stores';
import { Urgency } from '../ts/enums/Constants';
import Notification from '../components/Notification';

export default class ChecklistStore {
  rootStore: RootStore;

  activeChecklistType: ComplianceChecklistEnums.Types = ComplianceChecklistEnums.Types.gstPeriodChecklist;

  selectedChecklistId: string;

  checklists: Map<string, PeriodCloseTypes.ChecklistItem>;

  businessChecklists: Map<string, Map<string, TableGroup>> = new Map();

  checklistLoading: boolean = false;

  activePeriod: string = '';

  activeItem: string = '';

  checklistRetrievalDebounce: any;

  checklistSectionOrder: any = {
    bankReconciliation: [
      'bankUnreconciled',
      'bankReconciliationBalances',
      'unreconciledBankLines',
      'unreconciledTransactions',
      'reconcileBalances',
      'deletedTransactions',
    ],
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });

    this.rootStore = rootStore;
    this.checklists = new Map();
  }

  set practice(practice) {
    if (practice.countryCode.toLowerCase() === 'us') {
      this.activeChecklistType = ComplianceChecklistEnums.Types.monthlyChecklist;
    }
  }

  get currentChecklistGroups() {
    const businessChecklist = this.businessChecklists.get(this.rootStore.businessesStore.selectedBusinessId);
    if (!businessChecklist || !businessChecklist.has(this.activeChecklistType)) {
      return null;
    }
    return businessChecklist.get(this.activeChecklistType);
  }

  get defaultPeriod() {
    let tableGroupInx: number = 0;
    if (this.currentChecklistGroups) {
      if (this.activeChecklistType === ComplianceChecklistEnums.Types.gstPeriodChecklist) {
        tableGroupInx = getIndexOfClosestFutureDate(
          this.currentChecklistGroups.tableGroups.map(
            (tableGroup) => tableGroup.tableGroupPeriod.periodDue
          )
        );
      } else if (this.activeChecklistType === ComplianceChecklistEnums.Types.yearlyChecklist) {
        tableGroupInx = getIndexOfClosestPastDate(
          this.currentChecklistGroups.tableGroups.map(
            (tableGroup) => tableGroup.tableGroupPeriod.periodEnd
          )
        );
      }
      return this.currentChecklistGroups.tableGroups?.[tableGroupInx].tableGroupPeriod.periodName;
    }
    return null;
  }

  async fireUpdateChecklist(checklistType: ComplianceChecklistEnums.Types, businessId: string = this.rootStore.businessesStore.selectedBusinessId) {
    try {
      const url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${businessId}/insights/tablegroups/${checklistType}`;
      const res = await PUT({ url, rootStore: this.rootStore });
      if (!res) throw new Error('No checklist updated');

      return res;
    } catch (error) {
      handleError({ error, operation: 'updateChecklist' });
      return error;
    }
  }

  // Why is this async?
  async storeBusinessChecklist(businessId: string, checkListType: ComplianceChecklistEnums.Types, checklist: TableGroup) {
    if (!this.businessChecklists.has(businessId)) {
      this.businessChecklists.set(businessId, new Map());
    }

    const businessChecklists = this.businessChecklists.get(businessId);
    businessChecklists.set(checkListType, checklist);
    this.businessChecklists.set(businessId, businessChecklists);

    return this.businessChecklists;
  }

  async retrieveChecklist(checkListType: ComplianceChecklistEnums.Types, businessId: string = this.rootStore.businessesStore.selectedBusinessId) {
    clearTimeout(this.checklistRetrievalDebounce);
    this.checklistRetrievalDebounce = setTimeout(() => {
      this.checklistLoading = true;
      if (!this.businessChecklists?.get(businessId)?.get(checkListType)) {
        this.checklistLoading = true;
      }
      const transaction = Sentry.startTransaction({
        name: 'retrieveChecklist',
      });

      const url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${businessId}/insights/tablegroups/${checkListType}`;
      GET({ url, rootStore: this.rootStore, sentryTransaction: transaction, sentrySpanName: 'GET: businessChecklist' })
        .then((res) => {
          if (!res) throw new Error('No checklist found');
          this.storeBusinessChecklist(businessId, checkListType, res);
          this.checklistLoading = false;
          transaction.finish();
          return res;
        })
        .catch((error) => {
          handleError({ error, operation: 'Retrieve Checklist' });
          this.storeBusinessChecklist(businessId, checkListType, {
            tableGroupTitle: 'No checklist found',
            tableGroupType: checkListType,
            tableGroups: null,
            error
          });
          this.checklistLoading = false;
          transaction.finish();
          return error;
        });
    }, 500);
  }

  // Older stuff? Still used?
  setActivePeriod(period: string) {
    this.activePeriod = period;
  }

  get selectedChecklist(): PeriodCloseTypes.ChecklistItem {
    return this.checklists.get(this.selectedChecklistId);
  }

  get selectedChecklistType(): string {
    return this.checklists.get(this.selectedChecklistId)?.type || ComplianceChecklistEnums.Types.gstPeriodChecklist;
  }

  set selectedChecklistType(checklistType: ComplianceChecklistEnums.Types) {
    let currentChecklist: PeriodCloseTypes.ChecklistItem = this.checklists.get(this.selectedChecklistId);

    if (!currentChecklist) {
      currentChecklist = {
        title: null,
        checkbox: null,
        status: null,
        table: null,
        checklistIdentifier: null,
        id: checklistType,
        type: checklistType,
      };
    }

    currentChecklist.type = checklistType;
    this.checklists.set(this.selectedChecklistId, currentChecklist);
  }

  set selectedChecklist(checklistId: string) {
    this.selectedChecklistId = checklistId;
  }

  updateChecklist = (checklist: PeriodCloseTypes.ChecklistItem) => {
    // Get the current checklist
    const currentChecklist = this.checklists.get(this.selectedChecklistId);
    // Set the updated checklist
    this.checklists.set(this.selectedChecklistId, {
      ...currentChecklist,
      ...checklist
    });
  };

  async fireUpdatePracticeBusinessesChecklists(checklistType: ComplianceChecklistEnums.Types) {
    try {
      this.rootStore.resyncStore.globalResyncStarted = Date.now();

      this.rootStore.businessesStore.activeClientBusinesses.forEach((business) => {
        this.rootStore.resyncStore.resyncingBusinesses.set(business.id, true);
      });

      const url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/practice/${this.rootStore.practiceStore.id}/insights/tablegroups/${checklistType}`;
      const res = await PUT({ url, rootStore: this.rootStore });
      if (!res) throw new Error('No practice businesses checklist updated');

      return res;
    } catch (error) {
      this.rootStore.resyncStore.globalResyncStarted = null;
      this.rootStore.businessesStore.activeClientBusinesses.forEach((business) => {
        this.rootStore.resyncStore.resyncingBusinesses.set(business.id, false);
      });

      handleError({ error, operation: 'updatePracticeBusinessesChecklists' });
      return error;
    }
  }

  async toggleCheckListCheck(
    tableGroupId: string,
    categoryId: string,
    itemId: string
  ) {
    try {
      const checklistData = { ...this.businessChecklists.get(this.rootStore.businessesStore.selectedBusinessId).get(this.activeChecklistType) };
      const tableGroup = checklistData?.tableGroups?.find((group) => group.tableGroupId === tableGroupId);
      const category = tableGroup?.categories?.find((cat) => cat.categoryId === categoryId);
      const itemIndex = category?.categoryItems?.findIndex((item) => item.itemId === itemId);
      const categoryItem = category?.categoryItems?.[itemIndex];
      categoryItem.checkBox = {
        ...categoryItem.checkBox,
        state: categoryItem.checkBox.state === 'checked' ? 'unchecked' : 'checked'
      };

      if (categoryItem.status === Urgency.danger) {
        categoryItem.status = Urgency.muted;
      } else if (categoryItem.status === Urgency.muted) {
        categoryItem.status = Urgency.danger;
      }
      // eslint-disable-next-line camelcase
      category.categoryItems[itemIndex] = categoryItem;
      this.storeBusinessChecklist(this.rootStore.businessesStore.selectedBusinessId, this.activeChecklistType, checklistData);

      const url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/businesses/${this.rootStore.businessesStore.selectedBusinessId}/insights/tablegroups/${tableGroupId}/category/${categoryId}/item/${itemId}/checkbox`;
      const res = await PUT({ url, rootStore: this.rootStore });

      if (!res) throw new Error(`Could not update the checkbox for ${tableGroupId}/${categoryId}/${itemId}`);

      return res;
    } catch (e) {
      handleError({ error: e, operation: 'toggleCheckListCheck' });
      return e;
    }
  }

  async resyncChecklistSection(checklistId: string, sectionId: string) {
    try {
      const checklist = this.currentChecklistGroups.tableGroups.find((tableGroup) => tableGroup.tableGroupId === checklistId);
      if (!checklist) throw new Error('No checklist found');
      const { periodStart, periodEnd } = checklist.tableGroupPeriod;

      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${this.rootStore.businessesStore.selectedBusinessId}/workflow/${sectionId}`;
      const data = {
        periodStart,
        periodEnd,
        checklistId,
        periodType: this.activeChecklistType,
      };

      return POST({ url, data, rootStore: this.rootStore });
    } catch (e) {
      handleError({ error: e, operation: 'resyncChecklistSection' });
      throw Error('Could not resync checklist section');
    }
  }

  updateCustomCell = async (tableGroupId: string, categoryId: string, itemId: string, rowInx: number, cellInx: number, cellId: string, value: number) => {
    const transaction = Sentry.startTransaction({
      name: 'updateCustomCell',
      data: {
        tableGroupId,
        categoryId,
        itemId,
        rowInx,
        cellInx,
        cellId,
        value
      }
    });

    let action = transaction.startChild({
      op: 'locateCell',
      description: 'Locate custom cell in global store before updating',
    });

    const checklistData = { ...this.businessChecklists.get(this.rootStore.businessesStore.selectedBusinessId).get(this.activeChecklistType) };
    const tablegroupIndex = checklistData.tableGroups.findIndex((group) => group.tableGroupId === tableGroupId);
    const categoryIndex = checklistData.tableGroups[tablegroupIndex].categories.findIndex((cat) => cat.categoryId === categoryId);
    const itemIndex = checklistData.tableGroups[tablegroupIndex].categories[categoryIndex].categoryItems.findIndex((item) => item.itemId === itemId);

    action.finish();

    action = transaction.startChild({
      op: 'updateCell',
      description: 'Updating custom cell in global store before sending to API',
    });

    this.businessChecklists
      .get(this.rootStore.businessesStore.selectedBusinessId)
      .get(this.activeChecklistType)
      .tableGroups[tablegroupIndex]
      .categories[categoryIndex]
      .categoryItems[itemIndex]
      .table.tableRows[rowInx]
      .rowCells[cellInx].rawData = value;

    action.finish();
    action = transaction.startChild({
      op: 'updateAPI',
      description: 'Sending updated cell to API',
    });

    Notification({
      type: 'info',
      title: 'Saving Changes...',
      description: 'Please do not refresh your page.',
    });

    PUT({
      url: `${
        process.env.REACT_APP_INSIGHT_ENDPOINT_V1
      }/businesses/${
        this.rootStore.businessesStore.selectedBusinessId
      }/insights/tablegroups/${
        tableGroupId
      }/category/${
        categoryId
      }/item/${
        itemId
      }/cell/${
        cellId
      }`,
      data: {
        customValue: value
      },
      rootStore: this.rootStore
    })
      .then(() => {
        action.finish();
        action = transaction.startChild({
          op: 'notify',
          description: 'Notify user of successful cell update',
        });

        notification.destroy();

        Notification({
          type: 'success',
          title: 'Changes Saved Successfully',
        });
      })
      .catch((error) => {
        action.finish();
        action = transaction.startChild({
          op: 'handleError',
          description: 'Handle error and notify user from updating custom cell',
        });

        handleError({ error, operation: 'updateCustomCell' });

        notification.destroy();

        Notification({
          type: 'error',
          title: 'Could not save changes',
          description: 'Please try again later',
        });
      })
      .finally(() => {
        action.finish();
        transaction.finish();
      });
  }
}
