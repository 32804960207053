import React, { useEffect } from 'react';
import { Select } from 'antd';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import { useAuthenticatedEffect } from '../../lib/customHooks/useAuthenticatedEffect';

const PeriodSelectionHeader = ({ simple }: { simple?: boolean }) => {
  const { checklistStore, localeStore, practiceStore } = useStore();
  const [checklistOptions, setChecklistOptions] = React.useState([
    { value: ComplianceChecklistEnums.Types.gstPeriodChecklist, label: 'GST Period Checklist' },
  ]);

  useAuthenticatedEffect(() => {
    if (!checklistStore.selectedChecklistId) {
      checklistStore.selectedChecklist = ComplianceChecklistEnums.Types.gstPeriodChecklist;
    }
  });

  const usPractice = practiceStore.isGlobalPractice;

  useEffect(() => {
    const options = [
      {
        value: ComplianceChecklistEnums.Types.yearlyDataCompliance,
        label: localeStore.translation('period-close.checklist-label', {
          periodType: localeStore.translation('period-close.period-type.yearlyDataCompliance')
        }),
      },
      {
        value: ComplianceChecklistEnums.Types.monthlyChecklist,
        label: localeStore.translation('period-close.checklist-label', {
          periodType: localeStore.translation('period-close.period-type.monthlyChecklist')
        }),
      },
    ];

    if (!usPractice) {
      options.splice(1, 0, {
        value: ComplianceChecklistEnums.Types.gstPeriodChecklist,
        label: localeStore.translation('period-close.checklist-label', {
          periodType: localeStore.translation('period-close.period-type.gstPeriodChecklist')
        }),
      });
    }

    setChecklistOptions(options);
  }, []);

  const onChecklistSelection = (value) => {
    checklistStore.selectedChecklistId = value;
    checklistStore.selectedChecklistType = value;
  };

  const defaultType = usPractice
    ? ComplianceChecklistEnums.TypeNames.monthlyChecklist
    : ComplianceChecklistEnums.TypeNames.gstPeriodChecklist;
  const checklistType = checklistStore.selectedChecklistType || defaultType;

  return (
    <div className={`periodSelecterHeader ${simple ? 'periodSelecterHeader--simple' : ''}`}>
      <Select
        className='selection-dropdown'
        defaultValue={checklistType}
        size='large'
        onChange={onChecklistSelection}
        options={checklistOptions}
      />
    </div>
  );
};

PeriodSelectionHeader.defaultProps = {
  simple: false
};

export default PeriodSelectionHeader;
