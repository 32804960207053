import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import * as firebase from 'firebase';
import { AccountConstants } from '@aider/constants-library';
import _ from 'lodash';
import type { RootStore } from './Store';
import { ILocation, IPracticeProfile, IPracticeSubscription } from '../models/interfaces/practice';
import { POST, PUT } from '../lib/requests';

import 'firebase/app';
import 'firebase/storage';
import { ISubscription } from '../ts/interfaces/components/Modal';
import { findAddressComponent, getPlaceIdDetails, getTimezone } from '../lib/googleUtils';
import { buildChartOfAccounts, getSortAndFilteredChartOfAccounts } from '../helpers/chartOfAccountsHelper';

export default class PracticeStore {
  rootStore: RootStore;

  address: string;

  area: string;

  businessClass: string;

  countryCode: string;

  id: string;

  lineOfBusiness: string;

  location: ILocation;

  numberOfClients: number;

  numberOfAdvisors: number;

  accountingSoftware: string[];

  name: string;

  profile: IPracticeProfile;

  subscription: IPracticeSubscription;

  subscriptionDetails: ISubscription = {
    currentClientCount: null,
    updatedClientCount: null,
    currentTier: null,
    newTier: null,
  };

  timeZoneId: string;

  brandingLogo: string;

  brandingError: string;

  phoneNumber: string;

  types: string[];

  utcOffset: number;

  website: string;

  rawOffset: number;

  dstOffset: number;

  get practiceInitials() {
    const initials = this.name?.split(' ');
    if (initials?.length === 1) {
      return `${initials?.[0]?.charAt(0) || ''}${initials?.[0]?.charAt(1) || ''
      }`?.toUpperCase();
    }
    return `${initials?.[0]?.charAt(0) || ''}${initials?.[1]?.charAt(0) || ''
    }`.toUpperCase();
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });

    this.rootStore = rootStore;
  }

  get practice() {
    return {
      address: this.address,
      area: this.area,
      businessClass: this.businessClass,
      countryCode: this.countryCode,
      id: this.id,
      lineOfBusiness: this.lineOfBusiness,
      location: this.location,
      name: this.name,
      profile: this.profile,
      subscription: this.subscription,
      timeZoneId: this.timeZoneId,
      brandingLogo: this.brandingLogo,
      numberOfAdvisors: this.numberOfAdvisors,
      numberOfClients: this.numberOfClients,
      accountingSoftware: this.accountingSoftware,
    };
  }

  set practice(practice) {
    this.address = practice.address;
    this.area = practice.area;
    this.businessClass = practice.businessClass;
    this.countryCode = practice.countryCode;
    this.id = practice.id;
    this.lineOfBusiness = practice.lineOfBusiness;
    this.location = practice.location;
    this.name = practice.name;
    this.address = practice.address;
    this.profile = practice.profile;
    if (practice.profile?.accountingSoftware) {
      this.accountingSoftware = practice.profile.accountingSoftware;
    }
    if (practice.profile?.numberOfClients) {
      this.numberOfAdvisors = parseInt(
        practice.profile.numberOfAccountants,
        10
      );
    }
    if (practice.profile?.numberOfClients) {
      this.numberOfClients = parseInt(practice.profile.numberOfClients, 10);
    }
    if (practice.profile?.logoUrl) {
      this.brandingLogo = practice.profile.logoUrl;
    }
    if (practice.numberOfAdvisors) {
      this.numberOfAdvisors = practice.numberOfAdvisors;
    }
    if (practice.numberOfClients) {
      this.numberOfClients = practice.numberOfClients;
    }
    if (practice.accountingSoftware) {
      this.accountingSoftware = practice.accountingSoftware;
    }
    this.subscription = practice.subscription;
    this.timeZoneId = practice.timeZoneId;
  }

  get isNZPractice() {
    return this.countryCode === 'NZ';
  }

  get isAUPractice() {
    return this.countryCode === 'AU';
  }

  get isUSPractice() {
    return this.countryCode === 'US';
  }

  get isGlobalPractice() {
    return ['NZ', 'AU'].indexOf(this.practice.countryCode) === -1;
  }

  get logoUrl() {
    return this.brandingLogo;
  }

  set logoUrl(logoUrl: string) {
    this.brandingLogo = logoUrl;
  }

  get hasId() {
    return !!this.id;
  }

  get hasRequiredInfo() {
    return (
      !!this.name
      && !!this.address
    );
  }

  get hasInfo() {
    return (
      !!this.name
      && !!this.address
      && !!this.area
      && !!this.countryCode
      && !!this.lineOfBusiness
      && !!this.location
      && !!this.profile
      && !!this.subscription
      && !!this.timeZoneId
    );
  }

  get connectedOSPs() {
    let osps = [];
    this.rootStore.businessesStore.businesses.forEach((business) => {
      osps = _.uniq(osps.concat(business.connectedOsps));
    });

    return osps.filter((osp) => osp);
  }

  chartOfAccounts() {
    this.connectedOSPs.forEach((osp) => {
      const practiceChartOfAccounts = AccountConstants.practiceChartOfAccountsPerOSP[osp];
      const builtAccountsChart = buildChartOfAccounts(practiceChartOfAccounts);
      const sortedAccounts = getSortAndFilteredChartOfAccounts(builtAccountsChart);

      if (!this.rootStore.businessesStore.businesses.get(this.id).sortedChartOfAccounts) {
        this.rootStore.businessesStore.businesses.get(this.id).sortedChartOfAccounts = {};
      }

      this.rootStore.businessesStore.businesses.get(this.id).sortedChartOfAccounts[osp] = sortedAccounts;
    });
  }

  async setPracticeAddressData(placeId: string) {
    try {
      const data = await getPlaceIdDetails({ placeId, rootStore: this.rootStore });
      const addressComponents = data?.address_components;

      this.countryCode = findAddressComponent(addressComponents, 'country')?.short_name;

      let area;
      if (this.countryCode === 'NZ' || this.countryCode !== 'AU') {
        area = findAddressComponent(addressComponents, 'administrative_area_level_1')?.long_name;
      } else {
        area = findAddressComponent(addressComponents, 'administrative_area_level_2')?.long_name;
        if (!area) {
          area = findAddressComponent(addressComponents, 'administrative_area_level_1')?.long_name;
        }
      }
      this.address = data?.formatted_address;
      this.area = area;
      this.location = { longitude: data?.geometry?.location?.lng, latitude: data?.geometry?.location?.lat };
      this.phoneNumber = data?.formatted_phone_number;
      this.types = data?.types;
      this.utcOffset = data?.utc_offset;
      this.website = data?.website;

      if (!this.area) {
        throw Error('User Tried to Set Up Practice with Address: Failure to Find Address');
      }

      const timezone = await getTimezone({
        location: this.location,
        rootStore: this.rootStore,
      });
      this.timeZoneId = timezone.timeZoneId;
      this.rawOffset = timezone.rawOffset;
      this.dstOffset = timezone.dstOffset;
    } catch (e) {
      Sentry.captureException('Could not get place details', e);
      throw e;
    }
  }

  usesSoftware(software: string) {
    return this.accountingSoftware.includes(software);
  }

  updatePractice = (practice) => {
    this.practice = {
      ...this.practice,
      ...practice,
    };
  };

  get practiceHTTPData() {
    return {
      name: this?.name,
      location: this?.location,
      address: this?.address,
      phoneNumber: this?.phoneNumber,
      types: this?.types,
      utcOffset: this?.utcOffset,
      website: this?.website,
      countryCode: this?.countryCode,
      area: this?.area,
      timeZoneId: this?.timeZoneId,
      rawOffset: this?.rawOffset,
      dstOffset: this?.dstOffset,
      profile: {
        accountingSoftware: this?.accountingSoftware,
        numberOfAccountants: this?.numberOfAdvisors,
        numberOfClients: this?.numberOfClients,
        logoUrl: this?.brandingLogo,
      }
    };
  }

  createPractice = async () => {
    try {
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices`;
      await POST({
        url,
        data: this.practiceHTTPData,
        rootStore: this.rootStore,
      });
      this.rootStore.connectionsStore.initialConnection = false;
      return true;
    } catch (e) {
      Sentry.captureException('Error creating Business', e);
      throw e;
    }
  };

  persistPractice = async () => {
    try {
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${this.id}`;
      await PUT({
        url,
        data: this.practiceHTTPData,
        rootStore: this.rootStore,
      });
      return true;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  handleUploadImage = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const practiceId = this.id;

    const storageRef = firebase.storage().ref();
    const practiceImageRef = storageRef.child(`logos/practice/${practiceId}`);

    const uploadTask = practiceImageRef.put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploade
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // CONNECT ON PROGRESS
        onProgress(progress);
      },
      (error) => {
        // Handle unsuccessful uploads
        onError(error);
        Sentry.captureException(error);
      },
      () => {
        // Handle successful uploads on complete
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onSuccess(downloadURL);
        });
      }
    );
  };
}
