import { autorun, makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { ClientManagementEnums, ClientManagementTypes, CountryEnums } from '@aider/constants-library';
import { DELETE, GET, PUT } from '../lib/requests';
import type { RootStore } from './Store';
import { BUSINESS_NOT_FOUND_ERROR } from '../entities/types';
import { ActivatedBusinesses, BankAccount } from '../models/interfaces/stores';
import Notification from '../components/Notification';
import handleError from '../lib/errorHandler';
import Mixpanel from '../lib/mixpanel';
import { convertBankAccounts } from '../lib/storeUtils';
import { buildChartOfAccounts, getSortAndFilteredChartOfAccounts } from '../helpers/chartOfAccountsHelper';
import LoadingStore from './loadingStore';

export default class BusinessesStore {
  rootStore: RootStore;

  businesses: Map<string, any>;

  selectedBusinessId: string;

  loading: boolean = false;

  retrievingConnection: boolean = false;

  selectedForActivation: string[] = [];

  initialFetchComplete: boolean = false;

  fetchingBusinessAdvisors: boolean = false;

  businessBankAccounts: Map<string, BankAccount[]> = new Map();

  businessConfigs: Map<string, ClientManagementTypes.ClientConfig> = new Map();

  bankAccountDebounce: string[] = [];

  businessConfigsDebounce: string[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.businesses = new Map();
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );

    /**
     * Update the count of businesses in dashboard store when the number of businesses in this store changes
     * this triggers dashboard store recomputation of the businesses for loading purposes
     */
    autorun(() => {
      this.rootStore.dashboardStore.businessStoreClientBusinessCount = this.clientBusinesses.size;
    });
  }

  /**
   * Computed
   */
  get selectedBusiness() {
    return this.businesses.get(this.selectedBusinessId);
  }

  get selectedBusinessOsp() {
    return this.businesses.get(this.selectedBusinessId)?.connectedOsps?.[0] || ClientManagementEnums.OSPKeys.xero;
  }

  get selectedBusinessOspName() {
    return this.rootStore.localeStore.translation(`osps.${this.selectedBusinessOsp}`);
  }

  /**
   * Identify the index of the first business in the list
   * to set as the default selected business
   */
  get defaultSelectedBusinessId() {
    if (this.loading) return null;
    const key = this.sortedActiveClientBusinesses?.[0]?.id;
    return key;
  }

  get selectedBusinessBankAccounts() {
    return this.businessBankAccounts.has(this.selectedBusinessId) ? this.businessBankAccounts.get(this.selectedBusinessId) : null;
  }

  get selectedBusinessConfig() {
    return this.businessConfigs.has(this.selectedBusinessId) ? this.businessConfigs.get(this.selectedBusinessId) : null;
  }

  get selectedBankAccounts() {
    return this.businesses.get(this.selectedBusinessId)?.profile?.hasSelectedBankAccount;
  }

  get isLoadingETL() {
    return this.businesses.get(this.selectedBusinessId)?.etl?.status !== 'Finished';
  }

  get defaultTaxRate() {
    switch (this.selectedBusiness.countryCode) {
      case 'AU':
        return 25;
      case 'NZ':
        return 28;
      case 'CA':
        return 15;
      case 'US':
        return 21;
      default:
        return 25;
    }
  }

  setBusinessForActivation(businesses: string | string[]) {
    if (typeof businesses === 'string') {
      this.selectedForActivation.push(businesses);
    } else {
      this.selectedForActivation = businesses;
    }
  }

  clearBusinessesForActivation() {
    this.selectedBusinessId = null;
    this.selectedForActivation = [];
  }

  clearBankAccounts() {
    this.interpolateBankAccounts([]);
  }

  selectBankAccount(bankAccountId: string) {
    if (this.selectedBankAccounts.indexOf(bankAccountId) === -1) {
      this.interpolateBankAccounts([...this.selectedBankAccounts, bankAccountId]);
    }
  }

  deselectBankAccount(bankAccountId: string) {
    const selectedBankAccounts = this.selectedBankAccounts.filter((account) => account !== bankAccountId) || [];
    this.interpolateBankAccounts(selectedBankAccounts);
  }

  updateBusinessByPath(businessId: string, path: string, value: any) {
    const business = this.businesses.get(businessId);
    _.set(business, path, value);
    this.updateBusiness(business);
  }

  interpolateBankAccounts(selectedBankAccounts: string[]) {
    const updatedBusiness = { ...this.selectedBusiness };
    _.set(updatedBusiness, 'profile.hasSelectedBankAccount', selectedBankAccounts);
    this.updateBusiness(updatedBusiness);
  }

  /**
   * Computed property which returns a Map of all non management businesses (clients)
   * @returns {Map<string, any>}
   */
  get clientBusinesses() {
    const clients = new Map();
    this.businesses.forEach((business) => {
      if (business?.businessClass !== 'management') clients.set(business.id, business);
    });
    return clients;
  }

  /**
   * Computed property which returns a Map of all non management businesses (clients)
   * that are activated and have data
   * @returns {Map<string, any>}
   */
  get activeClientBusinesses() {
    const clients = new Map();
    this.businesses.forEach((business) => {
      if (business?.businessClass !== 'management') {
        if (business?.aipStatus === ClientManagementEnums.BusinessActivationStatus.activated) {
          clients.set(business.id, business);
        }
      }
    });
    return clients;
  }

  /**
   * Computed property which returns an array of alphebatised active clients
   * @returns {Array<any>}
   */
  get sortedActiveClientBusinesses() {
    const clients = [];
    this.businesses.forEach((business) => {
      if (business?.businessClass !== 'management') {
        if (business?.aipStatus === ClientManagementEnums.BusinessActivationStatus.activated) {
          clients.push(business);
        }
      }
    });
    clients.sort((a, b) => a.name.localeCompare(b.name));
    return clients;
  }

  /**
   * Computed property which returns a Map of all non management businesses (clients)
   * that are in the loading_zone and have not been acvtivated
   * @returns {Map<string, any>}
   */
  get inactiveClientBusinesses() {
    const clients = new Map();
    this.businesses.forEach((business) => {
      if (business?.businessClass !== 'management'
        && business?.aipStatus !== ClientManagementEnums.BusinessActivationStatus.activated
      ) {
        clients.set(business.id, business);
      }
    });
    return clients;
  }

  /**
   * Computed property which returns a Map of all non management businesses (clients)
   * that are fully supported by the app
   * @returns {Map<string, any>}
   */
  get supportedBusinesses() {
    const supportedBusinesses = new Map();
    this.businesses.forEach((business) => {
      if (business?.businessClass !== 'management'
        && this.rootStore.practiceStore.countryCode !== 'US'
        && business?.supported === ClientManagementEnums.PlatformSupportStatus.supported
      ) supportedBusinesses.set(business.id, business);
    });
    return supportedBusinesses;
  }

  /**
   * Computed property which returns a Map of all non management businesses (clients)
   * that are missing data which means they will not be fully supported by the app
   * @returns {Map<string, any>}
   */
  get unsupportedBusinesses() {
    const unsupportedBusinesses = new Map();
    this.businesses.forEach((business) => {
      if (business?.businessClass !== 'management'
        && (
          (this.rootStore.practiceStore.countryCode !== 'US'
            && business?.supported === ClientManagementEnums.PlatformSupportStatus.notSupported)
          || this.rootStore.practiceStore.countryCode === 'US')
      ) unsupportedBusinesses.set(business.id, business);
    });
    return unsupportedBusinesses;
  }

  get ospClientCounts() {
    const counts = {
      [ClientManagementEnums.OSPKeys.xero]: 0,
      [ClientManagementEnums.OSPKeys.intuit]: 0,
      combination: 0,
    };
    this.businesses.forEach((business) => {
      if (business.businessClass === 'management') {
        return;
      }
      if (typeof business.connectedOsps !== 'undefined') {
        if (business.connectedOsps.length > 1) {
          counts.combination += 1;
        } else if (business.connectedOsps.includes(ClientManagementEnums.OSPKeys.intuit)) {
          counts[ClientManagementEnums.OSPKeys.intuit] += 1;
        } else {
          counts[ClientManagementEnums.OSPKeys.xero] += 1;
        }
      } else {
        counts[ClientManagementEnums.OSPKeys.xero] += 1;
      }
    });
    return counts;
  }

  get linesOfBusiness() {
    const linesOfBusiness = [];
    this.clientBusinesses.forEach((business) => {
      if (linesOfBusiness.indexOf(business.lineOfBusiness) === -1) linesOfBusiness.push(business.lineOfBusiness);
    });
    return linesOfBusiness;
  }

  get clientTags() {
    const tags = [];
    this.clientBusinesses.forEach((business) => {
      business.tags.forEach((tag) => {
        if (tags.indexOf(tag) === -1) tags.push(tag);
      });
    });
    return tags;
  }

  get fetchingBankAccounts() {
    return this.bankAccountDebounce.indexOf(this.selectedBusinessId) !== -1;
  }

  /**
   *
   * @param business
   */
  addBusiness(business: any) {
    try {
      if (!business) return false;

      const countryCode = business.countryCode || 'NZ';
      const taxRate = business.profile?.taxRate || CountryEnums.CountryDefaultTaxRates[countryCode];
      const superFrequency = business.profile?.superPaymentFrequency || CountryEnums.CountryDefaultSuperFrequency[countryCode];
      const selectedBankAccounts = business.profile?.hasSelectedBankAccount ?? [];

      const defaultBusiness = { ...business };
      defaultBusiness.profile = business.profile || {};
      defaultBusiness.countryCode = countryCode;
      defaultBusiness.profile.taxRate = taxRate;
      defaultBusiness.profile.superPaymentFrequency = superFrequency;
      defaultBusiness.profile.hasSelectedBankAccount = selectedBankAccounts;

      this.businesses.set(business.id, defaultBusiness);
      return true;
    } catch (error) {
      Sentry.captureEvent(error);
      throw error;
    }
  }

  /**
   *
   * @param business
   */
  removeBusiness(business: any) {
    this.businesses.delete(business.id);
  }

  /**
   *
   * @param business
   */
  removeBusinessById(businessId: string) {
    this.businesses.delete(businessId);
    // Remove business row from dashboard store as well
    this.rootStore.dashboardStore.businesses.delete(businessId);
  }

  /**
   *
   * @param business
   */
  updateBusiness(business: any, businessId: string = this.selectedBusinessId) {
    this.businesses.set(businessId, business);
  }

  /**
   *
   * @returns
   */
  async fetchBusinessData({ businessStatus, useLoader }
    : { businessStatus?: any, useLoader?: keyof LoadingStore['loadingMessages'] }
  = { businessStatus: null }) {
    this.rootStore.loadingStore.setLoading(useLoader || 'business');
    this.loading = true;
    const fetchedBusinesses: string[] = [];
    let transaction;

    const sentryTransaction = Sentry.startTransaction({
      name: 'Get Businesses',
    });

    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/users/${this.rootStore.userStore.id}/businesses${businessStatus ? `?businessStatus=${businessStatus}` : ''}`;

    GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction,
    }).then((res) => {
      sentryTransaction.finish();

      if (!res?.businesses) {
        throw Error('Business data not returned');
      }
      this.rootStore.authStore.authService.handleClaimData(res);
      transaction = Sentry.startTransaction({
        name: 'Stablish Businesses Connections',
      });
      res.businesses.forEach(async (business) => {
        fetchedBusinesses.push(business.id);

        this.addBusiness(business);
        this.setBusinessAlerts(business.id);
      });
      transaction.finish();
      this.businesses.forEach((business) => {
        if (fetchedBusinesses.indexOf(business.id) === -1) {
          this.removeBusiness(business);
        }
      });

      if (typeof res.configs !== 'undefined') {
        res.configs.forEach((config: { id: string, config: ClientManagementTypes.ClientConfig }) => {
          this.businessConfigs.set(config.id, config.config);
        });
      }

      this.loading = false;
      this.initialFetchComplete = true;
      this.rootStore.dashboardStore.initialLoadComplete = true;
    }).catch((error) => {
      if (transaction) {
        transaction.finish();
      }
      Sentry.captureException(error);
      if (error?.message === BUSINESS_NOT_FOUND_ERROR) {
        this.rootStore.connectionsStore.initialConnection = true;
      }
      this.initialFetchComplete = true;
      this.rootStore.dashboardStore.initialLoadComplete = true;
      this.loading = false;
      if (error?.message !== BUSINESS_NOT_FOUND_ERROR) {
        throw error;
      }
    }).finally(() => {
      this.rootStore.loadingStore.setDoneLoading(useLoader || 'business');
    });
  }

  async persistSelectedBusinessWithChecklistResync(
    ruleId: string,
    checklistId: string,
    sectionId: string,
    periodId: string,
  ) {
    return this.persistSelectedBusiness()
      .then(() => this.rootStore.rulesStore.handleUpdateRule(
        ruleId,
        checklistId,
        sectionId,
        periodId,
        this.selectedBusinessId,
      ))
      .catch((error) => {
        Sentry.captureException(error);
        Notification({ type: 'error', title: 'Failed to save business configuration', description: 'Something went wrong saving your changes, please try again' });
      });
  }

  /**
   *
   * @returns
   */
  async persistSelectedBusiness() {
    const sentryTransaction = Sentry.startTransaction({
      name: 'Persist Selected Business',
    });
    const business = { ...this.selectedBusiness };
    delete business.connection;

    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${business.id}`;

    const businessData = {
      profile: business.profile || null,
      tags: business.tags || [],
    };

    const businessConfig = this.businessConfigs.get(business.id);
    const configUrl = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${business.id}/config`;

    const promises = [
      PUT({
        url,
        data: businessData,
        rootStore: this.rootStore,
        sentryTransaction,
        sentrySpanName: 'UpdateBusinessData',
      }),
      PUT({
        url: configUrl,
        data: businessConfig,
        rootStore: this.rootStore,
        sentryTransaction,
        sentrySpanName: 'UpdateBusinessConfig',
      }),
    ];
    return Promise.allSettled(promises)
      .then((results) => {
        if (results.some((result) => result.status === 'rejected')) {
          throw results.find((result) => result.status === 'rejected');
        }
        Notification({ type: 'success', title: 'Business settings saved' });
      })
      .catch((error) => {
        handleError({ error, operation: 'PersistBusinessConfig' });
        Notification({ type: 'error', title: 'Failed to save business configuration', description: 'Something went wrong saving your changes, please try again' });
      })
      .finally(() => {
        sentryTransaction.finish();
      });
  }

  async getChartOfAccounts(businessId: string) {
    try {
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${businessId}/accounts`;
      const res = await GET({
        url,
        rootStore: this.rootStore
      });
      if (!res) throw Error('No chart of accounts found');
      const newBusiness = this.businesses.get(businessId);
      const chartOfAccounts = buildChartOfAccounts(res.accounts);
      _.set(newBusiness, 'chartOfAccounts', chartOfAccounts);
      _.set(newBusiness, 'sortedChartOfAccounts', getSortAndFilteredChartOfAccounts(chartOfAccounts));
      this.updateBusiness(newBusiness);
      return res;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  setBusinessAlerts(businessId: string) {
    this.rootStore.alertStore.clearAlerts(businessId);
    const business = this.businesses.get(businessId);
    const alerts = business.alerts ? Object.values(business.alerts) : [];

    alerts.forEach((alert: any) => {
      const { category } = alert;
      const { count } = alert;

      this.rootStore.alertStore.alert(businessId, category, count);
    });
  }

  fetchBusinessConnection = async (businessId: string = this.selectedBusinessId) => {
    if (!this.retrievingConnection) {
      this.retrievingConnection = true;
      const transaction = Sentry.startTransaction({
        name: 'Retrieve Businesses Connection',
      });
      let span;
      const business = this.businesses.get(businessId);
      if (!business) {
        this.retrievingConnection = false;
        return null;
      }
      if (!business?.connection) {
        span = transaction.startChild({
          op: 'RetrieveConnection',
          description: 'Retrieve Business Connection from API',
        });
        business.connection = { disconnected: true };
        const getConnectionUrl = `${process.env.REACT_APP_CONNECTION_MODULE_ENDPOINT}/businesses/${business.id}/connections`;

        const connection = await GET({
          url: getConnectionUrl,
          rootStore: this.rootStore,
          sentryTransaction: transaction
        });
        span.finish();
        span = transaction.startChild({
          op: 'UpdateBusiness',
          description: 'Update Business Connection with retrieved details',
        });

        if (connection?.businessConnections?.length === 1) {
          business.connection = { ...connection.businessConnections[0]?.configuration?.entities?.[0] };
        }
        this.updateBusiness(business, businessId);

        span.finish();
      }
      transaction.finish();
      this.retrievingConnection = false;
    }
    return this.businesses.get(businessId).connection;
  }

  activateBusinesses = async () => {
    const practiceId = this.rootStore.practiceStore.id;
    this.loading = true;

    const activatedBusinesses: ActivatedBusinesses = {
      statusChanges: this.selectedForActivation.map((businessId) => ({
        businessId,
        newStatus: ClientManagementEnums.BusinessActivationStatus.activated,
      })),
    };

    try {
      const sentryTransaction = Sentry.startTransaction({
        name: 'Activate Businesses API Call',
      });
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practice/${practiceId}/clients/status`;
      const res = await PUT({
        url,
        data: activatedBusinesses,
        rootStore: this.rootStore,
        sentryTransaction,
      });
      sentryTransaction.finish();
      if (!res || !res?.changeResults || !res?.subscription) {
        throw Error('Business data not returned');
      }

      const successful = res.changeResults?.map((response) => {
        if (response.result === 'success') {
          return response.businessId;
        }
        return null;
      }).filter((businessId) => businessId !== null);

      const failed = res.changeResults?.map((response) => {
        if (response.result !== 'success') {
          return response.businessId;
        }
        return null;
      }).filter((businessId) => businessId !== null);

      const { subscription } = res;

      if (subscription) {
        this.rootStore.practiceStore.subscriptionDetails = {
          currentClientCount: subscription.previousClientCount,
          currentTier: subscription.previousTier,
          updatedClientCount: subscription.newClientCount,
          newTier: subscription.newTier,
        };
      }

      this.loading = false;
      return { successful, failed, subscription: res.subscription };
    } catch (err) {
      this.loading = false;
      handleError({ error: err });
      throw Error('Business data not returned');
    }
  }

  deactivateBusinesses = async () => {
    const practiceId = this.rootStore.practiceStore.id;
    this.loading = true;

    const deactivatedBusinesses: ActivatedBusinesses = {
      statusChanges: this.selectedForActivation.map((businessId) => ({
        businessId,
        newStatus: ClientManagementEnums.BusinessActivationStatus.notActivated,
      })),
    };

    try {
      const sentryTransaction = Sentry.startTransaction({
        name: 'Deactivate Businesses API Call',
      });
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practice/${practiceId}/clients/status`;
      const res = await PUT({
        url,
        data: deactivatedBusinesses,
        rootStore: this.rootStore,
        sentryTransaction,
      });
      sentryTransaction.finish();
      if (!res || !res?.changeResults || !res?.subscription) {
        throw Error('Business data not returned');
      }

      const successful = res.changeResults?.map((response) => {
        if (response.result === 'success') {
          return response.businessId;
        }
        return null;
      }).filter((businessId) => businessId !== null);

      const failed = res.changeResults?.map((response) => {
        if (response.result !== 'success') {
          return response.businessId;
        }
        return null;
      }).filter((businessId) => businessId !== null);

      const { subscription } = res;

      if (subscription) {
        this.rootStore.practiceStore.subscriptionDetails = {
          currentClientCount: subscription.previousClientCount,
          currentTier: subscription.previousTier,
          updatedClientCount: subscription.newClientCount,
          newTier: subscription.newTier,
        };
      }

      this.loading = false;
      return { successful, failed, subscription: res.subscription };
    } catch (err) {
      this.loading = false;
      handleError({ error: err });
      throw Error('Business data not returned');
    }
  }

  activateBulkClients = async (target: ClientManagementEnums.PlatformSupportStatus = ClientManagementEnums.PlatformSupportStatus.supported) => {
    let response: { status: 'error' | 'ok', subscription?} = { status: 'error' };
    const transaction = Sentry.startTransaction({
      name: 'Activate Supported Clients',
    });
    const span = transaction.startChild({
      op: 'ActivateClients',
      description: 'Activate all supported clients',
    });
    try {
      const clients = target === ClientManagementEnums.PlatformSupportStatus.supported
        ? this.supportedBusinesses
        : this.unsupportedBusinesses;
      const data = {
        statusChanges: [],
      };
      clients.forEach((client) => {
        if (!client?.aipStatus || client?.aipStatus === ClientManagementEnums.BusinessActivationStatus.notActivated) {
          data.statusChanges.push({
            businessId: client.id,
            newStatus: ClientManagementEnums.BusinessActivationStatus.activated,
          });
        }
      });
      if (data.statusChanges.length === 0) throw Error('No clients to activate');
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practice/${this.rootStore.practiceStore.id}/clients/status`;
      const res = await PUT({
        url,
        data,
        rootStore: this.rootStore,
        sentryTransaction: transaction
      });
      if (!res && (!res?.changeResults || !res?.subscription)) throw Error('No clients activated');
      const { subscription } = res;
      if (subscription) {
        this.rootStore.practiceStore.subscriptionDetails = {
          currentClientCount: subscription.previousClientCount,
          currentTier: subscription.previousTier,
          updatedClientCount: subscription.newClientCount,
          newTier: subscription.newTier,
        };
      }
      this.rootStore.loadingStore.setGlobalBypass('business', 'defaultBusiness');
      await this.fetchBusinessData();
      this.selectedBusinessId = null;
      Notification({
        type: 'success',
        title: `${res.changeResults.length} Client${res.changeResults.length > 1 ? 's' : ''} Activated`,
      });
      response = { status: 'ok', subscription };
    } catch (error: any) {
      Notification({
        type: 'error',
        title: 'Error activating clients',
        description: error,
      });
      Sentry.captureException(error);
    }
    span.finish();
    transaction.finish();
    return response;
  }

  deactivateBusiness = async (businessId: string) => {
    let response = false;
    const transaction = Sentry.startTransaction({
      name: 'Deactivate Client',
    });
    const span = transaction.startChild({
      op: 'DeactivateClients',
      description: 'Deactivate client',
    });
    const business = this.businesses.get(businessId);
    try {
      const data = {
        statusChanges: [{
          businessId,
          newStatus: ClientManagementEnums.BusinessActivationStatus.notActivated,
        }],
      };
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practice/${this.rootStore.practiceStore.id}/clients/status`;
      const res = await PUT({
        url,
        data,
        rootStore: this.rootStore,
        sentryTransaction: transaction
      });
      if (!res && (!res?.changeResults || !res?.subscription)) throw Error('Could not deactivate client');
      await this.fetchBusinessData();
      Notification({
        type: 'success',
        title: 'Client deactivated',
        description: `${business.name} deactivated successfully`,
      });

      const { newClientCount, previousClientCount, newTier, previousTier } = res.subscription;

      Mixpanel.people.set({
        numberOfClients: newClientCount,
        priceTier: newTier.name,
      });

      Mixpanel.track('Client Deactivated', {
        client: business.name,
        previousClientCount,
        newClientCount,
        previousTier: previousTier.name,
        newTier: newTier.name,
      });

      // By nullifying selected business ID we allow automated default
      // selection of the first business in from activated businesses once ready
      this.selectedBusinessId = null;

      response = true;
    } catch (error: any) {
      Notification({
        type: 'error',
        title: 'Error dectivating clients',
        description: error,
      });
      Sentry.captureException(error);
    }
    span.finish();
    transaction.finish();
    return response;
  }

  disconnectAndRemoveBusinesses = async () => {
    const businessIds = this.selectedForActivation;

    const transaction = Sentry.startTransaction({
      name: 'Disconnect and Remove multiple clients',
    });

    const span = transaction.startChild({
      op: 'RemoveClients',
      description: 'Disconnect and remove client',
    });

    try {
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices/${this.rootStore.practiceStore.id}/clients/batch`;
      const res = await DELETE({
        url,
        data: { clients: businessIds },
        rootStore: this.rootStore,
        sentryTransaction: transaction
      });
      if (!res) throw Error('Could not remove clients');
      await this.fetchBusinessData();
      Notification({
        type: 'success',
        title: 'Selected Clients removed',
        description: 'Selected clients removed successfully',
      });

      const subscription = res?.clients?.[0]?.subscription;

      // By nullifying selected business ID we allow automated default
      // selection of the first business in from activated businesses once ready
      this.selectedBusinessId = null;

      span.finish();
      transaction.finish();
      return subscription;
    } catch (error: any) {
      Notification({
        type: 'error',
        title: 'Error removing clients',
        description: error,
      });
      Sentry.captureException(error);
    }
    span.finish();
    transaction.finish();
    return null;
  }

  disconnectAndRemoveBusiness = async (businessId: string) => {
    let response = false;
    const transaction = Sentry.startTransaction({
      name: 'Disconnect and Remove Client',
    });
    const span = transaction.startChild({
      op: 'RemoveClients',
      description: 'Disconnect and remove client',
    });
    const business = this.businesses.get(businessId);
    try {
      const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/practices/${this.rootStore.practiceStore.id}/clients/${businessId}`;
      const res = await DELETE({
        url,
        rootStore: this.rootStore,
        sentryTransaction: transaction
      });
      if (!res) throw Error('Could not remove client');
      await this.fetchBusinessData();
      Notification({
        type: 'success',
        title: 'Client removed',
        description: `${business.name} removed successfully`,
      });

      const subscription = res?.clients?.find((client) => client.clientBusinessId === businessId)?.subscription;

      if (subscription && business?.aipStatus === ClientManagementEnums.BusinessActivationStatus.activated) {
        const { newClientCount, previousClientCount, newTier, previousTier } = subscription;

        Mixpanel.people.set({
          numberOfClients: newClientCount,
          priceTier: newTier?.name,
        });

        Mixpanel.track('Client Removed', {
          client: this.rootStore.practiceStore.name,
          previousClientCount,
          newClientCount,
          previousTier: previousTier?.name,
          newTier: newTier?.name,
        });
      }

      // By nullifying selected business ID we allow automated default
      // selection of the first business in from activated businesses once ready
      this.selectedBusinessId = null;

      response = true;
    } catch (error: any) {
      Notification({
        type: 'error',
        title: 'Error removing clients',
        description: error,
      });
      Sentry.captureException(error);
    }
    span.finish();
    transaction.finish();
    return response;
  }

  async getUsersForBusinesses(businessId: string = this.selectedBusinessId) {
    this.fetchingBusinessAdvisors = true;
    const business = this.businesses.get(businessId);
    const url = `${process.env.REACT_APP_USERS_MODULE_ENDPOINT}/businesses/${businessId}/users`;
    try {
      if (!business) throw Error('Business not found');

      const res = await GET({
        url,
        rootStore: this.rootStore
      });

      if (!res && !res?.businessUsers) throw Error('No users found for business');

      business.advisors = res.businessUsers;
      this.updateBusiness(business);
      this.fetchingBusinessAdvisors = false;
    } catch (error) {
      handleError({ error });
      this.fetchingBusinessAdvisors = false;
    }
  }

  async fetchBankAccounts(businessId: string = this.selectedBusinessId) {
    if (this.bankAccountDebounce.indexOf(businessId) !== -1) {
      return;
    }

    this.bankAccountDebounce.push(businessId);
    const sentryTransaction = Sentry.startTransaction({
      name: 'Get Businesses',
    });
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${businessId}/bankAccounts`;
    GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction,
    })
      .then((res) => {
        if (!res?.bankAccounts) {
          throw Error('Bank accounts not returned');
        }
        const normalisedAccounts = convertBankAccounts(res.bankAccounts);
        this.businessBankAccounts.set(businessId, normalisedAccounts);
      })
      .catch((e) => {
        handleError({ error: e });
      })
      .finally(() => {
        sentryTransaction.finish();
        this.bankAccountDebounce = this.bankAccountDebounce.filter((id) => id !== businessId);
      });
  }

  async fetchBusinessConfig(businessId: string = this.selectedBusinessId) {
    if (this.businessConfigsDebounce.indexOf(businessId) !== -1) {
      return;
    }
    this.businessConfigsDebounce.push(businessId);
    const sentryTransaction = Sentry.startTransaction({
      name: 'Get Business Configs',
    });
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${businessId}/config`;
    GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction,
    })
      .then((res) => {
        if (!res?.config) {
          throw Error('Business config not returned');
        }
        this.businessConfigs.set(businessId, res.config);
      })
      .catch((e) => {
        handleError({ error: e });
      })
      .finally(() => {
        sentryTransaction.finish();
        this.businessConfigsDebounce = this.businessConfigsDebounce.filter((id) => id !== businessId);
      });
  }

  updateSelectedBusinessConfig(config: any) {
    this.businessConfigs.set(this.selectedBusinessId, config);
  }
}
