import React, { useRef, useState } from 'react';
import { Select, Table, Modal, Radio, Form, Tooltip } from 'antd';
import { SettingOutlined, DownloadOutlined } from '@ant-design/icons';

import {
  Line,
  Bar,
  Pie,
  Doughnut,
  Radar,
  PolarArea,
  Bubble,
  Scatter,
} from '../chart';
import ButtonSecondary from '../customAntD/ButtonSecondary';

const { Option } = Select;

function transformToAntdTable(jsonData) {
  /**
   * Transforms JSON data into the format required by antd Table component.
   *
   * @param {Array<Object>} jsonData - The JSON data extracted from the markdown table.
   * @returns {Object} An object containing columns and dataSource for antd Table.
   */

  // Function to check if a string is a valid date
  const isValidDate = (dateString) => {
    const date = Date.parse(dateString);
    return !Number.isNaN(date);
  };

  // Function to check if a string is a currency
  const isCurrency = (currencyString) => typeof currencyString === 'string'
    && (currencyString.trim().startsWith('$')
      || currencyString.trim().startsWith('-$'));

  // Extract column headers from the first object in the JSON data
  const columns = Object.keys(jsonData[0]).map((key) => ({
    title: key,
    dataIndex: key,
    key,
    sorter: (a, b) => {
      if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        return a[key] - b[key];
      }
      if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        if (isValidDate(a[key]) && isValidDate(b[key])) {
          return Date.parse(a[key]) - Date.parse(b[key]);
        }
        if (isCurrency(a[key]) && isCurrency(b[key])) {
          const numA = parseFloat(a[key].replace(/[^0-9.-]+/g, ''));
          const numB = parseFloat(b[key].replace(/[^0-9.-]+/g, ''));
          return numA - numB;
        }
        return a[key].localeCompare(b[key]);
      }
      return 0;
    },
  }));

  // Use the JSON data directly as the dataSource
  const dataSource = jsonData.map((item, index) => ({
    ...item,
    key: index, // Add a unique key for each row
  }));

  return { columns, dataSource };
}

function transformToChartJsData(
  columns,
  dataSource,
  labelColumn,
  selectedColumns
) {
  const colors = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
  ];

  const labels = dataSource.map((item) => item[labelColumn]);

  const datasets = columns
    .filter((col) => selectedColumns.includes(col.dataIndex))
    .map((col, index) => ({
      label: col.title,
      data: dataSource.map((item) => {
        const value = item[col.dataIndex];
        const numberValue =
          typeof value === 'string'
            ? parseFloat(value.replace(/[^0-9.-]+/g, ''))
            : value;
        return numberValue;
      }),
      backgroundColor: colors[index % colors.length],
      borderColor: colors[index % colors.length].replace('0.2', '1'),
      borderWidth: 1,
    }));

  return { labels, datasets };
}

function downloadCSV(columns, dataSource) {
  // Prepare CSV header
  const csvHeader = columns.map((col) => `"${col.title}"`).join(',');

  // Prepare CSV rows
  const csvRows = dataSource
    .map((row) => columns
      .map((col) => {
        const cell = row[col.dataIndex];
        return `"${
          typeof cell === 'string' ? cell.replace(/"/g, '""') : cell
        }"`;
      })
      .join(','))
    .join('\n');

  // Combine header and rows
  const csvContent = `${csvHeader}\n${csvRows}`;

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Create a link to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'data.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// To download the image
const downloadImage = (chartRef) => {
  const base64Image = chartRef.current.toBase64Image();
  const link = document.createElement('a');
  link.href = base64Image;
  link.download = 'chart.png';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface InteractiveTableProps {
  data: any[];
}

const InteractiveTable: React.FC<InteractiveTableProps> = ({ data }) => {
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const { columns, dataSource } = transformToAntdTable(data);
  const [labelColumn, setLabelColumn] = useState(columns[0].dataIndex);
  const [selectedColumns, setSelectedColumns] = useState(
    columns
      .map((col) => col.dataIndex)
      .filter((dataIndex) => dataIndex !== labelColumn)
  );
  const [chartData, setChartData] = useState<any>(
    transformToChartJsData(columns, dataSource, labelColumn, selectedColumns)
  );
  const [viewMode, setViewMode] = useState<'table' | 'chart'>('table');
  const [chartType, setChartType] = useState<'line' | 'bar'>('bar');
  const chartTypes = [
    { value: 'bar', label: 'Bar', component: Bar },
    { value: 'line', label: 'Line', component: Line },
    { value: 'pie', label: 'Pie', component: Pie },
    { value: 'doughnut', label: 'Doughnut', component: Doughnut },
    { value: 'radar', label: 'Radar', component: Radar },
    { value: 'polarArea', label: 'Polar Area', component: PolarArea },
    { value: 'bubble', label: 'Bubble', component: Bubble },
    { value: 'scatter', label: 'Scatter', component: Scatter },
  ];
  const chartRef = useRef(null);

  const handleLabelColumnChange = (value) => {
    setLabelColumn(value);
    setSelectedColumns((prevSelectedColumns) => prevSelectedColumns.filter((col) => col !== value));
    setChartData(
      transformToChartJsData(columns, dataSource, value, selectedColumns)
    );
  };

  const handleSelectedColumnsChange = (value) => {
    const filteredValues = value.filter((col) => col !== labelColumn);
    setSelectedColumns(filteredValues);
    setChartData(
      transformToChartJsData(columns, dataSource, labelColumn, filteredValues)
    );
  };

  const download = () => {
    try {
      if (viewMode === 'table') {
        downloadCSV(columns, dataSource);
      } else {
        downloadImage(chartRef);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', float: 'right' }}>
        <Tooltip title={`Download ${viewMode === 'table' ? 'CSV' : 'Image'}`}>
          <ButtonSecondary
            size='large'
            shape='circle'
            icon={<DownloadOutlined />}
            onClick={download}
          />
        </Tooltip>
        <Tooltip title='Settings'>
          <ButtonSecondary
            tooltip='Visualisation Settings'
            size='large'
            shape='circle'
            icon={<SettingOutlined />}
            onClick={() => setIsSettingsVisible(true)}
            style={{ marginLeft: 10 }}
          />
        </Tooltip>
      </div>
      <Modal
        title='Display settings'
        open={isSettingsVisible}
        onOk={() => setIsSettingsVisible(false)}
        onCancel={() => setIsSettingsVisible(false)}
        cancelButtonProps={{ style: { visibility: 'hidden' } }}
      >
        <Form layout='vertical'>
          <Form.Item label='View as'>
            <Radio.Group
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              style={{ marginBottom: 10 }}
            >
              <Radio.Button value='table'>Table</Radio.Button>
              <Radio.Button value='chart'>Chart</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>

        {viewMode === 'chart' && (
          <Form layout='vertical'>
            <Form.Item label='X-axis labels'>
              <Select
                value={labelColumn}
                onChange={handleLabelColumnChange}
              >
                {columns.map((col) => (
                  <Option
                    key={col.dataIndex}
                    value={col.dataIndex}
                  >
                    {col.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label='Data to show in chart'>
              <Select
                mode='multiple'
                value={selectedColumns}
                onChange={handleSelectedColumnsChange}
              >
                {columns.map((col) => (
                  <Option
                    key={col.dataIndex}
                    value={col.dataIndex}
                  >
                    {col.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Chart Type'>
              <Select
                value={chartType}
                onChange={setChartType}
              >
                {chartTypes.map((type) => (
                  <Option
                    key={type.value}
                    value={type.value}
                  >
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Modal>
      {viewMode === 'table' ? (
        <Table
          className='scrollblar'
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          onChange={(pagination, filters, sorter: any) => {
            const sortedData = [...dataSource].sort((a, b) => {
              const { columnKey, order } = sorter;
              if (order === 'ascend') {
                return columns
                  .find((col) => col.dataIndex === columnKey)
                  .sorter(a, b);
              }
              if (order === 'descend') {
                return columns
                  .find((col) => col.dataIndex === columnKey)
                  .sorter(b, a);
              }
              return 0;
            });
            setChartData(
              transformToChartJsData(
                columns,
                sortedData,
                labelColumn,
                selectedColumns
              )
            );
          }}
        />
      ) : (
        <>
          {(() => {
            const ChartComponent = chartTypes.find(
              (type) => type.value === chartType
            )?.component;
            return ChartComponent ? (
              <ChartComponent
                ref={chartRef}
                data={chartData}
              />
            ) : null;
          })()}
        </>
      )}
    </>
  );
};

export default InteractiveTable;
