import { toJS } from 'mobx';
import { CustomRuleEnums } from '@aider/constants-library';
import { rootStore } from '../stores/Store';
/**
 * A constant for the definition of a Split Config object.
 * Based on the practice id of the user logged in.
 */
export const SPLIT_CONFIG_PRACTICE_ID = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_KEY,
    key: toJS(rootStore.businessStore.practiceId).id,
  },
};

/**
 * A constant for linking the rule types to the criteria.
 */
export const RuleCriteria = {
  accounts: CustomRuleEnums.AccountRuleCriteria,
  transactions: CustomRuleEnums.TransactionRuleCriteria,
  manualChecks: CustomRuleEnums.ManualChecksCriteria,
};
