import { makeAutoObservable } from 'mobx';
import { ComplianceChecklistEnums } from '@aider/constants-library';
import * as Sentry from '@sentry/browser';
import type { RootStore } from './Store';
import { GET } from '../lib/requests';
import handleError from '../lib/errorHandler';
import { DashboardSortDirection, DashboardSortTarget } from '../models/enums/components';
import { PeriodCloseDashboardBusiness, PeriodCloseSortInfo } from '../models/interfaces/stores';

class PeriodCloseDashboardStore {
  rootStore: RootStore;

  activeChecklistType: ComplianceChecklistEnums.Types = ComplianceChecklistEnums.Types.yearlyChecklist;

  activeSort: PeriodCloseSortInfo = {
    column: 'name',
    direction: DashboardSortDirection.ascending,
    target: DashboardSortTarget.name,
  }

  activeIndustryFilter: string[] = [];

  activeTagFilter: string[] = [];

  activeSearchFilter: string = '';

  dashboard: Map<ComplianceChecklistEnums.Types, PeriodCloseDashboardBusiness[]> = new Map();

  retrievalDebounce: any;

  dashboardInitialised: boolean = false;

  get sortColumn() {
    return this.activeSort.column;
  }

  get sortTarget() {
    return this.activeSort.target;
  }

  get sortDirection() {
    return this.activeSort.direction;
  }

  rowsLoading: boolean = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      { rootStore: false },
      { autoBind: true }
    );
  }

  async initialiseDashboard() {
    this.activeIndustryFilter = [];
    this.activeTagFilter = [];

    return this.retrieveDashboardDebounced().catch((e) => {
      handleError({ error: e, transaction: 'Compliance Dashboard - Initialise Dashboard' });
    });
  }

  /** Actions to clear all, add a single or remove a single industry filter */
  clearIndustryFilter() {
    this.activeIndustryFilter = [];
  }

  addIndustryFilter(industry: string) {
    if (this.activeIndustryFilter?.indexOf(industry) === -1) {
      this.activeIndustryFilter.push(industry);
    }
  }

  removeIndustryFilter(industry: string) {
    this.activeIndustryFilter = this.activeIndustryFilter.filter(
      (activeIndustry) => activeIndustry !== industry
    );
  }

  /** Actions to clear all, add a single or remove a single tag filter */
  clearTagFilter() {
    this.activeTagFilter = [];
  }

  addTagFilter(tag: string) {
    if (this.activeTagFilter?.indexOf(tag) === -1) {
      this.activeTagFilter.push(tag);
    }
  }

  removeTagFilter(tag: string) {
    this.activeTagFilter = this.activeTagFilter.filter(
      (activeTag) => activeTag !== tag
    );
  }

  // eslint-disable-next-line class-methods-use-this
  applyFilter(source: string[], needle: string[]) {
    // No filter to apply, do not filter
    if (source.length === 0) return true;
    return needle.some((val: any) => source.indexOf(val) > -1);
  }

  sortName(
    a:PeriodCloseDashboardBusiness,
    b:PeriodCloseDashboardBusiness,
    direction:DashboardSortDirection = undefined
  ) {
    const sortDirection = typeof direction === 'number' ? direction : this.activeSort.direction;

    if (sortDirection === DashboardSortDirection.ascending) {
      return a.businessName.localeCompare(b.businessName);
    }
    return b.businessName.localeCompare(b.businessName);
  }

  sortUpdated(
    a:PeriodCloseDashboardBusiness,
    b:PeriodCloseDashboardBusiness
  ) {
    const aVal = this.rootStore.resyncStore.dataStatusSnapshots.get(a.businessId).etl?.ended;
    const bVal = this.rootStore.resyncStore.dataStatusSnapshots.get(b.businessId).etl?.ended;
    if (this.activeSort.direction === DashboardSortDirection.ascending) {
      if (!aVal) return 1;
      if (!bVal) return -1;
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
    } else {
      if (!aVal) return -1;
      if (!bVal) return 1;
      if (aVal < bVal) return 1;
      if (aVal > bVal) return -1;
    }
    return this.sortName(a, b, DashboardSortDirection.ascending);
  }

  sortProgress(
    a:PeriodCloseDashboardBusiness,
    b:PeriodCloseDashboardBusiness
  ) {
    const aVal = a.checklistStatus.percentage;
    const bVal = b.checklistStatus.percentage;
    if (this.activeSort.direction === DashboardSortDirection.ascending) {
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
    } else {
      if (aVal > bVal) return -1;
      if (aVal < bVal) return 1;
    }
    return this.sortName(a, b, DashboardSortDirection.ascending);
  }

  sortStatus(
    a:PeriodCloseDashboardBusiness,
    b:PeriodCloseDashboardBusiness
  ) {
    const statusOrder = ['todo', 'inProgress', 'done'];
    const aVal = a.checklistStatus.status;
    const bVal = b.checklistStatus.status;
    let statusSort = 0;
    if (this.activeSort.direction === DashboardSortDirection.ascending) {
      statusSort = statusOrder.indexOf(aVal) - statusOrder.indexOf(bVal);
    } else {
      statusSort = statusOrder.indexOf(bVal) - statusOrder.indexOf(aVal);
    }
    if (statusSort !== 0) return statusSort;
    return this.sortName(a, b, DashboardSortDirection.ascending);
  }

  sortAlert(
    a:PeriodCloseDashboardBusiness,
    b:PeriodCloseDashboardBusiness
  ) {
    const sortActive = this.activeSort.target === DashboardSortTarget.active;
    const sortAscending = this.activeSort.direction === DashboardSortDirection.ascending;
    let aVal: number;
    let bVal: number;
    if (this.activeSort.column === 'checklistStatus') {
      aVal = sortActive ? a.totals.active : a.totals.reviewed;
      bVal = sortActive ? b.totals.active : b.totals.reviewed;
    } else {
      aVal = sortActive ? a[this.activeSort.column]?.active : a[this.activeSort.column]?.reviewed;
      bVal = sortActive ? b[this.activeSort.column]?.active : b[this.activeSort.column]?.reviewed;
    }
    if (aVal > bVal) return sortAscending ? 1 : -1;
    if (aVal < bVal) return sortAscending ? -1 : 1;
    return this.sortName(a, b, DashboardSortDirection.ascending);
  }

  get sortedDashboard():PeriodCloseDashboardBusiness[] {
    return this.dashboard.has(this.activeChecklistType)
      ? this.dashboard.get(this.activeChecklistType)
        .slice()
        .filter((business) => business.businessName.toLowerCase().includes(this.activeSearchFilter.toLowerCase()))
        .filter((business) => this.applyFilter(this.activeTagFilter, business.tags))
        .filter((business) => this.applyFilter(this.activeIndustryFilter, [business.lineOfBusiness]))
        .sort((a, b) => {
          switch (this.activeSort.target) {
            case DashboardSortTarget.updated:
              return this.sortUpdated(a, b);
            case DashboardSortTarget.status:
              return this.sortStatus(a, b);
            case DashboardSortTarget.progress:
              return this.sortProgress(a, b);
            case DashboardSortTarget.active:
            case DashboardSortTarget.reviewed:
              return this.sortAlert(a, b);
            case DashboardSortTarget.name:
            default:
              return this.sortName(a, b);
          }
        })
      : [];
  }

  async selectChecklist(checklistType: ComplianceChecklistEnums.Types) {
    if (!this.dashboard.has(checklistType)) {
      this.rowsLoading = true;
    }
    this.activeChecklistType = checklistType;
    return this.retrieveDashboardDebounced();
  }

  async retrieveDashboardDebounced() {
    clearTimeout(this.retrievalDebounce);
    this.retrievalDebounce = await setTimeout(this.retrieveDashboard, 500);
  }

  async retrieveDashboardRow(businessId: string) {
    const transaction = Sentry.startTransaction({
      name: 'Get Compliance Dashboard Row',
    });

    const newDash = this.dashboard.has(this.activeChecklistType) ? this.dashboard.get(this.activeChecklistType) : null;

    if (!newDash) {
      return 'Invalid Dashboard Type';
    }

    const rowInx = newDash.findIndex((row) => row.businessId === businessId);
    const url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/practice/${this.rootStore.practiceStore.id}/insights/tablegroups/${this.activeChecklistType}/dashboard/${businessId}`;

    return GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction: transaction,
    }).then((res: PeriodCloseDashboardBusiness) => {
      transaction.finish();
      newDash[rowInx] = res;
      this.dashboard.set(this.activeChecklistType, newDash);
    }).catch((error) => {
      handleError({ sentryTransaction: transaction, error });
      return error;
    });
  }

  async retrieveDashboard() {
    if (!this.dashboard.has(this.activeChecklistType)) {
      this.rowsLoading = true;
    }
    const transaction = Sentry.startTransaction({
      name: 'Get Compliance Dashboard Data',
    });

    let url = `${process.env.REACT_APP_INSIGHT_ENDPOINT_V1}/practice/${this.rootStore.practiceStore.id}/insights/tablegroups/${this.activeChecklistType}/dashboard?`;

    if (this.activeTagFilter) {
      this.activeTagFilter.forEach((tag) => {
        url = `${url}tags=${tag}&`;
      });
    }

    if (this.activeIndustryFilter) {
      this.activeIndustryFilter.forEach((industry) => {
        url = `${url}linesOfBusiness=${industry}&`;
      });
    }

    return GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction: transaction,
    })
      .then((response: {dashboardRows: PeriodCloseDashboardBusiness[]}) => {
        transaction.finish();
        this.dashboard.set(this.activeChecklistType, response.dashboardRows);
      })
      .catch((error) => {
        transaction.finish();
        Sentry.captureException('Error Retrieving Compliance Dashboard Data', error);
        return error;
      })
      .finally(() => {
        this.dashboardInitialised = true;
        this.rowsLoading = false;
      });
  }
}

export default PeriodCloseDashboardStore;
